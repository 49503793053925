import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type DialogFooterButtonProps = {
	color?: string,
	textColor?: string,
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const DialogFooterButton: FC<DialogFooterButtonProps> = ({
	children,
	color,
	textColor,
	...buttonProps
}) => {

	// hooks
	const styles = useStyles()

	return (
		<button {...buttonProps} className={`${styles.dialogFooterButton} ${buttonProps.className}`} style={{ backgroundColor: color, color: textColor, }}>
			{children}
		</button>
	)
}

const useStyles = createUseStyles({
	dialogFooterButton: {
		display: 'grid',
		justifyContent: 'center',
		alignContent: 'center',
		cursor: 'pointer',
		transition: 'filter 50ms ease',
		border: 'none',
		fontSize: 16,
		fontWeight: 500,
		outline: 'none !important',
		'&:disabled': {
			filter: 'grayscale(1)',
			'&:hover': {
				filter: 'grayscale(1)',
			},
			'&:active': {
				filter: 'grayscale(1)',
			}
		},
		'&:hover': {
			filter: 'brightness(0.95)',
		},
		'&:active': {
			filter: 'brightness(0.9)',
		}
	}
})