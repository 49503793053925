import firebase from 'firebase'
import { Admin, UserProfile } from '../types/types'
import { sanitizeObjectForFirebase } from '../utils/other/sanitize-object-for-firebase'
import { logAnalyticsEvent } from './analytics'

export const makeUserAdmin = async (userId: string) => {

    const db = firebase.firestore()

    const userProfile = (await db.doc(`userProfiles/${userId}`).get()).data() as UserProfile

    if (!userProfile) {
        throw new Error('Couldn\'t find user')
    }

    const payload: Admin = {
        displayName: userProfile.displayName,
        emailAddress: userProfile.emailAddress,
    }

    await db.doc(`admins/${userId}`).set(sanitizeObjectForFirebase(payload))

    logAnalyticsEvent('action', { type: 'makeUserAdmin', payload: { userId } })

}

export const makeUserNotAdmin = async (userId: string) => {

    const db = firebase.firestore()

    await db.doc(`admins/${userId}`).delete()

    logAnalyticsEvent('action', { type: 'makeUserNotAdmin', payload: { userId } })
    
}