import React, { FC } from 'react'
import { IconProps } from '../props'

export const EmailIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 18" fill={color}>
			<path id="Path_25" data-name="Path 25" d="M21.75,64H2.25A2.25,2.25,0,0,0,0,66.25v13.5A2.25,2.25,0,0,0,2.25,82h19.5A2.25,2.25,0,0,0,24,79.75V66.25A2.25,2.25,0,0,0,21.75,64Zm0,2.25v1.913c-1.051.856-2.727,2.187-6.309,4.992-.789.621-2.353,2.113-3.441,2.1-1.088.018-2.652-1.475-3.441-2.1C4.977,70.35,3.3,69.019,2.25,68.163V66.25ZM2.25,79.75v-8.7c1.074.856,2.6,2.056,4.919,3.874C8.194,75.73,9.988,77.511,12,77.5c2,.011,3.774-1.744,4.831-2.576,2.322-1.818,3.845-3.019,4.919-3.874v8.7Z" transform="translate(0 -64)" />
		</svg>
	)
}