import { Student } from "../types/types";
import firebase from 'firebase';
import { sanitizeObjectForFirebase } from "../utils/other/sanitize-object-for-firebase";
import { logAnalyticsEvent } from "./analytics";

export const createStudent = async (student: Student) => {

    logAnalyticsEvent('action', { type: 'createStudent', payload: { student } })

    const auth = firebase.auth()
    const db = firebase.firestore()

    const userId = auth.currentUser?.uid
    if (!userId) { throw new Error('Couldn\'t find a user id for the logged in user') }

    await db.doc(`students/${userId}`).set(sanitizeObjectForFirebase(student))
}

export const updateStudent = async (payload: Partial<Student>) => {
    
    const auth = firebase.auth()
    const db = firebase.firestore()

    const userId = auth.currentUser?.uid
    if (!userId) { throw new Error('Couldn\'t find a user id for the logged in user') }

    await db.doc(`students/${userId}`).update(sanitizeObjectForFirebase(payload))

}