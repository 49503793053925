import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { CloseIcon } from '../../../icons/close-icon/CloseIcon'
import { grey } from "@ant-design/colors";
import { Colors } from '../../../../common/constants/colors';

type DialogHeaderProps = {
	title: string
	onClose?: () => void
}

export const DialogHeader: FC<DialogHeaderProps> = ({
	title,
	onClose,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.dialogHeader}>
			<h2>{title}</h2>
			{onClose && (
				<button className={styles.closeButton} onClick={onClose}>
					<CloseIcon size={18} color={Colors.graphite} />
				</button>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	dialogHeader: {
		display: 'grid',
		gridTemplateColumns: '1fr max-content',
		alignItems: 'center',
		paddingLeft: 24,
		paddingRight: 24,
		paddingTop: 20,
		paddingBottom: 20,
		borderBottom: `1px solid ${Colors.border}`,
	},
	closeButton: {
		border: 'none',
		backgroundColor: 'none',
		background: 'none',
		cursor: 'pointer',
	}
})