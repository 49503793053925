import { red } from '@ant-design/colors'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { DeleteSubjectDialog } from '../../dialogs/delete-subject-dialog/DeleteSubjectDialog'
import { DeleteIcon } from '../../icons/delete-icon/DeleteIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type DeleteSubjectOverflowMenuItemProps = {
	subject: RecordWithID<Subject>
	onClose?: () => void
}

export const DeleteSubjectOverflowMenuItem: FC<DeleteSubjectOverflowMenuItemProps> = ({
	subject,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setShowDialog, onClose])

	return (
		<>
			<OverflowMenuItem icon={DeleteIcon} textColor={red.primary} onClick={handleMenuItemClick}>
				Delete Subject
			</OverflowMenuItem>
			<DeleteSubjectDialog visible={showDialog} onClose={handleDialogClose} subject={subject} />
		</>
	)
}

const useStyles = createUseStyles({
	deleteSubjectOverflowMenuItem: {
		display: 'grid'
	}
})