import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageStudentsDialog } from '../../../dialogs/admin-manage-students-dialog/AdminManageStudentsDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledStudentsIcon } from '../../../styled-icons/styled-students-icon/StyledStudentsIcon'

type ManageStudentsActionProps = {}

export const ManageStudentsAction: FC<ManageStudentsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [setShowDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledStudentsIcon />
				<h3>Manage Students</h3>
			</ListItem>
			<AdminManageStudentsDialog visible={showDialog} onClose={handleDialogClose} />
		</>

	)
}

const useStyles = createUseStyles({
	manageStudentsAction: {
		display: 'grid'
	}
})