import { Portal } from '@blueprintjs/core'
import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileDialogMaxWidth } from '../../common/constants/breakpoints'
import { DialogsContext } from '../dialogs-provider/dialogs-context'
import * as uuid from 'uuid'
import { useMediaQuery } from '@react-hook/media-query'

export type DialogProps = {
	visible: boolean
	onClose?: () => void
}

export const Dialog: FC<DialogProps> = ({
	visible,
	onClose,
	children,
}) => {

	// hooks
	const styles = useStyles()
	const { currentDialogs, setCurrentDialogs } = useContext(DialogsContext)
	const uid = useMemo(() => uuid.v4(), [])
	const dialogIsTopDialog = useMemo(() => {
		return currentDialogs[currentDialogs.length - 1] === uid
	}, [currentDialogs, uid])
	const dialogIsBottomDialog = useMemo(() => {
		return currentDialogs[0] === uid
	}, [currentDialogs, uid])
	const portalClassName = useMemo(() => {
		const classes = [styles.dialogPortal]
		if (visible) {
			classes.push(styles.dialogPortalVisible)
		}
		return classes.join(' ')
	}, [visible, styles.dialogPortal, styles.dialogPortalVisible])
	const backgroundClassName = useMemo(() => {
		const classes = [styles.background]
		if (visible && dialogIsBottomDialog) {
			classes.push(styles.backgroundVisible)
		}
		if (onClose) {
			classes.push(styles.allowClose)
		}
		return classes.join(' ')
	}, [visible, dialogIsBottomDialog, styles.background, styles.backgroundVisible, styles.allowClose])
	const dialogClassName = useMemo(() => {
		const classes = [styles.dialog]
		if (visible && dialogIsTopDialog) {
			classes.push(styles.dialogVisible)
		}
		return classes.join(' ')
	}, [visible, dialogIsTopDialog, styles.dialog, styles.dialgoVisible])
	const [showDialog, setShowDialog] = useState<boolean>(visible)
	const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([])
	const [windowInnerHeight, setWindowInnerHeight] = useState<number>()
	const isDesktop = useMediaQuery(`screen and (min-width: ${mobileDialogMaxWidth + 1}px)`)
	const dialogHeight = isDesktop ? undefined : windowInnerHeight ? windowInnerHeight - 24 : 0

	// event handlers
	const handleDialogClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
	}, [])

	// side effects
	useEffect(() => {
		if (visible) {
			setCurrentDialogs(currentDialogs => [...currentDialogs, uid])
		} else {
			setCurrentDialogs(currentDialogs => currentDialogs.filter(dialog => dialog !== uid))
		}
		return () => {
			setCurrentDialogs(currentDialogs => currentDialogs.filter(dialog => dialog !== uid))
		}
	}, [visible, uid, setCurrentDialogs])

	useEffect(() => {
		if (!visible) {
			const newTimeout = setTimeout(() => {
				setShowDialog(false)
			}, 400);
			setTimeouts(oldTimeouts => [...oldTimeouts, newTimeout])
		} else {
			timeouts.forEach(timeout => clearTimeout(timeout))
			setShowDialog(true)
		}
	}, [visible, setShowDialog, setTimeouts])

	useEffect(() => {
		setWindowInnerHeight(window.innerHeight)
	}, [window.innerHeight])

	useEffect(() => {
		const eventListener = () => {
			setWindowInnerHeight(window.innerHeight)
		}
		window.addEventListener('resize', eventListener)
		return () => {
			window.removeEventListener('resize', eventListener)
		}
	}, [])

	if (!showDialog) {
		return null
	}

	return (
		<Portal container={document.body} className={portalClassName}>
			<div className={styles.dialogInnerContainer} onClick={onClose}>
				<div className={dialogClassName} style={{ height: dialogHeight, maxHeight: dialogHeight }} onClick={handleDialogClick}>
					{children}
				</div>
			</div>
			<div className={backgroundClassName} onClick={onClose} />
		</Portal>
	)
}

const useStyles = createUseStyles({
	dialogPortal: {
		display: 'grid',
		position: 'fixed',
		left: 0,
		top: 0,
		width: '100vw',
		height: '100vh',
		gridTemplateColumns: '100vw',
		justifyContent: 'center',
		zIndex: 1,

		// animation
		pointerEvents: 'none',
	},
	dialogPortalVisible: {
		pointerEvents: 'all',
	},
	'@keyframes background-appear': {
		from: {
			opacity: 0,
		},
		to: {
			opacity: 0.15,
		},
	},
	background: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: -1,

		// animation
		transition: 'opacity 400ms ease',
		backgroundColor: 'black',
		opacity: 0,
	},
	allowClose: {
		cursor: 'pointer',
	},
	backgroundVisible: {
		opacity: 0.15,
		animation: `$background-appear 400ms ease`,
		animationFillMode: 'backwards',
	},
	dialogInnerContainer: {
		paddingTop: 24,
	},
	'@keyframes dialog-appear-mobile': {
		from: {
			transform: `translateY(calc(100vh - 24px))`,
			opacity: 0,
		},
		to: {
			transform: `translateY(0px)`,
			opacity: 1,
		},
	},
	'@keyframes dialog-appear-desktop': {
		from: {
			transform: `scale(0.9)`,
			opacity: 0,
		},
		to: {
			transform: `scale(1)`,
			opacity: 1,
		},
	},
	dialog: {
		backgroundColor: 'white',
		width: '100%',
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,
		boxShadow: '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
		display: 'grid',
		gridTemplateRows: 'max-content 1fr',
		gridAutoRows: 'max-content',
		cursor: 'unset',
		position: 'relative',
		
		// animation
		animation: `$dialog-appear-mobile 400ms ease`,
		animationFillMode: 'backwards',
		opacity: 0,
		transition: 'transform 400ms ease, opacity 400ms ease',
		transform: 'translateY(calc(100vh - 24px))',

		'&:before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 0,
			top: '100%',
			height: 1000,
			backgroundColor: 'white',
		}
	},
	dialogVisible: {
		opacity: 1,
		transform: 'translateY(0px)',
	},
	[`@media screen and (min-width: ${mobileDialogMaxWidth + 1}px)`]: {
		dialogPortal: {
			gridTemplateColumns: '750px',
		},
		dialogInnerContainer: {
			paddingTop: 68,
			paddingLeft: 24,
			paddingRight: 24,
			paddingBottom: 123,
		},
		dialog: {
			height: 'auto',
			maxHeight: 'calc(100vh - 68px - 123px)',
			borderRadius: 16,
			boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',

			// animation
			animation: `$dialog-appear-desktop 400ms ease`,
			animationFillMode: 'backwards',
			transform: 'scale(0.9)',

			'&:before': {
				display: 'none',
			}
		},
		dialogVisible: {
			transform: 'scale(1)',
		},
	}
})