import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../../common/constants/colors'
import { useStudents } from '../../../../../common/hooks/database/use-students'
import { Student } from '../../../../../common/types/types'
import { RecordWithID } from '../../../../../common/types/utils'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../../../form-row/FormRow'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'
import { StudentSelect } from '../../../../students/student-select/StudentSelect'
import { RegisterForASessionDialogContext } from '../../../register-for-a-session-dialog/register-for-a-session-dialog-context'

type ChooseStudentProps = {}

export const ChooseStudent: FC<ChooseStudentProps> = () => {

	// hooks
	const styles = useStyles()
	const { control, formState: { isValid }, setValue, watch, reset, trigger, getValues } = useForm({ mode: 'all' })
	const { onClose, setStudentId, setDialogState } = useContext(RegisterForASessionDialogContext)
	const student: RecordWithID<Student> | undefined = watch('student')
	const students = useStudents()

	// side effects
	useEffect(() => {
		setStudentId(student?.id)
	}, [student])

	// event handlers
	const handleClose = useCallback(() => {
		onClose && onClose()
		reset()
	}, [onClose, reset])

	const handleSubmit = useCallback(() => {
		setDialogState('registrationForm')
	}, [setDialogState])

	if (!students) {
		return (
			<>
				<DialogHeader title="Choose a Student to Register" onClose={handleClose} />
				<DialogBody>
					<div style={{ height: 300, }} />
				</DialogBody>
				<LoadingOverlay loading={true} />
			</>
		)
	}

	return (
		<>
			<DialogHeader title="Choose a Student to Register" onClose={handleClose} />
			<DialogBody>
				<FormRow label="Choose a student to register for a tutoring session">
					<Controller
						name="student"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<StudentSelect
								{...field}
								items={students}
								placeholder="Choose a Student..."
							/>
						)}
					/>
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.lightGray} onClick={handleClose}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!isValid} onClick={handleSubmit}>
					Continue
				</DialogFooterButton>
			</DialogFooter>
		</>
	)
}

const useStyles = createUseStyles({
	chooseStudent: {
		display: 'grid'
	}
})