import React, { FC } from 'react'
import { IconProps } from '../props'

export const WarnIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 21.333" fill={color}>
			<path id="Path_12" data-name="Path 12" d="M23.73,18.334a2,2,0,0,1-1.732,3H2a2,2,0,0,1-1.732-3L10.267,1a2,2,0,0,1,3.465,0ZM12,14.75a1.917,1.917,0,1,0,1.917,1.917A1.917,1.917,0,0,0,12,14.75ZM10.18,7.861l.309,5.667a.5.5,0,0,0,.5.473h2.023a.5.5,0,0,0,.5-.473l.309-5.667a.5.5,0,0,0-.5-.527H10.679a.5.5,0,0,0-.5.527Z" transform="translate(0)" />
		</svg>
	)
}