import { getNow } from "./date-logic"
import firebase from 'firebase'
import moment from "moment"
import { RecordWithID } from "../types/utils"
import { Registration, Student, Subject, Tutor, TutorAvailability } from "../types/types"
import xlsx, { WorkBook } from 'xlsx'

export const generatePairingList = async (): Promise<WorkBook> => {

    const db = firebase.firestore()

    const thisWeek = getNow().startOf('week').unix()

    let registrationsThisWeek = await getRecords<Registration>(
        db.collection('registrations').where('registrationWeekStartDate', '==', thisWeek)
    )
    const allStudents = await getRecords<Student>(db.collection('students'))
    const allTutors = await getRecords<Tutor>(db.collection('tutors'))
    const allSubjects = await getRecords<Subject>(db.collection('subjects'))

    const studentsMap = createRecordMap(allStudents)
    const tutorsMap = createRecordMap(allTutors)
    const subjectsMap = createRecordMap(allSubjects)

    const tutorAvailabilitiesThisWeek = await getRecords<TutorAvailability>(db.collection('tutorAvailabilities').where('registrationWeekStartDate', '==', thisWeek).where('availableRegistrations', '>', 0))
    const availableTutorsThisWeek = tutorAvailabilitiesThisWeek.map(availability => allTutors.find(tutor => tutor.id === availability.TutorID)) as RecordWithID<Tutor>[]

    const rtwData: RegistrationsThisWeekSheetData[] = registrationsThisWeek.map(registration => ({
        ID: registration.id,
        "Student Name": studentsMap[registration.StudentID].displayName,
        "Student Email": studentsMap[registration.StudentID].emailAddress,
        "Student Phone": studentsMap[registration.StudentID].phoneNumber,
        "Student School": studentsMap[registration.StudentID].schoolName,
        "Student Grade": studentsMap[registration.StudentID].grade,
        Subject: registration.SubjectID ? subjectsMap[registration.SubjectID].name : '',
        "1st Preferred Subject": (registration.registrationPreferences && registration.registrationPreferences[0]) ? subjectsMap[registration.registrationPreferences[0].SubjectID].name : '',
        "1st Preferred Subject's Preferred Tutors": (registration.registrationPreferences && registration.registrationPreferences[0] && registration.registrationPreferences[0].PreferredTutorIDs) ? registration.registrationPreferences[0].PreferredTutorIDs.map(tutorId => tutorsMap[tutorId].displayName).join(', ') : '',
        "2nd Preferred Subject": (registration.registrationPreferences && registration.registrationPreferences[1]) ? subjectsMap[registration.registrationPreferences[1].SubjectID].name : '',
        "2nd Preferred Subject's Preferred Tutors": (registration.registrationPreferences && registration.registrationPreferences[1] && registration.registrationPreferences[1].PreferredTutorIDs) ? registration.registrationPreferences[1].PreferredTutorIDs.map(tutorId => tutorsMap[tutorId].displayName).join(', ') : '',
        "3rd Preferred Subject": (registration.registrationPreferences && registration.registrationPreferences[2]) ? subjectsMap[registration.registrationPreferences[2].SubjectID].name : '',
        "3rd Preferred Subject's Preferred Tutors": (registration.registrationPreferences && registration.registrationPreferences[2] && registration.registrationPreferences[2].PreferredTutorIDs) ? registration.registrationPreferences[2].PreferredTutorIDs.map(tutorId => tutorsMap[tutorId].displayName).join(', ') : '',
        "Paired Tutor Name": registration.TutorID ? tutorsMap[registration.TutorID].displayName : '',
        "Paired Tutor Email": registration.TutorID ? tutorsMap[registration.TutorID].emailAddress : '',
        "Paired Tutor Phone": registration.TutorID ? tutorsMap[registration.TutorID].phoneNumber : '',
        "Paired Tutor School": registration.TutorID ? tutorsMap[registration.TutorID].schoolName : '',
    }))

    const ttwData: TutorsThisWeekSheetData[] = availableTutorsThisWeek.map(tutor => ({
        ID: tutor.id,
        "Tutor Name": tutor.displayName,
        "Tutor Email": tutor.emailAddress,
        "Tutor Phone": tutor.phoneNumber,
        "Tutor School": tutor.schoolName,
        Subjects: tutor.SubjectIDs.map(subjectId => (subjectsMap[subjectId] ? subjectsMap[subjectId].name : [])).join(', '),
        "Preferred Student": tutor.PreferredStudentID && studentsMap[tutor.PreferredStudentID] ? studentsMap[tutor.PreferredStudentID].displayName : '',
        "Paired Student": registrationsThisWeek.find(registration => registration.TutorID === tutor.id) ? studentsMap[registrationsThisWeek.find(registration => registration.TutorID === tutor.id)!.StudentID].displayName : ''
    }))

    const subjectsData: AllSubjectsSheetData[] = allSubjects.map(subject => ({
        ID: subject.id,
        "Subject Name": subject.name,
        Priority: subject.pairingPriority,
    }))

    const tutorsData: AllTutorsSheetData[] = allTutors.map(tutor => ({
        ID: tutor.id,
        "Tutor Name": tutor.displayName,
        "Tutor Email": tutor.emailAddress,
        "Tutor Phone": tutor.phoneNumber,
        "Tutor School": tutor.schoolName,
        "Preferred Student": tutor.PreferredStudentID && studentsMap[tutor.PreferredStudentID] ? studentsMap[tutor.PreferredStudentID].displayName : '',
    }))

    const studentsData: AllStudentsSheetData[] = allStudents.map(student => ({
        ID: student.id,
        "Student Name": student.displayName,
        "Student Email": student.emailAddress,
        "Student Phone": student.phoneNumber,
        "Student School": student.schoolName,
        "Student Grade": student.grade,
    }))

    const workbook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(workbook, xlsx.utils.json_to_sheet(rtwData), 'Registrations this Week')
    xlsx.utils.book_append_sheet(workbook, xlsx.utils.json_to_sheet(ttwData), 'Tutors this Week')
    xlsx.utils.book_append_sheet(workbook, xlsx.utils.json_to_sheet(subjectsData), 'All Subjects')
    xlsx.utils.book_append_sheet(workbook, xlsx.utils.json_to_sheet(tutorsData), 'All Tutors')
    xlsx.utils.book_append_sheet(workbook, xlsx.utils.json_to_sheet(studentsData), 'All Students')

    return workbook

}

export const getRecords = async <RecordType extends {}>(query: firebase.firestore.Query): Promise<RecordWithID<RecordType>[]> => {

    const snapshots = await query.get()
    return snapshots.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as RecordType,
    }))
    
}

export const createRecordMap = <RecordType extends {}>(records: RecordWithID<RecordType>[]): { [key: string]: RecordWithID<RecordType> } => {

    const recordMap: { [key: string]: RecordWithID<RecordType> } = {}
    records.forEach(record => {
        recordMap[record.id] = { ...record }
    })

    return recordMap

}

type RegistrationsThisWeekSheetData = {
    ID: string
    [`Student Name`]: string
    [`Student Email`]: string
    [`Student Phone`]: string
    [`Student School`]: string
    [`Student Grade`]: string
    [`Subject`]: string
    [`1st Preferred Subject`]: string
    [`1st Preferred Subject's Preferred Tutors`]: string
    [`2nd Preferred Subject`]: string
    [`2nd Preferred Subject's Preferred Tutors`]: string
    [`3rd Preferred Subject`]: string
    [`3rd Preferred Subject's Preferred Tutors`]: string
    [`Paired Tutor Name`]: string
    [`Paired Tutor Email`]: string
    [`Paired Tutor Phone`]: string
    [`Paired Tutor School`]: string
}

type TutorsThisWeekSheetData = {
    ID: string
    [`Tutor Name`]: string
    [`Tutor Email`]: string
    [`Tutor Phone`]: string
    [`Tutor School`]: string
    [`Subjects`]: string
    [`Preferred Student`]: string
    [`Paired Student`]: string
}

type AllSubjectsSheetData = {
    ID: string
    [`Subject Name`]: string
    [`Priority`]: number
}

type AllTutorsSheetData = {
    ID: string
    [`Tutor Name`]: string
    [`Tutor Email`]: string
    [`Tutor Phone`]: string
    [`Tutor School`]: string
    [`Preferred Student`]: string
}

type AllStudentsSheetData = {
    ID: string
    [`Student Name`]: string
    [`Student Email`]: string
    [`Student Phone`]: string
    [`Student School`]: string
    [`Student Grade`]: string
}