import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { HomeSection } from '../../../home-section/HomeSection'
import { ManageTutorInformationAction } from '../../home-actions/manage-tutor-information-action/ManageTutorInformationAction'
import { ManageTutorPreferredStudentsAction } from '../../home-actions/manage-tutor-preferred-students-action/ManageTutorPreferredStudentsAction'
import { UpdateTutorSubjectsAction } from '../../home-actions/update-tutor-subjects-action/UpdateTutorSubjectsAction'

type ManageTutorTutoringSectionProps = {}

export const ManageTutorTutoringSection: FC<ManageTutorTutoringSectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isTutor } = useCurrentUserProfile()

	if (!isTutor) {
		return null
	}

	return (
		<HomeSection title="Manage My Tutoring" helpText="Manage your subjects for tutoring students.">
			<UpdateTutorSubjectsAction />
			<ManageTutorInformationAction />
			<ManageTutorPreferredStudentsAction />
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	manageTutorTutoringSection: {
		display: 'grid'
	}
})