import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { FirebaseContext } from '../../firebase-provider/firebase-context'
import { DialogState, RegisterForASessionDialogContext } from './register-for-a-session-dialog-context'
import { RegisterForASession } from './subcomponents/register-for-a-session-dialog/RegisterForASessionFormDialog'
import { Success } from './subcomponents/success/Success'

type RegisterForASessionDialogProps = {} & DialogProps

export const RegisterForASessionDialog: FC<RegisterForASessionDialogProps> = ({
    visible,
    onClose,
}) => {

    // hooks
    const { userId } = useContext(FirebaseContext)
    const [dialogState, setDialogState] = useState<DialogState>('registrationForm')
    const [registrationId, setRegistrationId] = useState<string>()

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'RegisterForASessionDialog' })
		}
	}, [visible])
    
    // event handlers
    const handleClose = useCallback(() => {
        onClose && onClose()
        setTimeout(() => {
            setDialogState('registrationForm')
            setRegistrationId(undefined)
        }, 410);
    }, [setDialogState, setRegistrationId, onClose])
    
    return (
        <RegisterForASessionDialogContext.Provider value={{
            studentId: userId,
            setStudentId: () => {},
            dialogState,
            setDialogState,
            onClose: handleClose,
            registrationId,
            setRegistrationId,
        }}>
            <Dialog visible={visible} onClose={handleClose}>
                {dialogState === 'registrationForm' && (
                    <RegisterForASession />
                )}
                {dialogState === 'success' && (
                    <Success />
                )}
            </Dialog>
        </RegisterForASessionDialogContext.Provider>
    )
}