import { Spinner } from '@blueprintjs/core'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutorAvailability } from '../../../common/hooks/database/use-tutor-availability'
import { FirebaseContext } from '../../firebase-provider/firebase-context'
import moment from 'moment'
import { green, red } from '@ant-design/colors'
import { Colors } from '../../../common/constants/colors'
import { CaretDownIcon } from '../../icons/caret-down-icon/CaretDownIcon'
import { Shadows } from '../../../common/constants/shadows'
import { mobileDialogMaxWidth } from '../../../common/constants/breakpoints'
import { AvailableRegistrationsMenu } from '../available-registrations-menu/AvailableRegistrationsMenu'
import { getDate, getNow } from '../../../common/logic/date-logic'

type AvailabilityToggleProps = {
	weekStartDate: number,
	tutorId: string,
}

export const AvailabilityToggle: FC<AvailabilityToggleProps> = ({
	weekStartDate,
	tutorId,
}) => {

	// hooks
	const { userId } = useContext(FirebaseContext)
	const styles = useStyles()
	const availability = useTutorAvailability(tutorId, weekStartDate)
	const available = useMemo(() => (availability && availability.availableRegistrations > 0), [availability])
	const warnIfTutor = useMemo(() => Math.abs(getNow().startOf('week').diff(getDate(weekStartDate), 'weeks')) < 2, [weekStartDate])
	/* const warn = useMemo(() => !available && userId === tutorId && warnIfTutor, [available, userId, tutorId, warnIfTutor]) */
	const warn = false
	const [menuOpen, setMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleToggleClick = useCallback(() => {
		setMenuOpen(true)
	}, [setMenuOpen])

	const handleMenuClose = useCallback(() => {
		setMenuOpen(false)
	}, [setMenuOpen])

	if (!availability) {
		return (
			<Spinner size={18} />
		)
	}

	return (
		<div className={styles.availabilityContainer}>
			<div className={`${styles.availabilityToggle} ${available && styles.available} ${warn && styles.warn}`} onClick={handleToggleClick}>
				{available ? (
					<span>Available</span>
				) : (
					<span>Not Available</span>
				)}
				<CaretDownIcon size={18} color={available ? green.primary : warn ? red.primary : Colors.gray} />
			</div>
			<AvailableRegistrationsMenu
				tutorId={tutorId}
				registrationWeekStartDate={weekStartDate}
				visible={menuOpen}
				onClose={handleMenuClose}
				initialRegistrationsCount={availability.availableRegistrations}
			/>
		</div>
	)
}

const useStyles = createUseStyles({
	availabilityContainer: {
		position: 'relative',
	},
	availabilityToggle: {
		display: 'grid',
		border: `1px solid ${Colors.gray}`,
		color: Colors.gray,
		gridTemplateColumns: '130px 18px',
		padding: 8,
		borderRadius: 6,
		userSelect: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: Colors.whiteButton.hover,
		},
		'&:active': {
			backgroundColor: Colors.whiteButton.active,
		},
	},
	available: {
		borderColor: green.primary,
		color: green.primary,
		'&:hover': {
			backgroundColor: green[0],
		},
		'&:active': {
			backgroundColor: green[1],
		},
	},
	warn: {
		borderColor: red.primary,
		color: red.primary,
		'&:hover': {
			backgroundColor: red[0],
		},
		'&:active': {
			backgroundColor: red[1],
		},
	},
})