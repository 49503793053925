import { red } from '@ant-design/colors'
import { message } from 'antd'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { useStudent } from '../../../common/hooks/database/use-student'
import { useTutor } from '../../../common/hooks/database/use-tutor'
import { cancelSession, updateRegistration } from '../../../common/logic/registration-logic'
import { Registration } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { Dialog } from '../../dialog/Dialog'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { ManuallyPairRegistrationDialog } from '../../dialogs/manually-pair-registration-dialog/ManuallyPairRegistrationDialog'
import { CancelIcon } from '../../icons/cancel-icon/CancelIcon'
import { EmailIcon } from '../../icons/email-icon/EmailIcon'
import { LinkIcon } from '../../icons/link-icon/LinkIcon'
import { UnlinkIcon } from '../../icons/unlink-icon/UnlinkIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'
import { EmailStudentOverflowItem } from '../../students/email-student-overflow-item/EmailStudentOverflowItem'

type RegistrationListItemMenuProps = {
	registration: RecordWithID<Registration>
	onClose?: () => void
}

export const RegistrationListItemMenu: FC<RegistrationListItemMenuProps> = ({
	registration,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const { isAdmin, isStudent, isTutor } = useCurrentUserProfile()
	const { student } = useStudent(registration.StudentID)
	const { tutor } = useTutor(registration.TutorID)
	const [showCancelSessionDialog, setShowCancelSessionDialog] = useState<boolean>(false)
	const dialogSupportingText = useMemo(() => {
		if (isAdmin) { return 'If you do, we\'ll notify the student and the tutor that the session has been canceled.' }
		else if (isStudent) { return 'If you do, we\'ll notify the tutor that the session has been canceled.' }
		else if (isTutor) { return 'If you do, we\'ll notify the student that the session has been canceled.' }
		else { return '' }
	}, [isAdmin, isStudent, isTutor])
	const isWaitlisted = registration.TutorID === null
	const [manuallyPairRegistrationDialogVisible, setManuallyPairRegistrationDialogVisible] = useState<boolean>(false)

	// event handlers
	const handleEmailTutorClick = useCallback(() => {
		if (tutor) {
			window.open(`mailto:${tutor.emailAddress}`)
		}
	}, [tutor])

	const handleCancelSessionMenuItemClick = useCallback(() => {
		setShowCancelSessionDialog(true)
	}, [setShowCancelSessionDialog])

	const handleGoBackButtonClick = useCallback(() => {
		setShowCancelSessionDialog(false)
		onClose && onClose()
	}, [setShowCancelSessionDialog, onClose])

	const handleCancelSessionButtonClick = useCallback(() => {
		if (isTutor && !isAdmin) {
			updateRegistration(registration.id, { TutorID: undefined }).then(() => {
				onClose && onClose()
				message.success("We successfully cancelled your session for you.")
			})
		} else {
			cancelSession(registration.id).then(() => {
				onClose && onClose()
				message.success("We successfully cancelled your session for you.")
			})
		}
	}, [registration.id, onClose, isTutor, isAdmin])
	
	const handleManuallyPairRegistrationButtonClick = useCallback(() => {
		setManuallyPairRegistrationDialogVisible(true)
	}, [])

	const handleManullyPairRegistrationDialogClose = useCallback(() => {
		setManuallyPairRegistrationDialogVisible(false)
	}, [])

	const handleManuallyUnpairRegistrationButtonClick = useCallback(() => {
		updateRegistration(registration.id, {
			TutorID: undefined,
			SubjectID: undefined,
		})
	}, [registration.id])
	
	return (
		<>
			<div className={styles.registrationListItemMenu}>
				{(isTutor || isAdmin) && student && (
					<EmailStudentOverflowItem student={student} onClose={onClose} />
				)}
				{(isStudent || isAdmin) && (
					<OverflowMenuItem icon={EmailIcon} onClick={handleEmailTutorClick}>
						Email Tutor
					</OverflowMenuItem>
				)}
				{(isAdmin && isWaitlisted) && (
					<OverflowMenuItem icon={LinkIcon} onClick={handleManuallyPairRegistrationButtonClick}>
						Pair Registration
					</OverflowMenuItem>
				)}
				{(isAdmin && !isWaitlisted) && (
					<OverflowMenuItem icon={UnlinkIcon} onClick={handleManuallyUnpairRegistrationButtonClick}>
						Unpair Registration
					</OverflowMenuItem>
				)}
				<OverflowMenuItem icon={CancelIcon} textColor={red.primary} onClick={handleCancelSessionMenuItemClick}>
					Cancel Session
				</OverflowMenuItem>
			</div>
			<Dialog visible={showCancelSessionDialog} onClose={handleGoBackButtonClick}>
				<DialogHeader title="Are you sure?" />
				<DialogBody>
					Are you sure you want to cancel this session?
					<br />
					<br />
					{dialogSupportingText}
				</DialogBody>
				<DialogFooter>
					<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleGoBackButtonClick}>
						Go Back
					</DialogFooterButton>
					<DialogFooterButton color={red.primary} textColor="white" onClick={handleCancelSessionButtonClick}>
						Cancel Session
					</DialogFooterButton>
				</DialogFooter>
			</Dialog>
			<ManuallyPairRegistrationDialog
				registration={registration}
				visible={manuallyPairRegistrationDialogVisible}
				onClose={handleManullyPairRegistrationDialogClose}
			/>
		</>
	)
}

const useStyles = createUseStyles({
	registrationListItemMenu: {
		display: 'grid'
	}
})