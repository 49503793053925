import { gold } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { RegistrationIcon } from '../../icons/registration-icon/RegistrationIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledRegistrationIconProps = {}

export const StyledRegistrationIcon: FC<StyledRegistrationIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={RegistrationIcon} colors={{ background: gold.primary, foreground: gold[0] }} />
	)
}

const useStyles = createUseStyles({
	styledRegistrationIcon: {
		display: 'grid'
	}
})