import firebase from 'firebase'
import { logAnalyticsEvent } from './analytics'

export const signInWithGoogle = async () => {

    logAnalyticsEvent('action', { type: 'signInWithGoogle', payload: {  } })

    const auth = firebase.auth()
    await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

export const checkIfUser = async (email: string): Promise<boolean> => {

    logAnalyticsEvent('action', { type: 'checkIfUser', payload: { email } })

    const auth = firebase.auth()
    const signInMethods = await auth.fetchSignInMethodsForEmail(email)
    if (signInMethods.length > 0) {
        return true
    }
    return false
}

export const createAccount = async (email: string, password: string, name: string) => {

    logAnalyticsEvent('action', { type: 'createAccount', payload: { email, name } })

    const auth = firebase.auth()
    const response = await auth.createUserWithEmailAndPassword(email, password)
    if (response.user) {
        const db = firebase.firestore()
        return db.doc(`userProfiles/${response.user?.uid}`).set({
            name,
            email,
        })
    }
}

export const signIn = async (email: string, password: string) => {

    logAnalyticsEvent('action', { type: 'signIn', payload: { email } })

    const auth = firebase.auth()
    return auth.signInWithEmailAndPassword(email, password)
}

export const signOut = async () => {

    logAnalyticsEvent('action', { type: 'signOut', payload: { } })

    const auth = firebase.auth()
    auth.signOut()
}

export const sendPasswordResetLink = async (email: string) => {

    logAnalyticsEvent('action', { type: 'sendPasswordResetLink', payload: { email } })

    const auth = firebase.auth()
    return auth.sendPasswordResetEmail(email)
}