import { createContext, Dispatch, SetStateAction } from "react"

type DialogsContext = {
    currentDialogs: string[]
    setCurrentDialogs: Dispatch<SetStateAction<string[]>>
}

export const DialogsContext = createContext<DialogsContext>({
    currentDialogs: [],
    setCurrentDialogs: () => {},
})