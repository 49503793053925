import { Spinner } from '@blueprintjs/core'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type LoadingOverlayProps = {
	loading: boolean
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
	loading,
}) => {

	// hooks
	const styles = useStyles()

	if (!loading) {
		return null
	}

	return (
		<div className={styles.loadingOverlay}>
			<Spinner />
		</div>
	)
}

const useStyles = createUseStyles({
	loadingOverlay: {
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
	},
})