import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useStudents } from '../../../common/hooks/database/use-students'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Student } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { StudentListItem } from '../../students/student-list-item/StudentListItem'

type AdminManageStudentsDialogProps = {} & DialogProps

export const AdminManageStudentsDialog: FC<AdminManageStudentsDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const students = useStudents()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredStudents = useMatchSortedRecords(students, searchQuery, ['displayName'])

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageStudentsDialog' })
		}
	}, [visible])

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSearchTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader onClose={onClose} title="Manage Students" />
			<DialogBodywithSearch searchInputProps={{ onChange: handleSearchTextChange, value: searchQuery, placeholder: 'Search Students' }} compactView={true}>
				{filteredStudents?.map(student => (
					<StudentListItem key={student.id} student={student} compactView={true} />
				))}
			</DialogBodywithSearch>
			<DialogFooter>
			<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	adminManageStudentsDialog: {
		display: 'grid'
	}
})