import { blue } from '@ant-design/colors'
import React, { FC, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../../common/hooks/database/use-current-user-profile'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { CompleteProfileDialogContext } from '../../complete-profile-dialog-context'

type SuccessProps = {}

export const Success: FC<SuccessProps> = () => {

	// hooks
	const styles = useStyles()
	const { onClose } = useContext(CompleteProfileDialogContext)
	const { isStudent, isTutor } = useCurrentUserProfile()

	return (
		<>
			<DialogHeader title="Create Profile" />
			<DialogBody>
				<h2>All Done!</h2>
				<p>We've created your profile for you. You can now {isStudent && messages.student}{isTutor && messages.tutor}</p>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={blue.primary} textColor='white' onClick={onClose}>
					Go to Dashboard
				</DialogFooterButton>
			</DialogFooter>
		</>
	)
}

const useStyles = createUseStyles({
	success: {
		display: 'grid'
	}
})

const messages = {
    tutor: 'sign up to tutor students!',
    student: 'sign up for tutoring!',
}