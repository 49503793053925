import React, { FC } from 'react'
import { IconProps } from '../props'

export const CloseIcon: FC<IconProps> = ({
	size,
	color,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 17.28 17.28" fill={color}>
			<path id="Path_10" data-name="Path 10" d="M11.915,88.64l4.913-4.913a1.544,1.544,0,0,0,0-2.184l-1.092-1.092a1.544,1.544,0,0,0-2.184,0L8.64,85.365,3.727,80.452a1.544,1.544,0,0,0-2.184,0L.452,81.544a1.544,1.544,0,0,0,0,2.184L5.365,88.64.452,93.553a1.544,1.544,0,0,0,0,2.184l1.092,1.092a1.544,1.544,0,0,0,2.184,0L8.64,91.915l4.913,4.913a1.544,1.544,0,0,0,2.184,0l1.092-1.092a1.544,1.544,0,0,0,0-2.184Z" transform="translate(0 -80)" />
		</svg>
	)
}