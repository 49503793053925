import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileMaxWidth } from '../../common/constants/breakpoints'
import { AppTopBar } from './subcomponents/app-top-bar/AppTopBar'

type AppShellProps = {}

export const AppShell: FC<AppShellProps> = ({
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.appShell}>
			<AppTopBar />
			<div className={styles.content}>
				{children}
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	appShell: {
		display: 'grid',
		position: 'relative',
	},
	content: {
		display: 'grid',
		paddingLeft: 20,
		paddingRight: 20,
	},
	[`@media screen and (min-width: ${mobileMaxWidth - 1}px)`]: {
		content: {
			justifyContent: 'center'
		}
	}
})