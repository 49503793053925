import { blue, red } from '@ant-design/colors'
import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { AddIcon } from '../icons/add-icon/AddIcon'

type AddorRemoveButtonProps = {
	value: boolean
	onChange?: (value: boolean) => void
}

export const AddorRemoveButton: FC<AddorRemoveButtonProps> = ({
	value,
	onChange,
}) => {

	// hooks
	const styles = useStyles()

	// event handlers
	const handleClick = useCallback(() => {
		onChange && onChange(!value)
	}, [onChange, value])

	return (
		<div className={`${styles.addOrRemoveButton} ${value && styles.remove}`} onClick={handleClick}>
			{value ? (
				<div>
					Remove
				</div>
			) : (
				<AddIcon size={18} color={blue.primary} />
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	addOrRemoveButton: {
		display: 'grid',
		border: `1px solid ${blue.primary}`,
		borderRadius: '4px',
		padding: 6,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: blue[0],
		},
		'&:active': {
			backgroundColor: blue[1],
		},
	},
	remove: {
		border: `1px solid ${red.primary}`,
		color: red.primary,
		'&:hover': {
			backgroundColor: red[0],
		},
		'&:active': {
			backgroundColor: red[1],
		},
	},
})