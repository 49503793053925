import { useEffect, useMemo } from "react"
import { useFirestoreDocument } from "../../hooks/firebase/firebase"

export const createUseDocument = <T extends any>(collectionId: string) => {
    return (documentId?: string): T | undefined => {

        // hooks
        const documentPath = useMemo(() => documentId ? `${collectionId}/${documentId}` : undefined, [collectionId, documentId])
        const [record, setDocumentPath] = useFirestoreDocument<T>(documentPath)

        // side effects
        useEffect(() => {
            setDocumentPath(documentPath)
        }, [documentPath])

        return record
    }
}