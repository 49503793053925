import { useMemo } from "react"
import { UserProfile } from "../../types/types"
import { RecordWithID } from "../../types/utils"
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data"
import { useFirestoreQuery } from "../firebase/firebase"

export const useUserProfiles = (): RecordWithID<UserProfile>[] | undefined => {

    // hooks
    const [userProfileIds, userProfilesData] = useFirestoreQuery<UserProfile>('userProfiles', { filters: [], sorts: [['displayName', 'asc']] })
    const userProfiles = useMemo(() => combineIdsAndFirebaseQueryData(userProfileIds, userProfilesData), [userProfileIds, userProfilesData])

    return userProfiles

}