import { blue, red } from '@ant-design/colors'
import { message } from 'antd'
import React, { FC, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { createSubject, deleteSubject } from '../../../common/logic/subject-logic'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../form-row/FormRow'

type CreateSubjectDialogProps = {} & DialogProps

export const CreateSubjectDialog: FC<CreateSubjectDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const { register, reset, formState: { isValid }, getValues } = useForm({ mode: 'all' })
	const styles = useStyles()

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'CreateSubjectDialog' })
		}
	}, [visible])

	// event handlers
	const handleClose = useCallback(() => {
		reset()
		onClose && onClose()
	}, [reset])

	const handleCreateSubjectClick = useCallback(() => {
		const { subjectName } = getValues() as { subjectName: string }
		createSubject({
			name: subjectName,
			pairingPriority: 0,
		}).then(() => {
			message.success('Successfully created new subject')	
			handleClose()
		})
	}, [getValues, handleClose])

	return (
		<Dialog visible={visible} onClose={handleClose}>
			<DialogHeader title="Create a New Subject" />
			<DialogBody>
				<FormRow label="What do you want to name this subject?">
					<input {...register('subjectName', { required: true })} placeholder="Enter a Subject Name" />
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleClose}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" onClick={handleCreateSubjectClick} disabled={!isValid}>
					Create Subject
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	createSubjectDialog: {
		display: 'grid'
	}
})