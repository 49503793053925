import { blue, red } from '@ant-design/colors'
import { Avatar, Tag } from 'antd'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { useUserIsDisabled } from '../../../common/hooks/database/use-user-is-disabled'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { DisableAccountOverflowMenuItem } from '../../admin/disable-account-overflow-menu-item/DisableAccountOverflowMenuItem'
import { ListItem } from '../../list-item/ListItem'
import { OverflowMenuButton } from '../../overflow-menu/subcomponents/overflow-menu-button/OverflowMenuButton'
import { EmailTutorOverflowMenuItem } from '../email-tutor-overflow-menu-item/EmailTutorOverflowMenuItem'
import { UpdateTutorInformationListItem } from '../update-tutor-information-list-item/UpdateTutorInformationListItem'
import { UpdateTutorPreferredStudentOverflowMenuItem } from '../update-tutor-preferred-student-overflow-menu-item/UpdateTutorPreferredStudentOverflowMenuItem'
import { UpdateTutorSubjectsOverflowMenuItem } from '../update-tutor-subjects-overflow-menu-item/UpdateTutorSubjectsOverflowMenuItem'

type TutorListItemProps = {
	tutor: RecordWithID<Tutor>
	compactView?: boolean
}

export const TutorListItem: FC<TutorListItemProps> = ({
	tutor,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(tutor)
	const userIsDisabled = useUserIsDisabled(tutor.id)
	const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleOverflowMenuClose = useCallback(() => {
		setOverflowMenuOpen(false)
	}, [setOverflowMenuOpen])

	return (
		<ListItem compactView={compactView}>
			<Avatar style={{ backgroundColor: blue.primary }} size={32}>
				{initials}
			</Avatar>
			<h3>{tutor.displayName}</h3>
			{userIsDisabled && (
				<Tag color={red.primary}>Disabled</Tag>
			)}
			<OverflowMenuButton visible={overflowMenuOpen} onMenuOpenChange={setOverflowMenuOpen}>
				<UpdateTutorInformationListItem tutorId={tutor.id} onClose={handleOverflowMenuClose} />
				<UpdateTutorSubjectsOverflowMenuItem tutorId={tutor.id} onClose={handleOverflowMenuClose} />
				<UpdateTutorPreferredStudentOverflowMenuItem tutorId={tutor.id} onClose={handleOverflowMenuClose} />
				<EmailTutorOverflowMenuItem tutor={tutor} onClose={handleOverflowMenuClose} />
				<DisableAccountOverflowMenuItem userId={tutor.id} onClose={handleOverflowMenuClose} />
			</OverflowMenuButton>
		</ListItem>
	)
}

const useStyles = createUseStyles({
	tutorListItem: {
		display: 'grid'
	}
})