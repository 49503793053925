import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { useStudent } from '../../../common/hooks/database/use-student'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { StudentDetailsForm } from './subcomponents/student-details-form/StudentDetailsForm'

type UpdateStudentInformationDialogProps = {
	studentId: string
} & DialogProps

export const UpdateStudentInformationDialog: FC<UpdateStudentInformationDialogProps> = ({
	studentId,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const { student } = useStudent(studentId)

	return (
		<Dialog visible={visible} onClose={onClose}>
			{student && (
				<StudentDetailsForm student={student} onClose={onClose} />
			)}
		</Dialog>
	)
}

const useStyles = createUseStyles({
	updateStudentInformationDialog: {
		display: 'grid'
	}
})