import { blue } from '@ant-design/colors'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { Subject, Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ListItem } from '../../list-item/ListItem'
import { OverflowMenuButton } from '../../overflow-menu/subcomponents/overflow-menu-button/OverflowMenuButton'
import { RemoveTutorFromSubjectOverflowMenuItem } from '../remove-tutor-from-subject-overflow-menu-item/RemoveTutorFromSubjectOverflowMenuItem'

type TutorinSubjectListItemProps = {
	tutor: RecordWithID<Tutor>
	subject: RecordWithID<Subject>
	compactView?: boolean
}

export const TutorinSubjectListItem: FC<TutorinSubjectListItemProps> = ({
	tutor,
	subject,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(tutor)
	const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleOverflowMenuClose = useCallback(() => {
		setOverflowMenuOpen(false)
	}, [setOverflowMenuOpen])

	return (
		<ListItem compactView={compactView}>
			<Avatar style={{ backgroundColor: blue.primary }}>{initials}</Avatar>
			<h3>{tutor.displayName}</h3>
			<OverflowMenuButton visible={overflowMenuOpen} onMenuOpenChange={setOverflowMenuOpen}>
				<RemoveTutorFromSubjectOverflowMenuItem subject={subject} tutor={tutor} onClose={handleOverflowMenuClose} />
			</OverflowMenuButton>
		</ListItem>
	)
}

const useStyles = createUseStyles({
	tutorInSubjectListItem: {
		display: 'grid'
	}
})