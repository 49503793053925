import React, { FC } from 'react'
import { IconProps } from '../props'

export const RefreshIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 616 409.942" fill={color}>
			<path id="Path_1" data-name="Path 1" d="M624.485,353.456l-104,104a12,12,0,0,1-16.971,0l-104-104a12,12,0,0,1,0-16.971l16.877-16.877a12,12,0,0,1,17.064.094L488,375.465V152H284.024a12,12,0,0,1-8.486-3.516l-24-24A12,12,0,0,1,260.024,104H512a24,24,0,0,1,24,24V375.465L590.545,319.7a12,12,0,0,1,17.064-.094l16.877,16.877a12,12,0,0,1,0,16.97ZM364.461,363.515A12,12,0,0,0,355.976,360H152V136.535L206.545,192.3a12,12,0,0,0,17.064.094l16.877-16.877a12,12,0,0,0,0-16.971l-104-104a12,12,0,0,0-16.971,0l-104,104a12,12,0,0,0,0,16.971l16.877,16.877a12,12,0,0,0,17.064-.094L104,136.535V384a24,24,0,0,0,24,24H379.976a12,12,0,0,0,8.485-20.485l-24-24Z" transform="translate(-12 -51.029)" />
		</svg>
	)
}