import React, { FC, forwardRef, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileDialogMaxWidth } from '../../../../common/constants/breakpoints'

type DialogBodyProps = {
	children?: ReactNode
	disableScroll?: boolean
}

export const DialogBody = forwardRef<HTMLDivElement, DialogBodyProps>(({
	children,
	disableScroll,
}, ref) => {

	// hooks
	const styles = useStyles()

	return (
		<div ref={ref} className={`${styles.dialogBodyContainer} ${disableScroll && styles.disableScroll}`}>
			<div className={styles.dialogBody}>
				{children}
			</div>
		</div>
	)
})

const useStyles = createUseStyles({
	dialogBodyContainer: {
		display: 'grid',
		maxHeight: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	dialogBody: {
		display: 'grid',
		padding: 14,
		gridAutoRows: 'max-content',
		marginBottom: 24,
		gap: 22,
	},
	disableScroll: {
		overflowY: 'hidden',
	},
	[`@media screen and (min-width: ${mobileDialogMaxWidth - 1}px)`]: {
		dialogBody: {
			padding: 24,
		},
	},
})