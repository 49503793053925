import React, { FC } from 'react'
import { IconProps } from '../props'

export const SpreadsheetIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 384 512" fill={color}>
			<path id="Path_35" data-name="Path 35" d="M80,240V416a16,16,0,0,0,16,16H288a16,16,0,0,0,16-16V240a16,16,0,0,0-16-16H96A16,16,0,0,0,80,240Zm128,32h64v48H208Zm0,80h64v48H208Zm-96-80h64v48H112Zm0,80h64v48H112ZM369.83,97.98,285.94,14.1A48,48,0,0,0,252.05,0H47.99A48.158,48.158,0,0,0,0,48.09V464.01A48.008,48.008,0,0,0,47.99,512H335.93A48.07,48.07,0,0,0,384,464.01V131.97a48.3,48.3,0,0,0-14.17-33.99ZM255.95,51.99l76.09,76.08H255.95V51.99ZM336,464.01H47.99V48.09H207.96V152.07a23.934,23.934,0,0,0,24,23.99H336Z" />
		</svg>
	)
}