import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ManageTutorInformationDialog } from '../../../dialogs/manage-tutor-information-dialog/ManageTutorInformationDialog'
import { TutorManagePreferredStudentDialog } from '../../../dialogs/tutor-manage-preferred-student-dialog/TutorManagePreferredStudentDialog'
import { FirebaseContext } from '../../../firebase-provider/firebase-context'
import { ListItem } from '../../../list-item/ListItem'
import { StyledStudentsIcon } from '../../../styled-icons/styled-students-icon/StyledStudentsIcon'
import { StyledUserIcon } from '../../../styled-icons/styled-user-icon/StyledUserIcon'

type ManageTutorPreferredStudentsActionProps = {}

export const ManageTutorPreferredStudentsAction: FC<ManageTutorPreferredStudentsActionProps> = () => {

	// hooks
	const [showDialog, setShowDialog] = useState<boolean>(false)
	const { userId } = useContext(FirebaseContext)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [])

	if (!userId) {
		return null
	}

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledStudentsIcon />
				<h3>Update Preferred Student</h3>
			</ListItem>
			<TutorManagePreferredStudentDialog visible={showDialog} onClose={handleDialogClose} tutorId={userId} />
		</>
	)
}

const useStyles = createUseStyles({
	manageTutorPreferredStudentsAction: {
		display: 'grid'
	}
})