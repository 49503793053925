import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { useStudent } from '../../../common/hooks/database/use-student'
import { useStudents } from '../../../common/hooks/database/use-students'
import { useTutor } from '../../../common/hooks/database/use-tutor'
import { updateTutor } from '../../../common/logic/tutor-logic'
import { Student } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../form-row/FormRow'
import { Select } from '../../select/Select'
import { StudentSelect } from '../../students/student-select/StudentSelect'

type TutorManagePreferredStudentDialogProps = {
	tutorId: string
} & DialogProps

export const TutorManagePreferredStudentDialog: FC<TutorManagePreferredStudentDialogProps> = ({
	visible,
	onClose,
	tutorId,
}) => {

	// hooks
	const styles = useStyles()
	const { tutor } = useTutor(tutorId)
	const [value, setValue] = useState<string | undefined>(tutor?.PreferredStudentID ? tutor.PreferredStudentID : undefined)
	const students = useStudents()
	const selectedStudent = students?.find(student => student.id === value)

	// side effects
	useEffect(() => {
		if (visible) {
			setValue(tutor?.PreferredStudentID ? tutor.PreferredStudentID : undefined)
		}
	}, [visible])

	// event handlers
	const handlePreferredStudentChange = useCallback((student?: RecordWithID<Student>) => {
		setValue(student?.id)
	}, [])

	const handleClose = useCallback(() => {
		onClose && onClose()
	}, [tutor, onClose])

	const handleSubmit = useCallback(() => {
		if (tutor) {
			updateTutor(tutor.id, tutor => ({
				...tutor,
				PreferredStudentID: value ? value : null,
			})).then(() => {
				handleClose()
			})
		}
	}, [handleClose])

	if (!students) {
		return null
	}

	return (
		<Dialog visible={visible} onClose={handleClose}>
			<DialogHeader title="Update Preferred Student" onClose={handleClose} />
			<DialogBody>
				<FormRow label="Choose Preferred Student">
					<StudentSelect
						value={selectedStudent}
						items={students}
						onChange={handlePreferredStudentChange}
						placeholder="Choose a preferred student..."
						allowClear={true}
					/>
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.lightGray} onClick={handleClose}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={value === tutor?.PreferredStudentID} onClick={handleSubmit}>
					Save
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	tutorManagePreferredStudentDialog: {
		display: 'grid'
	}
})