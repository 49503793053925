import firebase from 'firebase'
import { SetUserAccountDisabled_InputData } from "../types/functions";
import { logAnalyticsEvent } from './analytics';

export const disableUserAccount = async (userId: string) => {

    logAnalyticsEvent('action', { type: 'disableUserAccount', payload: { userId } })

    const functions = firebase.functions()
    const callable = functions.httpsCallable('setUserAccountDisabled')

    const payload: SetUserAccountDisabled_InputData = {
        UserID: userId,
        disabled: true,
    }

    await callable(payload)

}

export const enableUserAcount = async (userId: string) => {

    logAnalyticsEvent('action', { type: 'enableUserAcount', payload: { userId } })

    const functions = firebase.functions()
    const callable = functions.httpsCallable('setUserAccountDisabled')

    const payload: SetUserAccountDisabled_InputData = {
        UserID: userId,
        disabled: false,
    }

    await callable(payload)

}