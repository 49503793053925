import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Registration } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { HomeSection } from '../../home-section/HomeSection'
import { RegistrationListItem } from '../registration-list-item/RegistrationListItem'
import moment from 'moment'
import { getDate, getNow } from '../../../common/logic/date-logic'

type AdminRegistrationsforWeekSectionProps = {
	week: number
	registrations: RecordWithID<Registration>[]
	compactView?: boolean
}

export const AdminRegistrationsforWeekSection: FC<AdminRegistrationsforWeekSectionProps> = ({
	week,
	registrations,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const diffWeeks: number = useMemo(() => {
		const weekMoment = getDate(week)
		return Math.abs(getNow().startOf('week').diff(weekMoment, 'weeks'))
	}, [week])
	const isThisWeek = useMemo(() => diffWeeks === 0, [diffWeeks])
	const isNextWeek = useMemo(() => diffWeeks === 1, [diffWeeks])
	const title: string = useMemo(() => {
		if (isThisWeek) {
			return "Sessions this Week"
		}
		if (isNextWeek) {
			return "Sessions next Week"
		}
		return `Sessions in ${diffWeeks} Weeks`
	}, [isThisWeek, isNextWeek, diffWeeks])
	const helpText: string = useMemo(() => {
		if (isThisWeek) {
			return "Showing sessions that have been scheduled for this week."
		}
		if (isNextWeek) {
			return "Showing sessions that have been scheduled for next week."
		}
		return `Showing sessions that have been scheduled in ${diffWeeks} weeks.`
	}, [isThisWeek, isNextWeek, diffWeeks])

	return (
		<HomeSection title={title} helpText={helpText} compactView={compactView}>
			{registrations.map(registration => (
				<RegistrationListItem
					key={registration.id}
					registration={registration}
					compactView={compactView}
				/>
			))}
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	adminRegistrationsForWeekSection: {
		display: 'grid'
	}
})