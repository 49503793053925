import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { TutorManagePreferredStudentDialog } from '../../dialogs/tutor-manage-preferred-student-dialog/TutorManagePreferredStudentDialog'
import { StudentsIcon } from '../../icons/students-icon/StudentsIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type UpdateTutorPreferredStudentOverflowMenuItemProps = {
	tutorId: string
	onClose?: () => void
}

export const UpdateTutorPreferredStudentOverflowMenuItem: FC<UpdateTutorPreferredStudentOverflowMenuItemProps> = ({
	tutorId,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setDialogOpen, onClose])

	return (
		<>
			<OverflowMenuItem icon={StudentsIcon} onClick={handleMenuItemClick}>
				Update Preferred Student
			</OverflowMenuItem>
			<TutorManagePreferredStudentDialog visible={dialogOpen} onClose={handleDialogClose} tutorId={tutorId} />
		</>
	)
}

const useStyles = createUseStyles({
	updateTutorPreferredStudentOverflowMenuItem: {
		display: 'grid'
	}
})