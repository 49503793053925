import React, { FC, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/constants/colors'
import { SearchIcon } from '../icons/search-icon/SearchIcon'

export type SearchInputProps = {} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>

export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(({
	...inputProps
}, ref) => {

	// hooks
	const styles = useStyles()

	return (
		<div ref={ref} className={styles.searchInputContainer}>
			<div className={styles.searchIcon}>
				<SearchIcon size={18} color={Colors.gray} />
			</div>
			<input {...inputProps} className={`${styles.searchInput} ${inputProps.className}`} />
		</div>
	)
})

const useStyles = createUseStyles({
	searchInputContainer: {
		position: 'relative',
		display: 'grid',
	},
	searchInput: {
		display: 'grid',
		paddingLeft: 38,
	},
	searchIcon: {
		position: 'absolute',
		left: 13,
		top: 13,
		pointerEvents: 'none',
	},
})