import React, { FC, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useFirestoreQuery } from '../../../common/hooks/firebase/firebase'
import { Registration } from '../../../common/types/types'
import moment from 'moment'
import { FirebaseQueryFiltersAndSorts } from '../../../common/hooks/firebase/firebase-types'
import { gold } from '@ant-design/colors'
import { RegistrationIcon } from '../../icons/registration-icon/RegistrationIcon'
import { StatisticsItem } from '../../statistics/statistics-item/StatisticsItem'
import { getNow } from '../../../common/logic/date-logic'

type RegistrationsThisWeekStatisticProps = {}

export const RegistrationsThisWeekStatistic: FC<RegistrationsThisWeekStatisticProps> = () => {

	// hooks
	const styles = useStyles()
	const registrationsThisWeek = useRegistrationsThisWeek()

	return (
		<StatisticsItem
			title="Registrations"
			icon={RegistrationIcon}
			color={gold.primary}
			value={registrationsThisWeek}
			description="Registrations this Week"
		/>
	)
}

const useStyles = createUseStyles({
	registrationsThisWeekStatistic: {
		display: 'grid'
	}
})

const useRegistrationsThisWeek = (): number | undefined => {

	// hooks
	const thisWeek = useMemo(() => getNow().startOf('week').unix(), [])
	const queryParams: FirebaseQueryFiltersAndSorts<Registration> = useMemo(() => ({
		filters: [['registrationWeekStartDate', '==', thisWeek]]
	}), [thisWeek])
	const [registrationIds, _a, setQueryParams] = useFirestoreQuery<Registration>('registrations', queryParams)

	// side effects
	useEffect(() => {
		setQueryParams(queryParams)
	}, [setQueryParams, queryParams])

	return registrationIds?.length

}