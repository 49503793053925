import { Switch } from 'antd'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { updateSubjectPriority } from '../../../common/logic/subject-logic'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ListItem } from '../../list-item/ListItem'
import { OverflowMenuButton } from '../../overflow-menu/subcomponents/overflow-menu-button/OverflowMenuButton'
import { StyledSubjectsIcon } from '../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'
import { DeleteSubjectOverflowMenuItem } from '../delete-subject-overflow-menu-item/DeleteSubjectOverflowMenuItem'
import { ManageTutorsinSubjectOverflowMenuItem } from '../manage-tutors-in-subject-overflow-menu-item/ManageTutorsinSubjectOverflowMenuItem'

type SubjectListItemProps = {
	subject: RecordWithID<Subject>
	compactView?: boolean
}

export const SubjectListItem: FC<SubjectListItemProps> = ({
	subject,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleOverflowMenuClose = useCallback(() => {
		setOverflowMenuOpen(false)
	}, [setOverflowMenuOpen])

	const handleSubjectPriorityChange = useCallback((checked: boolean) => {
		updateSubjectPriority(subject.id, checked ? 1 : 0)
	}, [subject.id])

	return (
		<ListItem compactView={compactView}>
			<StyledSubjectsIcon />
			<h3>{subject.name}</h3>
			<span style={{ fontSize: 12, color: Colors.gray }}>
				Pair First?
			</span>
			<Switch onChange={handleSubjectPriorityChange} checked={subject.pairingPriority > 0} />
			<OverflowMenuButton visible={overflowMenuOpen} onMenuOpenChange={setOverflowMenuOpen}>
				<ManageTutorsinSubjectOverflowMenuItem subject={subject} onClose={handleOverflowMenuClose} />
				<DeleteSubjectOverflowMenuItem subject={subject} onClose={handleOverflowMenuClose} />
			</OverflowMenuButton>
		</ListItem>
	)
}

const useStyles = createUseStyles({
	subjectListItem: {
		display: 'grid'
	}
})