import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Tutor } from '../../../../../common/types/types'
import { Country as SourceCountry, countries as sourceCountries } from 'countries-list'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
import PhoneNumber from 'awesome-phonenumber'
import { updateTutor } from '../../../../../common/logic/tutor-logic'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { useCurrentUserProfile } from '../../../../../common/hooks/database/use-current-user-profile'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { FormRow } from '../../../../form-row/FormRow'
import { Select } from '../../../../select/Select'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'
import { Colors } from '../../../../../common/constants/colors'
import { blue } from '@ant-design/colors'

export type Country = SourceCountry & { id: keyof typeof sourceCountries }

type TutorDetailsFormProps = {
	tutor: Tutor
	tutorId: string
	onClose?: () => void
}

export const TutorDetailsForm: FC<TutorDetailsFormProps> = ({
	tutor,
	tutorId,
	onClose,
}) => {

	// hooks
	const { isAdmin } = useCurrentUserProfile()
	const countries: Country[] = useMemo(() => Object.keys(sourceCountries).map((id) => {
		const countryId = id as keyof typeof sourceCountries
		return {
			...sourceCountries[countryId],
			id: countryId,
		}
	}), [])
	const { register, getValues, control, formState: { isValid }, watch } = useForm({
		mode: 'onChange',
		defaultValues: {
			displayName: tutor.displayName,
			phoneNumber: tutor.phoneNumber,
			schoolName: tutor.schoolName,
			country: countries.find(country => country.id === tutor.country) as Country,
		}
	})
	const selectedCountry: Country = watch('country')
	const [loading, setLoading] = useState<boolean>(false)

	const phoneNumberPlaceholder = useMemo(() => {
		return formatPhoneNumber(PhoneNumber.getExample(selectedCountry ? selectedCountry.id : 'US').getNumber())
	}, [selectedCountry])


	// event handlers
	const handleCancelButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSaveButtonClick = useCallback(() => {
		const formData = getValues()
		const updatePayload: Partial<Tutor> = {
			displayName: formData.displayName,
			phoneNumber: formData.phoneNumber,
			schoolName: formData.schoolName,
			country: formData.country.id,
		}
		setLoading(true)
		updateTutor(tutorId, oldTutor => {
			return {
				...oldTutor,
				...updatePayload,
			}
		}).then(() => {
			setLoading(false)
			onClose && onClose()
		})
	}, [getValues, setLoading])
	
	return (
		<>
			<DialogHeader title={isAdmin ? `Update Tutor Information` : `Update My Information`} />
			<DialogBody>
				<FormRow label={isAdmin ? 'Tutor Name' : `What's your name?`}>
					<input placeholder="Marcel Zhang" {...register('displayName', { required: true })} />
				</FormRow>
				<FormRow label={isAdmin ? `Tutor Country` : `What country do you live in?`}>
					<Controller
						name="country"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={countries}
								options={{
									nameKey: 'name',
									idKey: 'id',
									searchableFields: ['name'],
								}}
								placeholder="Choose a Country..."
							/>
						)}
					/>
				</FormRow>
				<FormRow label={isAdmin ? `Phone Number` : `What's your phone number?`}>
					<Controller
						name="phoneNumber"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<PhoneInput
								{...field}
								defaultCountry={selectedCountry?.id}
								placeholder={phoneNumberPlaceholder}
							/>
						)}
					/>
				</FormRow>
				<FormRow label={isAdmin ? 'School Name' : `What school do you attend?`}>
					<input placeholder="Shermer High School" {...register('schoolName', { required: true })} />
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleCancelButtonClick}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!isValid} onClick={handleSaveButtonClick}>
					Save
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={loading} />
		</>
	)
}

const useStyles = createUseStyles({
	tutorDetailsForm: {
		display: 'grid'
	}
})