import { FC, useEffect, useState } from "react"
import firebase from 'firebase'
import { AuthenticationState, FirebaseContext } from "./firebase-context";
import { createUseStyles } from "react-jss";
import { Spinner } from "@blueprintjs/core";

const firebaseConfig = {
    apiKey: "AIzaSyBw4MsNQ95xSke7EhkRah1oUu7GcuCcBM8",
    authDomain: "dart-tutors-app.firebaseapp.com",
    projectId: "dart-tutors-app",
    storageBucket: "dart-tutors-app.appspot.com",
    messagingSenderId: "2834698466",
    appId: "1:2834698466:web:7d8946b2724793c8528ebb",
    measurementId: "G-DLXKK5BJPG"
  };

const useEmulators = false

export const FirebaseProvider: FC = ({
    children,
}) => {

    // state
    const styles = useStyles()
    const [hasInitialized, setHasInitialized] = useState<boolean>(false)
    const [user, setUser] = useState<firebase.User | null>()
    const [userId, setUserId] = useState<string>()
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>('loading')

    // side effects
    useEffect(() => {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(firebaseConfig)
            const analytics = firebase.analytics()
            analytics.setAnalyticsCollectionEnabled(true)
            if (useEmulators) {
                firebase.auth().useEmulator('http://localhost:9099')
                firebase.functions().useEmulator('localhost', 5001)
                firebase.firestore().useEmulator('localhost', 8080)
            }
            //firebase.firestore().enablePersistence()
            const auth = firebase.auth()
            const authStateListener = auth.onAuthStateChanged(user => {
                const uid = user?.uid
                if (uid) {
                    analytics.setUserId(uid)
                }
                setUser(user)
            })
            setHasInitialized(true)
            return () => {
                authStateListener()
            }
        }
    }, [])

    useEffect(() => {
        if (user === undefined) {
            setAuthenticationState('loading')
        } else if (user === null) {
            setAuthenticationState('notAuthenticated')
        } else {
            setAuthenticationState('authenticated')
        }
        setUserId(user?.uid)
    }, [user])

    return (
        <FirebaseContext.Provider value={{
            user,
            userId,
            authenticationState,
            onUserChange: setUser,
        }}>
            {hasInitialized ? (
                children
            ) : (
                <Spinner />
            )}
        </FirebaseContext.Provider>
    )
}

const useStyles = createUseStyles({
    container: {
        width: '100vw',
        height: '100vh',
    },
})