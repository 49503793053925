import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { RegisterForASessionDialog } from '../../../dialogs/register-for-a-session-dialog/RegisterForASessionDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledRegistrationIcon } from '../../../styled-icons/styled-registration-icon/StyledRegistrationIcon'

type RegisterForASessionProps = {}

export const RegisterForASession: FC<RegisterForASessionProps> = () => {

	// hooks
	const [showRegisterDialog, setShowRegisterDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowRegisterDialog(true)
	}, [setShowRegisterDialog])

	const handleDialogClose = useCallback(() => {
		setShowRegisterDialog(false)
	}, [setShowRegisterDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledRegistrationIcon />
				<h3>Register for a Tutoring Session</h3>
			</ListItem>
			<RegisterForASessionDialog visible={showRegisterDialog} onClose={handleDialogClose} />
		</>
	)
}