import React, { FC, useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogState, RegisterForASessionDialogContext } from '../register-for-a-session-dialog/register-for-a-session-dialog-context'
import { RegisterForASession } from '../register-for-a-session-dialog/subcomponents/register-for-a-session-dialog/RegisterForASessionFormDialog'
import { Success } from '../register-for-a-session-dialog/subcomponents/success/Success'
import { ChooseStudent } from './subcomponents/choose-student/ChooseStudent'

type AdminCreateRegistrationDialogProps = {} & DialogProps

export const AdminCreateRegistrationDialog: FC<AdminCreateRegistrationDialogProps> = ({
	visible,
	onClose,
}) => {

    // hooks
    const [dialogState, setDialogState] = useState<DialogState>('chooseStudent')
    const [registrationId, setRegistrationId] = useState<string>()
    const [studentId, setStudentId] = useState<string>()

    // side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminCreateRegistrationDialog' })
		}
	}, [visible])

    // event handlers
    const handleClose = useCallback(() => {
        onClose && onClose()
        setTimeout(() => {
            setDialogState('chooseStudent')
            setRegistrationId(undefined)
        }, 410);
    }, [setDialogState, setRegistrationId, onClose])
    
    return (
        <RegisterForASessionDialogContext.Provider value={{
			studentId,
            setStudentId,
            dialogState,
            setDialogState,
            onClose: handleClose,
            registrationId,
            setRegistrationId,
        }}>
            <Dialog visible={visible} onClose={handleClose}>
				{dialogState === 'chooseStudent' && (
					<ChooseStudent />
				)}
                {dialogState === 'registrationForm' && (
                    <RegisterForASession />
                )}
                {dialogState === 'success' && (
                    <Success />
                )}
            </Dialog>
        </RegisterForASessionDialogContext.Provider>
    )
}

const useStyles = createUseStyles({
	adminCreateRegistrationDialog: {
		display: 'grid'
	}
})