import { blue } from '@ant-design/colors'
import React, { FC, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { UpdateSubjectsDialogBody } from '../../tutors/update-subjects-dialog-body/UpdateSubjectsDialogBody'

type UpdateTutorSubjectsDialogProps = {
	tutorId: string
} & DialogProps

export const UpdateTutorSubjectsDialog: FC<UpdateTutorSubjectsDialogProps> = ({
	tutorId,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'UpdateTutorSubjectsDialog' })
		}
	}, [visible])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader title="Update Subjects" onClose={onClose} />
			<UpdateSubjectsDialogBody tutorId={tutorId} />
			<DialogFooter>
				<DialogFooterButton textColor="white" color={blue.primary} onClick={onClose}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	updateTutorSubjectsDialog: {
		display: 'grid'
	}
})