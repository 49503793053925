import { useEffect, useMemo, useState } from "react"
import { createTutorAvailability } from "../../logic/tutor-availability-logic"
import { TutorAvailability } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"

export const useTutorAvailability = (tutorId: string, weekStartDate: number): TutorAvailability | undefined => {

    // hooks
    const tutorAvailabilityDocumentPath = useMemo(() => `tutorAvailabilities/${tutorId}-${weekStartDate}`, [tutorId, weekStartDate])
    const [tutorAvailability, setTutorAvailabilityDocumentPath, tutorAvailabilitySnapshot] = useFirestoreDocument<TutorAvailability>(tutorAvailabilityDocumentPath)
    const [didCreate, setDidCreate] = useState<boolean>(false)

    // side effects
    useEffect(() => {
        setTutorAvailabilityDocumentPath(tutorAvailabilityDocumentPath)
    }, [tutorAvailabilityDocumentPath])

    useEffect(() => {
        if (!didCreate && tutorAvailabilitySnapshot && !tutorAvailabilitySnapshot.exists) {
            setDidCreate(true)
            createTutorAvailability({
                TutorID: tutorId,
                registrationWeekStartDate: weekStartDate,
                availableRegistrations: 0,
            })
        }
    }, [tutorAvailabilitySnapshot, didCreate, tutorId, weekStartDate])

    return tutorAvailability

}