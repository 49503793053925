import firebase from 'firebase'
import { Registration } from "../types/types";
import { sanitizeObjectForFirebase } from '../utils/other/sanitize-object-for-firebase';
import { logAnalyticsEvent } from './analytics';

export const registerForASession = async (studentId: string, registration: Omit<Registration, 'StudentID'>): Promise<string> => {
    
    logAnalyticsEvent('action', { type: 'registerForASession', payload: { studentId, registration } })

    const db = firebase.firestore()
    const auth = firebase.auth()

    const newRegistration: Registration = {
        ...registration,
        StudentID: studentId,
    }

    const newDoc = await db.collection('registrations').add(sanitizeObjectForFirebase(newRegistration))

    return newDoc.id

}

export const updateRegistration = async (registrationId: string, payload: Partial<Registration>) => {

    const db = firebase.firestore()
    
    await db.doc(`registrations/${registrationId}`).update(sanitizeObjectForFirebase(payload))

}

export const cancelSession = async (registrationId: string) => {

    logAnalyticsEvent('action', { type: 'cancelSession', payload: { registrationId } })

    const db = firebase.firestore()
    
    await db.doc(`registrations/${registrationId}`).delete()

}