import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { AdminAvailabilitybyWeek } from '../../availability/admin-availability-by-week/AdminAvailabilitybyWeek'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'

type AdminManageAvailabilityDialogProps = {} & DialogProps

export const AdminManageAvailabilityDialog: FC<AdminManageAvailabilityDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageAvailabilityDialog' })
		}
	}, [visible])

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSearchQueryChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader onClose={onClose} title="Manage Availability" />
				<DialogBodywithSearch searchInputProps={{ onChange: handleSearchQueryChange, value: searchQuery, placeholder: 'Search Tutors' }}>
					<AdminAvailabilitybyWeek searchQuery={searchQuery} />
				</DialogBodywithSearch>
			<DialogFooter>
				<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	adminManageAvailabilityDialog: {
		display: 'grid',
		gap: 12,
		adminManageAvailabilityDialog: {
			
		},
	}
})