import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { IconProps } from '../props'

export const CaretDownIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 10.211" fill={color}>
			<path id="Path_11" data-name="Path 11" d="M12.506,192H28.084a1.209,1.209,0,0,1,.854,2.065l-7.786,7.792a1.213,1.213,0,0,1-1.713,0l-7.786-7.792A1.209,1.209,0,0,1,12.506,192Z" transform="translate(-11.295 -192)" />
		</svg>
	)
}