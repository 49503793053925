import React, { FC } from 'react'
import { IconProps } from '../props'

export const DisableUserIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 19.2" fill={color}>
			<path id="Path_27" data-name="Path 27" d="M23.8,17.179,14.638,10.1A5.385,5.385,0,1,0,6.816,4.057L1.735.127a.6.6,0,0,0-.844.1l-.735.945a.6.6,0,0,0,.1.84L22.326,19.069a.6.6,0,0,0,.844-.1l.735-.949A.594.594,0,0,0,23.8,17.179ZM7.468,12a5.041,5.041,0,0,0-5.04,5.04v.36a1.8,1.8,0,0,0,1.8,1.8H18.561L9.246,12Z" transform="translate(-0.029)" />
		</svg>
	)
}