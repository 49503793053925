import React, { FC } from 'react'
import { IconProps } from '../props'

export const CancelIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 21" fill={color}>
			<path id="Path_24" data-name="Path 24" d="M21.75,32H2.25A2.251,2.251,0,0,0,0,34.25v16.5A2.251,2.251,0,0,0,2.25,53h19.5A2.251,2.251,0,0,0,24,50.75V34.25A2.251,2.251,0,0,0,21.75,32Zm0,18.469a.282.282,0,0,1-.281.281H2.531a.282.282,0,0,1-.281-.281V34.531a.282.282,0,0,1,.281-.281H21.469a.282.282,0,0,1,.281.281ZM16.711,39.622,13.833,42.5l2.878,2.878a.56.56,0,0,1,0,.787l-1.045,1.045a.56.56,0,0,1-.787,0L12,44.333,9.122,47.211a.56.56,0,0,1-.787,0L7.289,46.166a.56.56,0,0,1,0-.787L10.167,42.5,7.289,39.622a.56.56,0,0,1,0-.787l1.045-1.045a.56.56,0,0,1,.787,0L12,40.667l2.878-2.878a.56.56,0,0,1,.787,0l1.045,1.045a.552.552,0,0,1,0,.787Z" transform="translate(0 -32)" />
		</svg>
	)
}