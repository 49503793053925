import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { useTutorsInSubject } from '../../../common/hooks/database/use-tutors-in-subject'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Button } from '../../button/Button'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { ListItem } from '../../list-item/ListItem'
import { StyledSubjectsIcon } from '../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'
import { ToggleTutorinSubjectListItem } from '../../subjects/toggle-tutor-in-subject-list-item/ToggleTutorinSubjectListItem'
import { TutorinSubjectListItem } from '../../subjects/tutor-in-subject-list-item/TutorinSubjectListItem'

type AddTutortoSubjectDialogProps = {
	subject: RecordWithID<Subject>
} & DialogProps

export const AddTutortoSubjectDialog: FC<AddTutortoSubjectDialogProps> = ({
	subject,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const tutors = useTutors()
	const tutorsInSubject = useTutorsInSubject(subject.id)
	const tutorsInSubjectIds = useMemo(() => { if (!tutorsInSubject) { return [] } else { return tutorsInSubject.map(tutor => tutor.id) } }, [tutorsInSubject])
	const filteredTutors = useMatchSortedRecords(tutors, searchQuery, ['displayName', 'emailAddress'])

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AddTutortoSubjectDialog' })
		}
	}, [visible])

	// event handlers
	const handleSearchQueryTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader title="Add Tutor(s) to Subject" onClose={onClose} />
			<DialogBodywithSearch
				searchInputProps={{
					value: searchQuery,
					onChange: handleSearchQueryTextChange,
					placeholder: 'Search Tutors'
				}}
				before={(
					<ListItem>
						<StyledSubjectsIcon />
						<h3>{subject.name}</h3>
					</ListItem>
				)}
			>
				{filteredTutors?.map(tutor => (
					<ToggleTutorinSubjectListItem tutor={tutor} subject={subject} isAdded={tutorsInSubjectIds.includes(tutor.id)} />
				))}
			</DialogBodywithSearch>
			<DialogFooter>
				<DialogFooterButton textColor="white" color={blue.primary} onClick={onClose}>
					Go Back
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	addTutorToSubjectDialog: {
		display: 'grid'
	}
})