import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSubjects } from '../../../../../common/hooks/database/use-subjects'
import { useTutorsInSubject } from '../../../../../common/hooks/database/use-tutors-in-subject'
import { RegistrationPreference, Subject, Tutor } from '../../../../../common/types/types'
import { RecordWithID } from '../../../../../common/types/utils'
import { FormRow } from '../../../../form-row/FormRow'
import { Select } from '../../../../select/Select'
import { TutorMultiSelect } from '../../../../tutors/tutor-multi-select/TutorMultiSelect'

type PreferenceFormItemProps = {
	value?: Partial<RegistrationPreference>
	onChange?: (registrationPreference: Partial<RegistrationPreference>) => void
	disabled?: boolean
	subjectLabel: string
}

export const PreferenceFormItem: FC<PreferenceFormItemProps> = ({
	value,
	onChange,
	disabled,
	subjectLabel,
}) => {

	// hooks
	const styles = useStyles()
	const subjects = useSubjects()
	const selectedSubject = subjects?.find(sub => sub.id === value?.SubjectID)
	const tutorsInSubject = useTutorsInSubject(selectedSubject?.id)
	const selectedTutors = tutorsInSubject ? value?.PreferredTutorIDs?.map(id => tutorsInSubject.find(tutor => tutor.id === id) as RecordWithID<Tutor>) : []

	// event handlers
	const handleSubjectChange = useCallback((subject?: RecordWithID<Subject>) => {
		if (subject) {
			const newRegistrationPreference: Partial<RegistrationPreference> = {
				...value,
				SubjectID: subject.id,
			}
			onChange && onChange(newRegistrationPreference)
		}
	}, [value, onChange])

	const handlePreferredTutorsChange = useCallback((tutors: RecordWithID<Tutor>[] | undefined) => {
		const newRegistrationPreference: Partial<RegistrationPreference> = {
			...value,
			PreferredTutorIDs: tutors ? [...tutors?.map(tutor => tutor.id)] : [],
		}
		onChange && onChange(newRegistrationPreference)
	}, [value, onChange])

	if (!subjects) {
		return null
	}
	
	return (
		<div className={styles.preferenceFormItem}>
			<FormRow label={subjectLabel} disabled={disabled}>
				<Select
					value={selectedSubject}
					onChange={handleSubjectChange}
					items={subjects}
					disabled={disabled}
					options={{
						nameKey: 'name',
						idKey: 'id',
						searchableFields: ['name'],
					}}
					placeholder="Choose a Subject..."
				/>
			</FormRow>
			<FormRow label="Preferred Tutor(s)" disabled={!selectedSubject || disabled}>
				<TutorMultiSelect
					value={selectedTutors}
					onChange={handlePreferredTutorsChange}
					items={tutorsInSubject ? tutorsInSubject : []}
					placeholder="Optionally choose one or more Tutors..."
					disabled={!selectedSubject || disabled}
				/>
			</FormRow>
		</div>
	)
}

const useStyles = createUseStyles({
	preferenceFormItem: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: 24,
		alignItems: 'start',
	}
})