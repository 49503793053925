import { useContext, useEffect, useMemo } from "react"
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context"
import { Admin, Student, Tutor, UserProfile } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"
import firebase from 'firebase'
import { useUserProfile } from "./use-user-profile"
import { useAdmin } from "./use-admin"
import { useTutor } from "./use-tutor"
import { useStudent } from "./use-student"

export const useCurrentUserProfile = (): { userProfile?: UserProfile, isAdmin?: boolean, isTutor?: boolean, isStudent?: boolean, admin?: Admin, tutor?: Tutor, student?: Student } => {

    // hooks
    const { userId } = useContext(FirebaseContext)
    const { userProfile } = useUserProfile(userId)
    const { admin, adminSnapshot } = useAdmin(userId)
    const { tutor, tutorSnapshot } = useTutor(userId)
    const { student, studentSnapshot } = useStudent(userId)
    const isAdmin = useMemo(() => { if (!adminSnapshot) { return undefined } else { return adminSnapshot.exists } }, [adminSnapshot])
    const isTutor = useMemo(() => { if (!tutorSnapshot) { return undefined } else { return tutorSnapshot.exists } }, [tutorSnapshot])
    const isStudent = useMemo(() => { if (!studentSnapshot) { return undefined } else { return studentSnapshot.exists } }, [studentSnapshot])

    return {
        userProfile,
        isAdmin,
        isTutor,
        isStudent,
        admin,
        tutor,
        student,
    }

}