import React, { FC } from 'react'
import { IconProps } from '../props'

export const SubjectsIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22.299 17.84" fill={color}>
			<path id="Path_1" data-name="Path 1" d="M12.822,12.265H9.477a.557.557,0,0,0-.557.557v4.46a.557.557,0,0,0,.557.557h3.345a.557.557,0,0,0,.557-.557v-4.46A.557.557,0,0,0,12.822,12.265ZM11.15,4.181a3.066,3.066,0,1,0,3.066,3.066A3.066,3.066,0,0,0,11.15,4.181Zm1.672,3.9a.279.279,0,0,1-.279.279H10.871a.279.279,0,0,1-.279-.279V5.854a.279.279,0,0,1,.279-.279h.557a.279.279,0,0,1,.279.279V7.247h.836a.279.279,0,0,1,.279.279ZM21.184,6.69H17.84V4.832a1.115,1.115,0,0,0-.5-.928L11.768.187a1.115,1.115,0,0,0-1.237,0L4.956,3.9a1.115,1.115,0,0,0-.5.928V6.69H1.115A1.115,1.115,0,0,0,0,7.8v9.477a.557.557,0,0,0,.557.557h.557a.557.557,0,0,0,.557-.557V8.362H4.46V17.84H6.132V5.13L11.15,1.785,16.167,5.13V17.84H17.84V8.362h2.787v8.92a.557.557,0,0,0,.557.557h.557a.557.557,0,0,0,.557-.557V7.8A1.115,1.115,0,0,0,21.184,6.69Z" />
		</svg>
	)
}