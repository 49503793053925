import { blue } from '@ant-design/colors'
import { MenuItem } from '@blueprintjs/core'
import { Avatar } from 'antd'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Student } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { HighlightText } from '../../highlight-text/HighlightText'
import { AdminItem, isAdminItem, Select, SelectProps } from '../../select/Select'

type StudentSelectProps = {} & Omit<SelectProps<RecordWithID<Student>>, 'options'>

export const StudentSelect: FC<StudentSelectProps> = ({
	onChange,
	value,
	...selectProps
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')

	// event handlers
	const handleActiveItemChange = useCallback((item: RecordWithID<Student> | null) => {
		onChange && onChange(item ? item : undefined)
	}, [onChange])

	const itemRenderer = useCallback((item: RecordWithID<Student> | AdminItem) => {
		if (isAdminItem(item)) {
			return (
				<MenuItem text="Clear Selection" onClick={() => handleActiveItemChange(null)} />
			)
		} else {
			const initals = item.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
			return (
				<MenuItem
					text={(
						<div className={styles.menuItemContentContainer} style={{ marginLeft: 2, }}>
							<Avatar size={22} style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
							<HighlightText text={item.displayName} query={searchQuery} />
						</div>
					)}
					onClick={() => handleActiveItemChange(item)}
					active={item.id === value?.id}
				/>
			)
		}
	}, [handleActiveItemChange, value, searchQuery])

	const valueRenderer = useCallback((student: RecordWithID<Student>) => {
		const initals = student.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
		return (
			<div className={styles.valueContainer}>
				<Avatar style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
				{student.displayName}
			</div>
		)
	}, [styles.valueContainer])

	return (
		<Select
			{...selectProps}
			itemRenderer={itemRenderer}
			valueRenderer={valueRenderer}
			onQueryChange={setSearchQuery}
			onChange={onChange}
			value={value}
			options={{
				nameKey: 'displayName',
				idKey: 'id',
				searchableFields: ['displayName'],
			}}
		/>
	)
}

const useStyles = createUseStyles({
	valueContainer: {
		display: 'grid',
		alignItems: 'center',
		gap: 8,
		gridAutoFlow: 'column',
		gridAutoColumns: 'max-content',
	},
	menuItemContentContainer: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		gap: 8,
		alignItems: 'center',
	},
})