import { FirebaseQueryData } from "../../hooks/firebase/firebase-types";
import { RecordWithID } from "../../types/utils";

export const combineIdsAndFirebaseQueryData = <RecordType extends {}>(ids?: string[], data?: FirebaseQueryData<RecordType>): RecordWithID<RecordType>[] | undefined => {
    if (!ids || !data) { return undefined }

    const records: RecordWithID<RecordType>[] = ids
        .filter(id => data[id] === undefined ? false : true)
        .map(id => ({ ...data[id], id }))

    return records
}