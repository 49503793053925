import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { generatePairingList } from '../../../../common/logic/pairing-list-logic'
import { ListItem } from '../../../list-item/ListItem'
import { LoadingOverlay } from '../../../loading-overlay/LoadingOverlay'
import { StyledSpreadhsheetIcon } from '../../../styled-icons/styled-spreadhsheet-icon/StyledSpreadhsheetIcon'
import xlsx from 'xlsx'

type AdminDownloadPairingListActionProps = {}

export const AdminDownloadPairingListAction: FC<AdminDownloadPairingListActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [loading, setLoading] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setLoading(true)
		generatePairingList().then(workbook => {
			xlsx.writeFile(workbook, 'Pairing List.xlsx')
			setLoading(false)
		})
	}, [])

	return (
		<ListItem onClick={handleListItemClick}>
			<StyledSpreadhsheetIcon />
			<h3>Download Pairing List</h3>
			<LoadingOverlay loading={loading} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	adminDownloadPairingListAction: {
		display: 'grid'
	}
})