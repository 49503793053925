import React, { FC, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { useTutor } from '../../../common/hooks/database/use-tutor'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { FirebaseContext } from '../../firebase-provider/firebase-context'
import { TutorDetailsForm } from './subcomponents/tutor-details-form/TutorDetailsForm'

type ManageTutorInformationDialogProps = {
	tutorId: string
} & DialogProps

export const ManageTutorInformationDialog: FC<ManageTutorInformationDialogProps> = ({
	tutorId,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const { tutor } = useTutor(tutorId)

	return (
		<Dialog visible={visible} onClose={onClose}>
			{tutor && (
				<TutorDetailsForm tutor={tutor} onClose={onClose} tutorId={tutorId} />
			)}
		</Dialog>
	)
}

const useStyles = createUseStyles({
	manageTutorInformationDialog: {
		display: 'grid'
	}
})