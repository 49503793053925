import { blue, red } from '@ant-design/colors'
import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useUserIsDisabled } from '../../../common/hooks/database/use-user-is-disabled'
import { DisableUserIcon } from '../../icons/disable-user-icon/DisableUserIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'
import { enableUserAcount, disableUserAccount } from "../../../common/logic/user-logic";
import { message } from 'antd'
import { EnableUserIcon } from '../../icons/enable-user-icon/EnableUserIcon'

type DisableAccountOverflowMenuItemProps = {
	userId: string
	onClose?: () => void
}

export const DisableAccountOverflowMenuItem: FC<DisableAccountOverflowMenuItemProps> = ({
	userId,
	onClose,
}) => {

	// hooks
	const userIsDisabled = useUserIsDisabled(userId)

	// event handlers
	const handleClick = useCallback(() => {
		if (userIsDisabled) {
			enableUserAcount(userId).then(() => {
				message.success('Successfully enabled user account')
				onClose && onClose()
			})
		} else {
			disableUserAccount(userId).then(() => {
				message.success('Successfully disabled user account')
				onClose && onClose()
			})
		}
	}, [userId, userIsDisabled])

	if (userIsDisabled === undefined) {
		return null
	}

	return (
		<OverflowMenuItem icon={userIsDisabled ? EnableUserIcon : DisableUserIcon} textColor={red.primary} onClick={handleClick}>
			{userIsDisabled ? "Enable Account" : "Disable Account"}
		</OverflowMenuItem>
	)
}