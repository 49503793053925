import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Authenticate } from '../authenticate/Authenticate'

export const Authenticator: FC = ({
    children
}) => {
    return (
        <Switch>
            <Route path="/sign-in">
                <Authenticate />
            </Route>
            <Route path="/log-in">
                <Authenticate />
            </Route>
            <Route path="/login">
                <Authenticate />
            </Route>
            <Route path="/signin">
                <Authenticate />
            </Route>
            <Route path="/authenticate">
                <Authenticate />
            </Route>
            <Route path="/sign-up">
                <Authenticate />
            </Route>
            {children}
        </Switch>
    )
}