import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { OverflowMenuIcon } from '../../../icons/overflow-menu-icon/OverflowMenuIcon'
import { OverflowMenu } from '../../OverflowMenu'

type OverflowMenuButtonProps = {
	visible: boolean,
	onMenuOpenChange?: (menuOpen: boolean) => void,
}

export const OverflowMenuButton: FC<OverflowMenuButtonProps> = ({
	children,
	visible,
	onMenuOpenChange,
}) => {

	// hooks
	const styles = useStyles()

	// event handlers
	const handleButtonClick = useCallback(() => {
		onMenuOpenChange && onMenuOpenChange(true)
	}, [onMenuOpenChange])

	const handleMenuClose = useCallback(() => {
		onMenuOpenChange && onMenuOpenChange(false)
	}, [onMenuOpenChange])

	return (
		<div className={styles.buttonContainer}>
			<button className={styles.overflowMenuButton} onClick={handleButtonClick}>
				<OverflowMenuIcon size={18} />
			</button>
			<OverflowMenu visible={visible} onClose={handleMenuClose}>
				{children}
			</OverflowMenu>
		</div>
	)
}

const useStyles = createUseStyles({
	overflowMenuButton: {
		border: 'none',
		backgroundColor: 'none',
		background: 'none',
		cursor: 'pointer',
		width: 36,
		height: 36,
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonContainer: {
		position: 'relative',
	},
})