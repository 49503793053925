import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { EmailIcon } from '../../icons/email-icon/EmailIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledEmailIconProps = {}

export const StyledEmailIcon: FC<StyledEmailIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon
			icon={EmailIcon}
			colors={{
				background: '#D16B3D',
				foreground: '#FFF9F7',
			}}
		/>
	)
}

const useStyles = createUseStyles({
	styledRefreshIcon: {
		display: 'grid'
	}
})