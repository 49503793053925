import { cyan } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { StudentsIcon } from '../../icons/students-icon/StudentsIcon'
import { StatisticsItem } from '../../statistics/statistics-item/StatisticsItem'

type TotalTutorsStatisticProps = {}

export const TotalTutorsStatistic: FC<TotalTutorsStatisticProps> = () => {

	// hooks
	const styles = useStyles()
	const totalTutors = useTotalTutors()

	return (
		<StatisticsItem
			title="Tutors"
			icon={StudentsIcon}
			color={cyan.primary}
			value={totalTutors}
			description="Total Tutors on Platform"
		/>
	)
}

const useStyles = createUseStyles({
	registrationsThisWeekStatistic: {
		display: 'grid'
	}
})

const useTotalTutors = (): number | undefined => {

	// hooks
	const tutors = useTutors()

	return tutors?.length

}