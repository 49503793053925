import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Background } from '../background/Background'
import { LogoutButton } from '../logout-button/LogoutButton'
import { UserIdentifier } from '../user-identifier/UserIdentifier'

type AppTopBarProps = {}

export const AppTopBar: FC<AppTopBarProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.appTopBar}>
			<Background />
			<UserIdentifier />
		</div>
	)
}

const useStyles = createUseStyles({
	appTopBar: {
		display: 'grid',
		position: 'relative',
		height: 130,
		marginBottom: 100,
	}
})