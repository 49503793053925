import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { HomeSection } from '../../../home-section/HomeSection'
import { AdminDownloadPairingListAction } from '../../home-actions/admin-download-pairing-list-action/AdminDownloadPairingListAction'
import { AdminRunAutoPair } from '../../home-actions/admin-run-auto-pair/AdminRunAutoPair'
import { AdminSendPairingEmails } from '../../home-actions/admin-send-pairing-emails/AdminSendPairingEmails'
import { ManageAdminsAction } from '../../home-actions/manage-admins-action/ManageAdminsAction'
import { ManageAvailabilityAction } from '../../home-actions/manage-availability-action/ManageAvailabilityAction'
import { ManageRegistrationsAction } from '../../home-actions/manage-registrations-action/ManageRegistrationsAction'
import { ManageStudentsAction } from '../../home-actions/manage-students-action/ManageStudentsAction'
import { ManageSubjectsAction } from '../../home-actions/manage-subjects-action/ManageSubjectsAction'
import { ManageTutorsAction } from '../../home-actions/manage-tutors-action/ManageTutorsAction'

type ManagethePlatformSectionProps = {}

export const ManagethePlatformSection: FC<ManagethePlatformSectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isAdmin } = useCurrentUserProfile()

	if (!isAdmin) {
		return null
	}

	return (
		<HomeSection title="Manage the Platform" helpText="Manage all details related to the AAH tutoring platform">
			<ManageRegistrationsAction />
			<ManageAvailabilityAction />
			<ManageStudentsAction />
			<ManageTutorsAction />
			<ManageSubjectsAction />
			<ManageAdminsAction />
			<AdminDownloadPairingListAction />
			<AdminRunAutoPair />
			<AdminSendPairingEmails />
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	manageThePlatformSection: {
		display: 'grid'
	}
})