import { gold } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { HomeSection } from '../../../home-section/HomeSection'
import { RegistrationIcon } from '../../../icons/registration-icon/RegistrationIcon'
import { RegistrationsonWaitlistStatistic } from '../../../statistics-items/registrations-on-waitlist-statistic/RegistrationsonWaitlistStatistic'
import { RegistrationsThisWeekStatistic } from '../../../statistics-items/registrations-this-week-statistic/RegistrationsThisWeekStatistic'
import { StudentsRegisteredThisWeek } from '../../../statistics-items/students-registered-this-week/StudentsRegisteredThisWeek'
import { TotalStudentsStatistic } from '../../../statistics-items/total-students-statistic/TotalStudentsStatistic'
import { TotalTutorsStatistic } from '../../../statistics-items/total-tutors-statistic/TotalTutorsStatistic'
import { TutorsAvailableThisWeekStatistic } from '../../../statistics-items/tutors-available-this-week-statistic/TutorsAvailableThisWeekStatistic'
import { StatisticsItem } from '../../../statistics/statistics-item/StatisticsItem'

type StatisticsSectionProps = {}

export const StatisticsSection: FC<StatisticsSectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isAdmin } = useCurrentUserProfile()

	if (!isAdmin) {
		return null
	}

	return (
		<HomeSection title="Statistics" helpText="Showing statistics from across the platform" contentClassName={styles.statisticsSection}>
			<RegistrationsThisWeekStatistic />
			<RegistrationsonWaitlistStatistic />
			{/* <StudentsRegisteredThisWeek /> */}
			<TotalStudentsStatistic />
			<TotalTutorsStatistic />
			<TutorsAvailableThisWeekStatistic />
			{/* <MostPopularSubjectStatistic /> */}
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	statisticsSection: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridAutoRows: 'max-content',
		gap: 12,
	}
})