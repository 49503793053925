import { red } from '@ant-design/colors'
import { message } from 'antd'
import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { removeTutorFromSubject } from '../../../common/logic/subject-logic'
import { Subject, Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { RemoveUserIcon } from '../../icons/remove-user-icon/RemoveUserIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type RemoveTutorFromSubjectOverflowMenuItemProps = {
	tutor: RecordWithID<Tutor>
	subject: RecordWithID<Subject>
	onClose?: () => void
}

export const RemoveTutorFromSubjectOverflowMenuItem: FC<RemoveTutorFromSubjectOverflowMenuItemProps> = ({
	tutor,
	subject,
	onClose,
}) => {

	// hooks
	const styles = useStyles()

	// event handlers
	const handleItemClick = useCallback(() => {
		removeTutorFromSubject(tutor.id, subject.id).then(() => {
			onClose && onClose()
			message.success('Successfully removed tutor from subject')
		})
	}, [tutor.id, onClose])

	return (
		<OverflowMenuItem icon={RemoveUserIcon} textColor={red.primary} onClick={handleItemClick}>
			Remove Tutor from Subject
		</OverflowMenuItem>
	)
}

const useStyles = createUseStyles({
	removeTutorFromSubjectOverflowMenuItem: {
		display: 'grid'
	}
})