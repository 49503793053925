import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageRegistrationsDialog } from '../../../dialogs/admin-manage-registrations-dialog/AdminManageRegistrationsDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledRegistrationIcon } from '../../../styled-icons/styled-registration-icon/StyledRegistrationIcon'

type ManageRegistrationsActionProps = {}

export const ManageRegistrationsAction: FC<ManageRegistrationsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [setShowDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledRegistrationIcon />
				<h3>Manage Registrations</h3>
			</ListItem>
			<AdminManageRegistrationsDialog visible={showDialog} onClose={handleDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	manageRegistrationsAction: {
		display: 'grid'
	}
})