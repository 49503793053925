import { ChangeEvent, Dispatch, SetStateAction, KeyboardEvent } from "react";

export const createHandleInputTextChange = (setState: (event: string) => any) => {
    return (event: ChangeEvent<HTMLInputElement>) => setState(event.target.value)   
}

export const createSubmitOnEnterKeydown = (submit: () => void) => {
    return (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submit()
        }
    }
}