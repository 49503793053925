import { blue } from '@ant-design/colors'
import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { useUpcomingWeeks } from '../../../../common/hooks/other/use-upcoming-weeks'
import { HomeSection } from '../../../home-section/HomeSection'
import { InfoIcon } from '../../../icons/info-icon/InfoIcon'
import { InfoCallout } from '../../../info-callout/InfoCallout'
import { TutorAvailabilityAction } from '../../home-actions/tutor-availability-action/TutorAvailabilityAction'

type TutorAvailabilitySectionProps = {}

export const TutorAvailabilitySection: FC<TutorAvailabilitySectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isTutor } = useCurrentUserProfile()
	const registrationWeeks = useUpcomingWeeks(useCallback(() => '', []))

	if (!isTutor) {
		return null
	}

	return (
		<HomeSection title="My Availability" helpText="Manage your availability for tutoring.">
			<InfoCallout
				icon={InfoIcon}
				title="Please sign up by 3:30pm on Sunday."
				color={blue.primary}
			>
				Please sign up by 3:30pm on Sunday. The pairing list will be sent out at 4:00pm.
				<br />
				<br />
				If you’re not assigned a student, we will manually pair you up and post on our WhatsApp groups before 5PM.
			</InfoCallout>
			{registrationWeeks.map(registrationWeek => (
				<TutorAvailabilityAction key={registrationWeek.startDate} weekStartDate={registrationWeek.startDate} />
			))}
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	tutorAvailabilitySection: {
		display: 'grid'
	}
})