import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { AppRouter } from './components/app-router/AppRouter';
import { AppShell } from './components/app-shell/AppShell';
import { AuthenticatedRoute } from './components/authentication/authenticated-route/AuthenticatedRoute';
import { Authenticator } from './components/authentication/root-router-switch/Authenticator';
import { DialogsProvider } from './components/dialogs-provider/DialogsProvider';
import { CompleteProfileDialog } from './components/dialogs/complete-profile-dialog/CompleteProfileDialog';
import { FirebaseProvider } from './components/firebase-provider/FirebaseProvider';

function App() {
  return (
    <FirebaseProvider>
      <BrowserRouter>
        <DialogsProvider>
          <Authenticator>
            <AuthenticatedRoute path="*">
              <CompleteProfileDialog />
              <AppShell>
                <AppRouter />
              </AppShell>
            </AuthenticatedRoute>
          </Authenticator>
        </DialogsProvider>
      </BrowserRouter>
    </FirebaseProvider>
  );
}

export default App;
