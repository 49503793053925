import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { TutorListItem } from '../../tutors/tutor-list-item/TutorListItem'

type AdminManageTutorsDialogProps = {} & DialogProps

export const AdminManageTutorsDialog: FC<AdminManageTutorsDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const tutors = useTutors()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredTutors = useMatchSortedRecords(tutors, searchQuery, ['displayName'])

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageTutorsDialog' })
		}
	}, [visible])

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSearchTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader onClose={onClose} title="Manage Tutors" />
			<DialogBodywithSearch searchInputProps={{ onChange: handleSearchTextChange, value: searchQuery, placeholder: 'Search Tutors' }} compactView={true}>
				{filteredTutors?.map(tutor => (
					<TutorListItem key={tutor.id} tutor={tutor} compactView={true} />
				))}
			</DialogBodywithSearch>
			<DialogFooter>
			<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	adminManageTutorsDialog: {
		display: 'grid'
	}
})