import { TutorAvailability } from "../types/types";
import firebase from 'firebase'
import { sanitizeObjectForFirebase } from "../utils/other/sanitize-object-for-firebase";
import { logAnalyticsEvent } from "./analytics";

export const createTutorAvailability = async (availability: TutorAvailability): Promise<string> => {

    logAnalyticsEvent('action', { type: 'createTutorAvailability', payload: { availability } })

    const db = firebase.firestore()

    const id = `${availability.TutorID}-${availability.registrationWeekStartDate}`

    await db.doc(`tutorAvailabilities/${id}`).set(
        sanitizeObjectForFirebase(availability)
    )

    return id

}

export const updateTutorAvailability = async (tutorId: string, weekStartDate: number, availableRegistrations: number) => {

    logAnalyticsEvent('action', { type: 'updateTutorAvailability', payload: { tutorId, weekStartDate, availableRegistrations } })

    const db = firebase.firestore()

    const id = `${tutorId}-${weekStartDate}`
    
    await db.runTransaction(async transaction => {
        const ref = db.doc(`tutorAvailabilities/${id}`)
        const snapshot = await transaction.get(ref)
        const data = snapshot.data() as TutorAvailability
        transaction.update(ref, {
            ...data,
            availableRegistrations,
        })
    })

}