import { createContext, Dispatch, SetStateAction } from "react";

export type DialogState = 'chooseUserType' | 'provideStudentDetails' | 'provideTutorDetails' | 'chooseTutorSubjects' | 'success'

type CompleteProfileDialogContext = {
    dialogState: DialogState
    setDialogState: Dispatch<SetStateAction<DialogState>>
    onClose: () => void
}

export const CompleteProfileDialogContext = createContext<CompleteProfileDialogContext>({
    dialogState: 'chooseUserType',
    setDialogState: () => {},
    onClose: () => {},
})