import { blue } from '@ant-design/colors'
import React, { ComponentType, FC } from 'react'
import { createUseStyles } from 'react-jss'
import { IconProps } from '../icons/props'

type InfoCalloutProps = {
	title: string
	icon: ComponentType<IconProps>
	color?: string
	textColor?: string
}

export const InfoCallout: FC<InfoCalloutProps> = ({
	title,
	icon: Icon,
	color = blue.primary,
	textColor = 'white',
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.infoCallout} style={{ border: `1px solid ${color}` }}>
			<div className={styles.calloutTitleRow} style={{ color: textColor, backgroundColor: color }}>
				<Icon size={24} color={textColor} />
				<span>
					{title}
				</span>
			</div>
			<div className={styles.calloutBody}>
				{children}
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	infoCallout: {
		display: 'grid',
		gridAutoRows: 'max-content',
		fontSize: 12,
		borderRadius: 8,
	},
	calloutTitleRow: {
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		paddingLeft: 14,
		paddingRight: 14,
		paddingTop: 10,
		paddingBottom: 10,
		fontSize: 14,
		alignItems: 'center',
		fontWeight: 500,
		gap: 10,
	},
	calloutBody: {
		paddingTop: 16,
		paddingLeft: 14,
		paddingRight: 14,
		paddingBottom: 20,
	}
})