import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { useUpcomingWeeks } from '../../../common/hooks/other/use-upcoming-weeks'
import moment from 'moment'
import { HomeSection } from '../../home-section/HomeSection'
import { TutorAvailabilityListItem } from '../tutor-availability-list-item/TutorAvailabilityListItem'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { getDate, getNow } from '../../../common/logic/date-logic'

type AdminAvailabilitybyWeekProps = {
	searchQuery: string
}

export const AdminAvailabilitybyWeek: FC<AdminAvailabilitybyWeekProps> = ({
	searchQuery,
}) => {

	// hooks
	const styles = useStyles()
	const tutors = useTutors()
	const registrationWeeks = useUpcomingWeeks(generateLabels)
	const filteredTutors = useMatchSortedRecords(tutors, searchQuery, ['displayName'])

	return (
		<>
			{registrationWeeks.map(registrationWeek => (
				<HomeSection key={registrationWeek.startDate} title={registrationWeek.label} helpText={`Showing availability for the week specified`}>
					{filteredTutors?.map(tutor => (
						<TutorAvailabilityListItem key={tutor.id} tutor={tutor} registrationWeek={registrationWeek.startDate} />
					))}
				</HomeSection>
			))}
		</>
	)
}

const useStyles = createUseStyles({
	adminAvailabilityByWeek: {
		display: 'grid'
	}
})

const generateLabels = (date: moment.Moment): string => {

	const isThisWeek = date.isSame(getNow().startOf('week'))
	const sundayText = getDate(date).add(1, 'week').format('dddd MMM, D')
	if (isThisWeek) {
		return `Availability this Week (${sundayText})`
	}

	const isNextWeek = date.isSame(getNow().startOf('week').add(1, 'week'))
	if (isNextWeek) {
		return `Availability next Week (${sundayText})`
	}

	const difference = Math.abs(date.diff(getNow().startOf('week'), 'weeks'))

	return `Availability ${difference} Weeks from Now (${sundayText})`

}