import { blue, green } from '@ant-design/colors'
import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { makeUserAdmin, makeUserNotAdmin } from '../../../common/logic/admin-logic'

type UserIsAdminToggleProps = {
	userId: string
	isAdmin: boolean
}

export const UserIsAdminToggle: FC<UserIsAdminToggleProps> = ({
	userId,
	isAdmin,
}) => {

	// hooks
	const styles = useStyles()

	// event handlers
	const handleClick = useCallback(() => {
		if (!isAdmin) {
			makeUserAdmin(userId)
		} else {
			makeUserNotAdmin(userId)
		}
	}, [isAdmin, userId])

	return (
		<div className={`${styles.userIsAdminToggle} ${isAdmin && styles.added}`} onClick={handleClick}>
			{isAdmin ? 'Added' : 'Add'}
		</div>
	)
}

const useStyles = createUseStyles({
	userIsAdminToggle: {
		display: 'grid',
		border: `1px solid ${blue.primary}`,
		color: blue.primary,
		fontWeight: 500,
		gridTemplateColumns: '80px',
		justifyContent: 'center',
		textAlign: 'center',
		padding: 8,
		borderRadius: 6,
		userSelect: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: blue[0],
		},
		'&:active': {
			backgroundColor: blue[1],
		},
	},
	added: {
		borderColor: green.primary,
		color: green.primary,
		'&:hover': {
			backgroundColor: green[0],
		},
		'&:active': {
			backgroundColor: green[1],
		},
	},
})