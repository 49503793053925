import React, { FC, useCallback, useState } from 'react'
import { UpdateStudentInformationDialog } from '../../dialogs/update-student-information-dialog/UpdateStudentInformationDialog'
import { UserIcon } from '../../icons/user-icon/UserIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type UpdateStudentInformationOverflowMenuItemProps = {
	studentId: string
	onClose?: () => void
}

export const UpdateStudentInformationOverflowMenuItem: FC<UpdateStudentInformationOverflowMenuItemProps> = ({
	studentId,
	onClose,
}) => {

	// hooks
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setDialogOpen, onClose])

	return (
		<>
			<OverflowMenuItem icon={UserIcon} onClick={handleMenuItemClick}>
				Update Student Information
			</OverflowMenuItem>
			<UpdateStudentInformationDialog visible={dialogOpen} onClose={handleDialogClose} studentId={studentId} />
		</>
	)
}