import React, { FC } from 'react'
import { IconProps } from '../props'

export const LinkIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 512 512.001" fill={color}>
			<path id="Path_172" data-name="Path 172" d="M314.222,197.78c51.091,51.091,54.377,132.287,9.75,187.16-6.242,7.73-2.784,3.865-84.94,86.02A140,140,0,0,1,41.042,272.97c32.773-32.773,51.835-51.9,63.409-63.457,7.463-7.452,20.331-2.354,20.486,8.192a173.309,173.309,0,0,0,4.746,37.828,11.928,11.928,0,0,1-3.2,11.2L80.632,312.57a84,84,0,0,0,118.8,118.8l75.19-75.2a84.022,84.022,0,0,0-22.814-135.019,12.026,12.026,0,0,1-6.561-12.346,51.718,51.718,0,0,1,14.815-30.4l4.375-4.375a11.931,11.931,0,0,1,13.76-2.294,139.209,139.209,0,0,1,36.025,26.049ZM470.958,41.04a139.915,139.915,0,0,0-197.99,0c-82.156,82.156-78.7,78.29-84.94,86.02-44.627,54.873-41.341,136.069,9.75,187.16A139.209,139.209,0,0,0,233.8,340.269a11.934,11.934,0,0,0,13.76-2.294l4.375-4.375a51.71,51.71,0,0,0,14.815-30.4,12.026,12.026,0,0,0-6.561-12.346A84.022,84.022,0,0,1,237.378,155.83l75.19-75.2a84,84,0,1,1,118.8,118.8L385.52,245.27a11.928,11.928,0,0,0-3.2,11.2,173.314,173.314,0,0,1,4.746,37.828c.155,10.546,13.023,15.644,20.486,8.192,11.574-11.558,30.636-30.684,63.409-63.457a139.913,139.913,0,0,0,0-197.991Z" transform="translate(0 0)" />
		</svg>
	)
}