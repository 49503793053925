import { useContext, useEffect, useMemo } from "react"
import { Student } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"
import firebase from 'firebase'
import { RecordWithID } from "../../types/utils"

export const useStudent = (studentId?: string): { student?: RecordWithID<Student>, studentSnapshot?: firebase.firestore.DocumentSnapshot<Student> } => {

    // hooks
    const [student, setStudentDocumentPath, studentSnapshot] = useFirestoreDocument<Student>(studentId && `students/${studentId}`)
    const studentWithId = useMemo(() => {
        if (!studentId || !student) { return undefined }
        return { ...student, id: studentId }
    }, [studentId, student])

    // side effects
    useEffect(() => {
        setStudentDocumentPath(studentId && `students/${studentId}`)
    }, [setStudentDocumentPath, studentId])

    return {
        student: studentWithId,
        studentSnapshot,
    }

}