import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ItemRenderer, MultiSelect as BlueprintMultiSelect } from "@blueprintjs/select";
import { MenuItem } from '@blueprintjs/core';
import { isEqual } from 'lodash';
import { CheckIcon } from '../icons/check-icon/CheckIcon';
import { HighlightText } from '../highlight-text/HighlightText';
import { useMatchSortedRecords } from '../../common/hooks/other/use-match-sorted-records';
import { RecordWithID } from '../../common/types/utils';

export type MultiSelectProps<T extends RecordWithID<{}>> = {
	items: T[]
	value?: T[]
	onChange?: (item?: T[]) => any
	onBlur?: () => any
	itemRenderer?: ItemRenderer<T>
	tagRenderer?: (item: T) => React.ReactNode
	onQueryChange?: (query: string) => void
	options: {
		nameKey: keyof T
		idKey: keyof T
		searchableFields?: string[]
	}
	placeholder?: string
	disabled?: boolean
	noResultsText?: string
}

export const MultiSelect = <T extends RecordWithID<{}>>({
	items,
	value,
	onChange,
	onBlur,
	itemRenderer,
	tagRenderer,
	onQueryChange,
	options,
	placeholder,
	disabled,
	noResultsText,
}: MultiSelectProps<T>) => {

	const { nameKey, idKey, searchableFields } = options
	const LocalizedMultiSelect = useMemo(() => BlueprintMultiSelect.ofType<T>(), [])

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredItems = useMatchSortedRecords(items, searchQuery, searchableFields as (keyof T)[])
	const [activeItem, setActiveItem] = useState<T>()

	// event handlers
	const handleActiveItemChange = useCallback((item: T | null) => {
		if (item !== null) {
			let newValues: T[] = []
			if (value) {
				newValues.push(...value)
			}
			if (newValues.indexOf(item as any) > -1) {
				newValues = newValues.filter(val => !isEqual(val, item))
			} else {
				newValues.push(item)
			}
			onChange && onChange(newValues)
		}
	}, [onChange, value])

	const renderItem = useCallback((item?: T) => {
		if (item) {
			const isSelected = value && value.find(val => val[idKey] === item[idKey]) ? true : false
			const name = item[nameKey] as any as string
			return (
				<MenuItem
					text={<HighlightText text={name} query={searchQuery} />}
					onClick={() => handleActiveItemChange(item)}
					icon={isSelected ? 'tick' : 'blank'}
				/>
			)
		} else {
			return null
		}
	}, [value, nameKey, idKey, handleActiveItemChange, searchQuery])

	const renderTag = useCallback((item?: T) => {
		if (item) {
			return item[nameKey]
		} else {
			return null
		}
	}, [nameKey])

	const handleQueryChange = useCallback((query: string) => {
		onQueryChange && onQueryChange(query)
		setSearchQuery(query)
	}, [onQueryChange, onQueryChange])

	if (!filteredItems) {
		return null
	}

	return (
		<LocalizedMultiSelect
			className={styles.localizedSelect}
			items={filteredItems}
			activeItem={activeItem}
			onItemSelect={setActiveItem}
			itemRenderer={itemRenderer ? itemRenderer : renderItem}
			query={searchQuery}
			onQueryChange={handleQueryChange}
			tagRenderer={tagRenderer ? tagRenderer : renderTag}
			placeholder={placeholder}
			selectedItems={value}
			onRemove={handleActiveItemChange}
			noResults={<MenuItem disabled={true} text={noResultsText ? noResultsText : "No Results..."} />}
			tagInputProps={{
				inputProps: {
					//onBlur,
				},
				tagProps: {
					minimal: true,
					large: true,
				},
			}}
		/>
	)
}

const useStyles = createUseStyles({
	localizedSelect: {
		display: 'grid',
		'& .bp3-input': {
			border: `1px solid #969696`,
			borderRadius: 8,
			padding: '8px 18px',
			boxSizing: 'border-box',
			outline: 'none !important',
			boxShadow: 'none',
			fontSize: 14,
			minHeight: 62,
		},
	},
	select: {
		display: 'grid',
		padding: '14px 18px',
		border: '1px solid #969696',
		borderRadius: 8,
		cursor: 'pointer',
		position: 'relative',
		minHeight: 46,
		'&:hover': {
			backgroundColor: '#F5F5F5',
		},
		'&:focus': {
			backgroundColor: '#F5F5F5',
		},
	},
	selectCaret: {
		position: 'absolute',
		top: 0,
		height: '100%',
		display: 'grid',
		alignItems: 'center',
		right: 18,
	},
	placeholder: {
		color: 'rgb(119, 119, 119)',
	},
	dropdownContainer: {
		position: 'absolute',
		left: 12,
		top: 44,
		width: '100%',
		backgroundColor:'white',
		padding: 12,
		boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
		cursor: 'unset',
		//border: `1px solid ${Colors.border}`,
		borderRadius: 8,
	},
	disabled: {
		cursor: 'default',
	},
})