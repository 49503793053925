import { gold, grey, red } from '@ant-design/colors'
import { Tag } from '@blueprintjs/core'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { useStudent } from '../../../common/hooks/database/use-student'
import { useSubject } from '../../../common/hooks/database/use-subject'
import { useTutor } from '../../../common/hooks/database/use-tutor'
import { Registration } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { LabeledUser } from '../../labeled-user/LabeledUser'
import { ListItemCard } from '../../list-item-card/ListItemCard'
import { ListItem } from '../../list-item/ListItem'
import { LoadingOverlay } from '../../loading-overlay/LoadingOverlay'
import { OverflowMenuButton } from '../../overflow-menu/subcomponents/overflow-menu-button/OverflowMenuButton'
import { StyledRegistrationIcon } from '../../styled-icons/styled-registration-icon/StyledRegistrationIcon'
import { WithLabel } from '../../with-label/WithLabel'
import { RegistrationListItemMenu } from '../registration-list-item-menu/RegistrationListItemMenu'

type RegistrationListItemProps = {
	registration: RecordWithID<Registration>
	compactView?: boolean
}

export const RegistrationListItem: FC<RegistrationListItemProps> = ({
	registration,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const { isAdmin, isStudent, isTutor } = useCurrentUserProfile()
	const showStudent = useMemo(() => isAdmin || isTutor, [isAdmin, isTutor])
	const showTutor = useMemo(() => isAdmin || isStudent, [isStudent])
	const isWaitlist = useMemo(() => !registration.TutorID, [registration])
	const subject = useSubject(registration.SubjectID)
	const { tutor } = useTutor(showTutor ? registration.TutorID : undefined)
	const { student } = useStudent(showStudent ? registration.StudentID : undefined)
	const loading = useMemo(() => {
		if (showStudent && !student) { return true }
		if (showTutor && !isWaitlist && !tutor) { return true }
		return false
	}, [showStudent, showTutor, subject, tutor, student, isWaitlist])
	const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleOverflowMenuClose = useCallback(() => {
		setOverflowMenuOpen(false)
	}, [setOverflowMenuOpen])

	return (
		<ListItemCard title="Registration" styledIcon={<StyledRegistrationIcon />} compactView={compactView} actions={(
			<OverflowMenuButton visible={overflowMenuOpen} onMenuOpenChange={setOverflowMenuOpen}>
				<RegistrationListItemMenu registration={registration} onClose={handleOverflowMenuClose} />
			</OverflowMenuButton>
		)}>
			{subject && (
				<WithLabel label="Subject">
					{subject.name}
				</WithLabel>
			)}
			<div className={styles.participants}>
				{showStudent && (
					<WithLabel label="Student">
						{student && (
							<LabeledUser user={student} />
						)}
					</WithLabel>
				)}
				{showTutor && (
					<WithLabel label="Tutor">
						{tutor && !isWaitlist && (
							<LabeledUser user={tutor} />
						)}
						{isWaitlist && (
							<div className={styles.waitlistTag}>
								Waiting for Tutor
							</div>
						)}
					</WithLabel>
				)}
			</div>
			{showStudent && (
					<WithLabel label="Student Email">
						{student && (
							student.emailAddress
						)}
					</WithLabel>
				)}
			{showStudent && (
					<WithLabel label="Student Grade">
						{student && (
							student.grade
						)}
					</WithLabel>
				)}
				{showTutor && tutor && (
					<WithLabel label="Tutor Email">
						{tutor && !isWaitlist && (
							tutor.emailAddress
						)}
					</WithLabel>
				)}
				{showStudent && (
					<WithLabel label="Student Phone">
						{student && (
							student.phoneNumber
						)}
					</WithLabel>
				)}
				{showTutor && tutor && (
					<WithLabel label="Tutor Phone">
						{tutor && !isWaitlist && (
							tutor.phoneNumber
						)}
					</WithLabel>
				)}
			<LoadingOverlay loading={loading} />
		</ListItemCard>
	)
}

const useStyles = createUseStyles({
	participants: {
		display: 'grid',
		gridAutoFlow: 'column',
		maxWidth: '100%',
		gridTemplateColumns: 'repeat(2, 50%)',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	waitlistTag: {
		backgroundColor: grey.primary,
		color: 'white',
		paddingLeft: 14,
		paddingRight: 14,
		paddingTop: 6,
		paddingBottom: 6,
		borderRadius: 4,
	},
})