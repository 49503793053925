import { useEffect, useMemo } from "react";
import { Tutor } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { useFirestoreQuery } from "../firebase/firebase";
import { FirebaseQueryFiltersAndSorts } from "../firebase/firebase-types";

export const useTutorsInSubject = (subjectId?: string): RecordWithID<Tutor>[] | undefined => {

    // hooks
    const tutorsInSubjectQueryParams: FirebaseQueryFiltersAndSorts<Tutor> | undefined = useMemo(() => {
        if (!subjectId) { return undefined }
        return { filters: [['SubjectIDs', 'array-contains', subjectId]] }
    }, [subjectId])
    const [tutorIds, tutorsData, setTutorsQueryParams] = useFirestoreQuery<Tutor>('tutors', tutorsInSubjectQueryParams)
    const tutors: RecordWithID<Tutor>[] | undefined = useMemo(() => combineIdsAndFirebaseQueryData(tutorIds, tutorsData), [tutorIds, tutorsData])

    // side effects
    useEffect(() => {
        setTutorsQueryParams(tutorsInSubjectQueryParams)
    }, [tutorsInSubjectQueryParams])

    return tutors

}