import React, { FC } from 'react'
import { IconProps } from '../props'

export const UnlinkIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 512.001 512" fill={color}>
			<path id="Path_172" data-name="Path 172" d="M304.083,388.936a12,12,0,0,1,0,16.971l-65.057,65.056A140,140,0,0,1,41.037,272.974l65.056-65.057a12,12,0,0,1,16.971,0l22.627,22.627a12,12,0,0,1,0,16.971L81.386,311.82c-34.341,34.341-33.451,88.269.6,120.866a84,84,0,0,0,117.445-1.32l65.057-65.056a12,12,0,0,1,16.971,0l22.627,22.626ZM247.515,145.691l64.3-64.3C346.165,47.041,400.105,47.934,432.7,82a84,84,0,0,1-1.335,117.43L366.31,264.486a12,12,0,0,0,0,16.971l22.627,22.627a12,12,0,0,0,16.971,0l65.056-65.057A140,140,0,1,0,272.975,41.037l-65.057,65.057a12,12,0,0,0,0,16.971l22.627,22.627a12,12,0,0,0,16.97,0ZM485.858,508.485l22.627-22.627a12,12,0,0,0,0-16.971L43.112,3.515a12,12,0,0,0-16.971,0L3.515,26.142a12,12,0,0,0,0,16.971L468.888,508.486a12,12,0,0,0,16.97,0Z" transform="translate(0 0)" />
		</svg>
	)
}