import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ManageTutorInformationDialog } from '../../dialogs/manage-tutor-information-dialog/ManageTutorInformationDialog'
import { UserIcon } from '../../icons/user-icon/UserIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type UpdateTutorInformationListItemProps = {
	tutorId: string
	onClose?: () => void
}

export const UpdateTutorInformationListItem: FC<UpdateTutorInformationListItemProps> = ({
	tutorId,
	onClose,
}) => {

	// hooks
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setDialogOpen, onClose])

	return (
		<>
			<OverflowMenuItem icon={UserIcon} onClick={handleMenuItemClick}>
				Update Information
			</OverflowMenuItem>
			<ManageTutorInformationDialog visible={dialogOpen} onClose={handleDialogClose} tutorId={tutorId} />
		</>
	)
}

const useStyles = createUseStyles({
	updateTutorInformationListItem: {
		display: 'grid'
	}
})