import { useMemo } from "react";
import { Student, Tutor } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { useFirestoreQuery } from "../firebase/firebase";

export const useStudents = (): RecordWithID<Student>[] | undefined => {

    // hooks
    const [studentIds, studentsData] = useFirestoreQuery<Student>('students', { filters: [], sorts: [['displayName', 'asc']] })
    const students = useMemo(() => combineIdsAndFirebaseQueryData(studentIds, studentsData), [studentIds, studentsData])

    return students

}