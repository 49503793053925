import { show } from '@blueprintjs/core/lib/esm/components/context-menu/contextMenu'
import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { UpdateStudentInformationDialog } from '../../../dialogs/update-student-information-dialog/UpdateStudentInformationDialog'
import { FirebaseContext } from '../../../firebase-provider/firebase-context'
import { ListItem } from '../../../list-item/ListItem'
import { StyledStudentsIcon } from '../../../styled-icons/styled-students-icon/StyledStudentsIcon'
import { StyledUserIcon } from '../../../styled-icons/styled-user-icon/StyledUserIcon'

type ManageStudentInformationActionProps = {}

export const ManageStudentInformationAction: FC<ManageStudentInformationActionProps> = () => {

	// hooks
	const [showDialog, setShowDialog] = useState<boolean>(false)
	const { userId } = useContext(FirebaseContext)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [])

	if (!userId) {
		return null
	}

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledUserIcon />
				<h3>Update My Information</h3>
			</ListItem>
			<UpdateStudentInformationDialog visible={showDialog} onClose={handleDialogClose} studentId={userId} />
		</>
	)
}