import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { getNow } from '../../../../common/logic/date-logic'
import { sendPairingEmails } from '../../../../common/logic/emails-logic'
import { ListItem } from '../../../list-item/ListItem'
import { LoadingOverlay } from '../../../loading-overlay/LoadingOverlay'
import { StyledEmailIcon } from '../../../styled-icons/styled-email-icon/StyledEmailIcon'

type AdminSendPairingEmailsProps = {}

export const AdminSendPairingEmails: FC<AdminSendPairingEmailsProps> = () => {

	// hooks
	const styles = useStyles()
	const [loading, setLoading] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setLoading(true)
		const thisWeek = getNow().startOf('week').unix()
		sendPairingEmails(thisWeek).then(() => {
			setLoading(false)
		})
	}, [])

	return (
		<ListItem onClick={handleListItemClick}>
			<StyledEmailIcon />
			<h3>Send Pairing Emails</h3>
			<LoadingOverlay loading={loading} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	adminRunAutoPair: {
		display: 'grid'
	}
})