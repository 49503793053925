import { createContext } from "react"

export type DialogState = 'chooseStudent' | 'registrationForm' | 'success'

type RegisterForASessionDialogContext = {
    studentId?: string
    setStudentId: (studentId?: string) => void
    dialogState: DialogState
    setDialogState: (state: DialogState) => void
    registrationId?: string
    setRegistrationId: (registrationId: string) => void
    onClose?: () => void
}

export const RegisterForASessionDialogContext = createContext<RegisterForASessionDialogContext>({
    dialogState: 'registrationForm',
    setStudentId: () => {},
    setDialogState: () => {},
    setRegistrationId: () => {},
})