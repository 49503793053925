import React, { FC, useContext, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { ListItem } from '../../../list-item/ListItem'
import { StyledAvailabilityIcon } from '../../../styled-icons/styled-availability-icon/StyledAvailabilityIcon'
import moment from 'moment'
import { AvailabilityToggle } from '../../../availability/availability-toggle/AvailabilityToggle'
import { FirebaseContext } from '../../../firebase-provider/firebase-context'
import { getDate, getNow } from '../../../../common/logic/date-logic'

const dateFormat = 'MMM DD'

type TutorAvailabilityActionProps = {
	weekStartDate: number
}

export const TutorAvailabilityAction: FC<TutorAvailabilityActionProps> = ({
	weekStartDate,
}) => {

	// hooks
	const styles = useStyles()
	const { userId } = useContext(FirebaseContext)
	const weekMoment = useMemo(() => getDate(weekStartDate), [weekStartDate])
	const thisWeekMoment = useMemo(() => getNow().startOf('week'), [])
	const weekDateText = useMemo(() => moment(weekMoment).add(1, 'week').format(dateFormat), [weekMoment])
	const weekCalendarText = useMemo(() => {
		const weeksDiff = Math.abs(weekMoment.diff(thisWeekMoment, 'weeks'))
		if (weeksDiff === 0) { return 'This Week' }
		if (weeksDiff === 1) { return 'Next Week' }
		return `${weeksDiff} Weeks from Now`
	}, [weekMoment, thisWeekMoment])

	if (!userId) {
		return null
	}

	return (
		<ListItem>
			<StyledAvailabilityIcon />
			<div className={styles.detailsColumn}>
				<h3>{weekDateText}</h3>
				<span>{weekCalendarText}</span>
			</div>
			<AvailabilityToggle tutorId={userId} weekStartDate={weekStartDate} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	tutorAvailabilityAction: {
		display: 'grid'
	},
	detailsColumn: {
		display: 'grid',
		gridTemplateRows: 'repeat(2, max-content)',
	},
})