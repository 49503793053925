import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type BackgroundProps = {}

export const Background: FC<BackgroundProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.background}>
			<div className={styles.innerBackground} />
		</div>
	)
}

const useStyles = createUseStyles({
	background: {
		display: 'grid',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		background: 'linear-gradient(0deg, rgba(135,231,222,1) 0%, rgba(102,201,237,1) 100%)',
	},
	innerBackground: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
	},
})