import React, { FC } from 'react'
import { IconProps } from '../props'

export const InfoIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 496 496" fill={color}>
			<path id="Path_35" data-name="Path 35" d="M256,8C119.043,8,8,119.083,8,256,8,393,119.043,504,256,504S504,393,504,256C504,119.083,392.957,8,256,8Zm0,110a42,42,0,1,1-42,42A42,42,0,0,1,256,118Zm56,254a12,12,0,0,1-12,12H212a12,12,0,0,1-12-12V348a12,12,0,0,1,12-12h12V272H212a12,12,0,0,1-12-12V236a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12V336h12a12,12,0,0,1,12,12Z" transform="translate(-8 -8)" />
		</svg>
	)
}