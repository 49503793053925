import { blue } from '@ant-design/colors'
import Avatar from 'antd/lib/avatar/avatar'
import { compact } from 'lodash'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { UserProfile } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ListItem } from '../../list-item/ListItem'
import { StyledAdminIcon } from '../../styled-icons/styled-admin-icon/StyledAdminIcon'
import { UserIsAdminToggle } from '../user-is-admin-toggle/UserIsAdminToggle'

type UserAdminToggleListItemProps = {
	userProfile: RecordWithID<UserProfile>
	isAdmin: boolean
	compactView?: boolean
}

export const UserAdminToggleListItem: FC<UserAdminToggleListItemProps> = ({
	userProfile,
	isAdmin,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(userProfile)

	return (
		<ListItem compactView={compactView}>
			<Avatar style={{ backgroundColor: blue.primary }}>{initials}</Avatar>
			<h3>{userProfile.displayName}</h3>
			<UserIsAdminToggle userId={userProfile.id} isAdmin={isAdmin} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	userAdminToggleListItem: {
		display: 'grid'
	}
})