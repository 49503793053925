import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { useStudentRegistrations } from '../../../../common/hooks/database/use-student-registrations'
import { getDate, getNow } from '../../../../common/logic/date-logic'
import { Registration } from '../../../../common/types/types'
import { chunkRegistrationsByWeek } from '../../../../common/utils/database/chunk-registrations-by-week'
import { HomeSection } from '../../../home-section/HomeSection'
import { RegistrationsforWeekSection } from '../registrations-for-week-section/RegistrationsforWeekSection'

type StudentMySessionsSectionProps = {}

export const StudentMySessionsSection: FC<StudentMySessionsSectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isStudent } = useCurrentUserProfile()
	const registrations = useStudentRegistrations()
	const registrationsByWeek = useMemo(() => chunkRegistrationsByWeek(registrations), [registrations])
	const registrationWeeks = useMemo(() => registrationsByWeek ? Object.keys(registrationsByWeek).map(date => parseInt(date)) : undefined, [registrationsByWeek])
	const defaultTitle = useMemo(() => `My Sessions this Week (${getNow().startOf('week').add(1, 'week').format('dddd MMM, D')})`, [])

	if (!isStudent) {
		return null
	}

	return (
		<div className={styles.studentMySessionsSection}>
			{registrationsByWeek && registrationWeeks?.map(registrationWeek => (
				<RegistrationsforWeekSection key={registrationWeek} week={registrationWeek} registrations={registrationsByWeek[registrationWeek]} />
			))}
			{registrationWeeks && registrationWeeks?.length < 1 && (
				<HomeSection title={defaultTitle} helpText="Showing sessions that you have scheduled for this week.">
					<span className={styles.infoText}>You don't have any session scheduled for this week</span>
				</HomeSection>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	studentMySessionsSection: {
		display: 'grid',
		gap: 24,
		gridTemplateRows: 'max-content',
	},
	infoText: {
		fontStyle: 'italic',
		fontSize: 12,
	},
})