import { message } from 'antd'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { signInWithGoogle } from '../../../common/logic/authentication-logic'
import { GoogleLogo } from './resources/google-logo'
import './styles.css'

export const SignInWithGoogleButton: FC = () => {

    // hooks
    const styles = useStyles()

    // event handlers
    const handleClick = () => {
        signInWithGoogle().catch((err) => {
            message.error(err.message)
        })
    }

    return (
        <button className={styles.signInWithGoogleButton} onClick={handleClick}>
            <GoogleLogo />
            <span>
                Sign in with Google
            </span>
        </button>
    )
}

const useStyles = createUseStyles({
    signInWithGoogleButton: {
        fontFamily: "'Roboto', sans-serif",
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        padding: 12,
        fontWeight: 'bold',
        color: '#757575',
        fontSize: 18,
        gap: 18,
        display: 'grid',
        alignItems: 'center',
        gridAutoColumns: 'auto',
        gridAutoFlow: 'column',
        border: 'none',
        backgroundColor: 'white',
        borderRadius: 4,
        cursor: 'pointer',
        letterSpacing: 1,
        '&:hover': {
            backgroundColor: '#EEEEEE',
            boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        }
    }
})