import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageTutorsDialog } from '../../../dialogs/admin-manage-tutors-dialog/AdminManageTutorsDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledTutorsIcon } from '../../../styled-icons/styled-tutors-icon/StyledTutorsIcon'

type ManageTutorsActionProps = {}

export const ManageTutorsAction: FC<ManageTutorsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [setShowDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledTutorsIcon />
				<h3>Manage Tutors</h3>
			</ListItem>
			<AdminManageTutorsDialog visible={showDialog} onClose={handleDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	manageTutorsAction: {
		display: 'grid'
	}
})