import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useAdmins } from '../../../common/hooks/database/use-admins'
import { useAllOtherUserProfiles } from '../../../common/hooks/database/use-all-other-user-profiles'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { UserAdminToggleListItem } from '../../admin/user-admin-toggle-list-item/UserAdminToggleListItem'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { LoadingOverlay } from '../../loading-overlay/LoadingOverlay'

type AdminManageAdminsDialogProps = {} & DialogProps

export const AdminManageAdminsDialog: FC<AdminManageAdminsDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const userProfiles = useAllOtherUserProfiles()
	const filteredUserProfiles = useMatchSortedRecords(userProfiles, searchQuery, ['displayName', 'emailAddress'])
	const admins = useAdmins()
	const adminIds = useMemo(() => admins ? admins.map(admin => admin.id) : undefined, [admins])

    // side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageAdminsDialog' })
		}
	}, [visible])

	// event handlers
	const handleSearchTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader title="Manage Admins" />
			<DialogBodywithSearch
				searchInputProps={{
					value: searchQuery,
					onChange: handleSearchTextChange,
					placeholder: 'Search Users'
				}}
				compactView={true}
			>
				{filteredUserProfiles?.map(userProfile => (
					<UserAdminToggleListItem key={userProfile.id} userProfile={userProfile} isAdmin={adminIds ? adminIds.includes(userProfile.id) : false} compactView={true} />
				))}
			</DialogBodywithSearch>
			<DialogFooter>
				<DialogFooterButton color={blue.primary} textColor="white" onClick={onClose}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={!adminIds} />
		</Dialog>
	)
}

const useStyles = createUseStyles({
	adminManageAdminsDialog: {
		display: 'grid'
	}
})