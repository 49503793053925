import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSubjects } from '../../../common/hooks/database/use-subjects'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Button } from '../../button/Button'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { SubjectListItem } from '../../subjects/subject-list-item/SubjectListItem'
import { UpdateSubjectListItem } from '../../tutors/update-subject-list-item/UpdateSubjectListItem'
import { CreateSubjectDialog } from '../create-subject-dialog/CreateSubjectDialog'

type AdminManageSubjectsDialogProps = {} & DialogProps

export const AdminManageSubjectsDialog: FC<AdminManageSubjectsDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const subjects = useSubjects()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredSubjects = useMatchSortedRecords(subjects, searchQuery, ['name'])
	const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false)

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageSubjectsDialog' })
		}
	}, [visible])

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSearchInputTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	const handleCreateButtonClick = useCallback(() => {
		setShowCreateDialog(true)
	}, [setShowCreateDialog])

	const handleCreateDialogClose = useCallback(() => {
		setShowCreateDialog(false)
	}, [setShowCreateDialog])

	return (
		<>
			<Dialog visible={visible} onClose={onClose}>
				<DialogHeader onClose={onClose} title="Manage Subjects" />
				<DialogBodywithSearch
					searchInputProps={{
						value: searchQuery,
						onChange: handleSearchInputTextChange,
						placeholder: 'Search Subjects'
					}}
					actions={(
						<Button color={blue.primary} textColor="white" onClick={handleCreateButtonClick}>Add Subject</Button>
					)}
					compactView={true}
				>
					{filteredSubjects?.map(subject => (
						<SubjectListItem key={subject.id} subject={subject} compactView={true} />
					))}
				</DialogBodywithSearch>
				<DialogFooter>
					<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
						Return to Dashboard
					</DialogFooterButton>
				</DialogFooter>
			</Dialog>
			<CreateSubjectDialog visible={showCreateDialog} onClose={handleCreateDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	adminManageSubjectsDialog: {
		display: 'grid'
	}
})