import { blue, gold, red } from '@ant-design/colors'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Colors } from '../../../../../common/constants/colors'
import { useUpcomingWeeks } from '../../../../../common/hooks/other/use-upcoming-weeks'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../../../form-row/FormRow'
import { Select } from '../../../../select/Select'
import moment from 'moment'
import { Registration, RegistrationPreference, RegistrationWeek, Subject, Tutor } from '../../../../../common/types/types'
import { useSubjects } from '../../../../../common/hooks/database/use-subjects'
import { RecordWithID } from '../../../../../common/types/utils'
import { useAvailableTutors } from '../../../../../common/hooks/database/use-available-tutors'
import { InfoCallout } from '../../../../info-callout/InfoCallout'
import { WarnIcon } from '../../../../icons/warn-icon/WarnIcon'
import { TutorOption, TutorSelect } from '../../../../tutors/tutor-select/TutorSelect'
import { InfoIcon } from '../../../../icons/info-icon/InfoIcon'
import { useNotAvailableTutors } from '../../../../../common/hooks/database/use-not-available-tutors'
import { TutorMultiSelect } from '../../../../tutors/tutor-multi-select/TutorMultiSelect'
import { registerForASession } from '../../../../../common/logic/registration-logic'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'
import { RegisterForASessionDialogContext } from '../../register-for-a-session-dialog-context'
import { useStudentHasRegistration } from '../../../../../common/hooks/database/use-student-has-registration'
import { getDate, getNow } from '../../../../../common/logic/date-logic'
import { PreferenceFormItem } from '../preference-form-item/PreferenceFormItem'

const generateLabel = (date: moment.Moment) => {
	const helpText = [
		'This Week',
		'Next Week',
	]

	const weeksFromNow = Math.abs(date.diff(getNow().startOf('week'), 'weeks'))
	const thisHelpText = helpText[weeksFromNow] ? helpText[weeksFromNow] : `In ${weeksFromNow} Weeks`

	return `${thisHelpText} (${getDate(date).add(7, 'days').format('dddd MMM, D')})`
}

type RegisterForASessionProps = {}

export const RegisterForASession: FC<RegisterForASessionProps> = ({}) => {

	// hooks
	const { control, formState: { isValid }, setValue, watch, reset, trigger, getValues } = useForm({ mode: 'all' })
	const { setDialogState, onClose, setRegistrationId, studentId } = useContext(RegisterForASessionDialogContext)
	const upcomingWeeks: RegistrationWeek[] = useUpcomingWeeks(useCallback(generateLabel, []))
	const subjects = useSubjects()
	const registrationWeek: RegistrationWeek | undefined = watch('registrationWeek')
	const subject: RecordWithID<Subject> | undefined = watch('subject')
	const tutorOption: TutorOption | undefined = watch('tutorOption')
	const availableTutors = useAvailableTutors(registrationWeek?.startDate, subject?.id)
	const tutorMenuItems: TutorOption[] = useMemo(() => {
		const menuItems: TutorOption[] = []
		menuItems.push({
			id: 'wait',
			type: 'option',
			data: { label: 'Wait for a Different Tutor' },
		}, { id: 'divider', type: 'divider', data: {} })
		availableTutors?.forEach(tutor => tutor && (menuItems.push({ id: tutor.id, type: 'tutor', data: tutor })))
		return menuItems
	}, [availableTutors])
	const noTutors = useMemo(() => availableTutors && availableTutors.length < 1, [availableTutors])
	const waitForATutor = useMemo(() => noTutors || tutorOption?.id === 'wait', [noTutors, tutorOption])
	const notAvailableTutors = useNotAvailableTutors(registrationWeek?.startDate, subject?.id)
	const sundayText: string | undefined = useMemo(() => {
		if (!registrationWeek) { return undefined }
		return getDate(registrationWeek.startDate).subtract(1, 'week').format('MMM DD')
	}, [registrationWeek])
	const preferredTutorNote: string = useMemo(() => {
		return `If one of your preferred tutors hasn’t signed up by 3:30pm Eastern Time on Sunday (${sundayText}), you’ll be assigned to the next available tutor (if there is one).`
	}, [sundayText])
	const [loading, setLoading] = useState<boolean>(false)
	const studentHasRegistration = useStudentHasRegistration(studentId, registrationWeek?.startDate)

	const registrationPreferences = watch('registrationPreferences')

	// side effects
	useEffect(() => {
		setValue('tutorOption', undefined)
		trigger()
	}, [registrationWeek, subject])

	useEffect(() => {
		setValue('preferredTutors', [])
		trigger()
	}, [registrationWeek, subject, tutorOption])

	useEffect(() => {
		const interval = setInterval(() => {
			trigger()
		}, 16)
		setTimeout(() => {
			clearInterval(interval)
		}, 200);
	}, [registrationWeek, subject, tutorOption])

	// event handlers
	const handleClose = useCallback(() => {
		onClose && onClose()
		reset()
	}, [onClose, reset])

	const handleSubmit = useCallback(() => {
		if (studentId) {
			setLoading(true)
			const {
				preferredTutors,
				registrationWeek,
				subject,
				tutorOption,
				registrationPreferences,
			} = getValues() as {
				preferredTutors: RecordWithID<Tutor>[],
				registrationWeek: RegistrationWeek,
				subject: RecordWithID<Subject>,
				tutorOption?: TutorOption,
				registrationPreferences: RegistrationPreference[],
			}
			const newRegistration: Omit<Registration, 'StudentID'> = {
				TutorID: tutorOption ? tutorOption.type === 'tutor' ? tutorOption.id : undefined : undefined,
				//SubjectID: subject.id,
				//PreferredTutorIDs: preferredTutors.map(tutor => tutor.id),
				registrationWeekStartDate: registrationWeek.startDate,
				registrationPreferences,
			}
			registerForASession(studentId, newRegistration).then((registrationId) => {
				setRegistrationId(registrationId)
				setLoading(false)
				setDialogState('success')
			})
		}
	}, [getValues, setLoading, setDialogState, setRegistrationId])

	if (!subjects) {
		return (
			<>
				<DialogHeader title="Register for a Tutoring Session" onClose={handleClose} />
				<DialogBody>
					<div style={{ height: 300, }} />
				</DialogBody>
				<LoadingOverlay loading={true} />
			</>
		)
	}

	return (
		<>
			<DialogHeader title="Register for a Tutoring Session" onClose={handleClose} />
			<DialogBody>
				<FormRow label="Choose a Week">
					<Controller
						name="registrationWeek"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={upcomingWeeks}
								options={{
									nameKey: 'label',
									idKey: 'startDate',
									searchableFields: ['label'],
								}}
								placeholder="Choose a Week..."
							/>
						)}
					/>
				</FormRow>
				{studentHasRegistration && (
					<InfoCallout
						icon={WarnIcon}
						title="Can't Register for Session"
						color={red.primary}
					>
						You've already registered for the week you chose.
						<br />
						<br />
						You cannot register for the same week twice.
					</InfoCallout>
				)}
				{/* 				<FormRow label="Choose a Subject" disabled={!registrationWeek}>
					<Controller
						name="subject"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={subjects}
								disabled={!registrationWeek}
								options={{
									nameKey: 'name',
									idKey: 'id',
									searchableFields: ['name'],
								}}
								placeholder="Choose a Subject..."
							/>
						)}
					/>
				</FormRow> */}
{/* 				<FormRow label="Choose a Tutor" disabled={!subject || noTutors || studentHasRegistration}>
					<Controller
						name="tutorOption"
						control={control}
						defaultValue={undefined}
						rules={{ required: !noTutors }}
						render={({ field }) => (
							<TutorSelect
								{...field}
								items={tutorMenuItems}
								placeholder="Choose a Tutor..."
								disabled={!subject || noTutors || studentHasRegistration}
							/>
						)}
					/>
				</FormRow> */}
				<Controller
					name="registrationPreferences[0]"
					control={control}
					rules={{ required: true, validate: (preference?: Partial<RegistrationPreference>) => preference?.SubjectID ? true : false }}
					render={({ field }) => (
						<PreferenceFormItem {...field} subjectLabel="1st Preference Subject" disabled={!registrationWeek || studentHasRegistration} />
					)}
				/>
				<Controller
					name="registrationPreferences[1]"
					control={control}
					rules={{ required: false }}
					render={({ field }) => (
						<PreferenceFormItem {...field} subjectLabel="2nd Preference Subject" disabled={!registrationWeek || !registrationPreferences[0] || studentHasRegistration} />
					)}
				/>
				<Controller
					name="registrationPreferences[2]"
					control={control}
					rules={{ required: false }}
					render={({ field }) => (
						<PreferenceFormItem {...field} subjectLabel="3rd Preference Subject" disabled={!registrationWeek || !registrationPreferences[1] || studentHasRegistration} />
					)}
				/>
				{registrationWeek && subject && notAvailableTutors && (
					<>
						{waitForATutor && (
							<FormRow label="Which tutor(s) would you like to wait for?" subtitle="You can choose up to 3 tutors." note={preferredTutorNote}>
								<Controller
									name="preferredTutors"
									control={control}
									defaultValue={[]}
									rules={{ required: false, validate: (val: any[]) => val.length < 4 }}
									render={({ field }) => (
										<TutorMultiSelect
											{...field}
											items={notAvailableTutors}
											placeholder="Optionally choose one or more Tutors..."
											disabled={!subject || noTutors}
										/>
									)}
								/>
							</FormRow>
						)}
						{noTutors && !studentHasRegistration && (
							<InfoCallout
								icon={WarnIcon}
								title="No Tutors Available Yet"
								color={gold.primary}
							>
								If you choose to join the waitlist, we’ll notify you once you’ve been paired with a tutor.
								<br />
								<br />
								If you don't want to choose a preferred tutor, we'll pair you with the first tutor who is available.
							</InfoCallout>
						)}
						{waitForATutor && !noTutors && (
							<InfoCallout
								icon={InfoIcon}
								title="Note: Waiting for Tutors"
								color={blue.primary}
							>
								If you don't choose a preferred tutor, we'll pair you with the first tutor who is available.
							</InfoCallout>
						)}
					</>

				)}
				<InfoCallout
					icon={InfoIcon}
					title="Please sign up by 3:30pm on Sunday."
					color={blue.primary}
				>
					Please sign up by 3:30pm on Sunday. The pairing list will be sent out at 4:00pm.
					<br />
					<br />
					If you’re not assigned a tutors, we will manually pair you up and post on our WhatsApp groups before 5PM.
				</InfoCallout>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.lightGray} onClick={handleClose}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={noTutors ? gold.primary : blue.primary} textColor="white" disabled={!isValid || studentHasRegistration} onClick={handleSubmit}>
					{waitForATutor && 'Join Waitlist'}
					{!waitForATutor && 'Register Now'}
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={loading} />
		</>
	)
}