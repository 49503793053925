import React, { FC } from 'react'
import { IconProps } from '../props'

export const RemoveUserIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 19.2" fill={color}>
			<path id="Path_29" data-name="Path 29" d="M23.4,7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h7.2a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z" />
		</svg>
	)
}