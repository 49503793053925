import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileMaxWidth } from '../../common/constants/breakpoints'
import { useUpdatesInProgress } from '../../common/hooks/database/use-updates-inprogress'
import { LoadingOverlay } from '../loading-overlay/LoadingOverlay'
import { ScrollView } from '../scroll-view/ScrollView'
import { ManageStudentTutoringSection } from './home-sections/manage-student-tutoring-section/ManageStudentTutoringSection'
import { ManagethePlatformSection } from './home-sections/manage-the-platform-section/ManagethePlatformSection'
import { ManageTutorTutoringSection } from './home-sections/manage-tutor-tutoring-section/ManageTutorTutoringSection'
import { StatisticsSection } from './home-sections/statistics-section/StatisticsSection'
import { StudentMySessionsSection } from './home-sections/student-my-sessions-section/StudentMySessionsSection'
import { TutorAvailabilitySection } from './home-sections/tutor-availability-section/TutorAvailabilitySection'
import { TutorMySessionsSection } from './home-sections/tutor-my-sessions-section/TutorMySessionsSection'

type HomeProps = {}

export const Home: FC<HomeProps> = () => {

	// hooks
	const styles = useStyles()
	const updatesInProgress = useUpdatesInProgress()

	if (updatesInProgress === undefined) {
		return <LoadingOverlay loading={true} />
	}

	if (updatesInProgress) {
		return (
			<div>
				We're in the middle of making updates to the platform. Please check back soon.
				<br />
				<br />
				If you need something urgent, please reach out to Casey Kang via WhatsApp.
			</div>
		)
	}

	return (
		<>
			<div className={styles.oneColumn}>
				<ManageStudentTutoringSection />
				<TutorAvailabilitySection />
				<ManageTutorTutoringSection />
				<StudentMySessionsSection />
				<TutorMySessionsSection />
				<ManagethePlatformSection />
				<StatisticsSection />
			</div>
			<div className={styles.twoColumns}>
				<div className={styles.column}>
					<ManageStudentTutoringSection />
					<TutorAvailabilitySection />
					<ManageTutorTutoringSection />
					<ManagethePlatformSection />
				</div>
				<div className={styles.column}>
					<StudentMySessionsSection />
					<TutorMySessionsSection />
					<StatisticsSection />
				</div>
			</div>
		</>
	)
}

const useStyles = createUseStyles({
	oneColumn: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: 24,
		'&:last-child': {
			marginBottom: 24,
		}
	},
	column: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: 24,
		height: 'calc(100vh - 254px)',
		maxHeight: 'calc(100vh - 254px)',
		overflowY: 'auto',
		paddingLeft: 12,
		paddingRight: 12,
		gridAutoRows: 'max-content',
		'&:last-child': {
			marginBottom: 24,
		}
	},
	twoColumns: {
		display: 'none',
		gridTemplateColumns: `repeat(2, ${(mobileMaxWidth / 2) - 40}px)`,
		maxWidth: mobileMaxWidth,
	},
	[`@media screen and (min-width: ${mobileMaxWidth - 1}px)`]: {
		oneColumn: {
			display: 'none',
		},
		twoColumns: {
			display: 'grid',
		},
	},
})