import React, { FC, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { SelectTile, SelectTileOption } from './subcomponents/select-tile/SelectTile'

type SelectTilesProps = {
    options: SelectTileOption[]
    selectedOptionId?: string
    onChange?: (id: string) => void
}

export const SelectTiles: FC<SelectTilesProps> = ({
    options,
    selectedOptionId,
    onChange,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.selectTiles}>
            {options.map(option => (
                <SelectTile key={option.id} option={option} selectedOptionId={selectedOptionId} onSelect={onChange} />
            ))}
        </div>
    )
}

const useStyles = createUseStyles({
    selectTiles: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 12,
    }
})