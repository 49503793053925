import React, { ComponentType, FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { IconProps } from '../../icons/props'
import { LoadingOverlay } from '../../loading-overlay/LoadingOverlay'

type StatisticsItemProps = {
	color?: string
	icon: ComponentType<IconProps>
	value?: string | number
	description: string
	title: string
}

export const StatisticsItem: FC<StatisticsItemProps> = ({
	color,
	icon: Icon,
	value,
	description,
	title,
}) => {

	// hooks
	const styles = useStyles()
	const loading = useMemo(() => {
		if (value === undefined) { return true }
		else { return false }
	}, [value])

	return (
		<div className={styles.statisticsItem} style={{ borderColor: color }}>
			<div className={styles.titleRow} style={{ backgroundColor: color }}>
				<Icon size={24} color="white" />
				<h4>{title}</h4>
			</div>
			<div className={styles.content}>
				<h1>{value}</h1>
				<span>{description}</span>
			</div>
			<LoadingOverlay loading={loading} />
		</div>
	)
}

const useStyles = createUseStyles({
	statisticsItem: {
		display: 'grid',
		border: `1px solid ${Colors.gray}`,
		borderRadius: 8,
		position: 'relative',
		gridAutoRows: 'max-content',
	},
	titleRow: {
		display: 'grid',
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		padding: 6,
		gridTemplateColumns: 'max-content 1fr',
		alignItems: 'center',
		gap: 8,
		color: 'white',
		'& h4': {
			fontSize: 14,
			fontWeight: 500,
			color: 'white',
		},
	},
	content: {
		display: 'grid',
		justifyItems: 'center',
		alignContent: 'center',
		textAlign: 'center',
		padding: 14,
		gap: 4,
	},
})