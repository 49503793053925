import React, { FC } from 'react'
import { IconProps } from '../props'

export const RegistrationIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16.5 22" fill={color}>
			<path id="Path_2" data-name="Path 2" d="M12.031,10.313H7.219a.345.345,0,0,0-.344.344v.688a.345.345,0,0,0,.344.344h4.813a.345.345,0,0,0,.344-.344v-.687A.345.345,0,0,0,12.031,10.313Zm0,4.125H7.219a.345.345,0,0,0-.344.344v.688a.345.345,0,0,0,.344.344h4.813a.345.345,0,0,0,.344-.344v-.687A.345.345,0,0,0,12.031,14.437ZM4.813,9.969A1.031,1.031,0,1,0,5.844,11,1.029,1.029,0,0,0,4.813,9.969Zm0,4.125a1.031,1.031,0,1,0,1.031,1.031A1.029,1.029,0,0,0,4.813,14.094ZM14.437,2.75H11a2.75,2.75,0,0,0-5.5,0H2.063A2.063,2.063,0,0,0,0,4.813V19.938A2.063,2.063,0,0,0,2.063,22H14.437A2.063,2.063,0,0,0,16.5,19.938V4.813A2.063,2.063,0,0,0,14.437,2.75ZM8.25,2.063a.687.687,0,1,1-.688.688A.69.69,0,0,1,8.25,2.063Zm6.187,17.531a.345.345,0,0,1-.344.344H2.406a.345.345,0,0,1-.344-.344V5.156a.345.345,0,0,1,.344-.344H4.125V6.188a.69.69,0,0,0,.688.687h6.875a.69.69,0,0,0,.688-.687V4.813h1.719a.345.345,0,0,1,.344.344Z" />
		</svg>
	)
}