import React, { FC } from 'react'
import { IconProps } from '../props'

export const DeleteIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 24" fill={color}>
			<path id="Path_28" data-name="Path 28" d="M1.5,21.75A2.25,2.25,0,0,0,3.75,24h13.5a2.25,2.25,0,0,0,2.25-2.25V6H1.5Zm12.75-12a.75.75,0,0,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm-4.5,0a.75.75,0,1,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm-4.5,0a.75.75,0,0,1,1.5,0v10.5a.75.75,0,0,1-1.5,0Zm15-8.25H14.625L14.184.623A1.125,1.125,0,0,0,13.177,0H7.819a1.112,1.112,0,0,0-1,.623L6.375,1.5H.75A.75.75,0,0,0,0,2.25v1.5a.75.75,0,0,0,.75.75h19.5A.75.75,0,0,0,21,3.75V2.25A.75.75,0,0,0,20.25,1.5Z" transform="translate(0 0)" />
		</svg>
	)
}