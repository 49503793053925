import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTutorsInSubject } from '../../../common/hooks/database/use-tutors-in-subject'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Button } from '../../button/Button'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { ListItem } from '../../list-item/ListItem'
import { StyledSubjectsIcon } from '../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'
import { TutorinSubjectListItem } from '../../subjects/tutor-in-subject-list-item/TutorinSubjectListItem'
import { AddTutortoSubjectDialog } from '../add-tutor-to-subject-dialog/AddTutortoSubjectDialog'

type ManageTutorsinSubjectDialogProps = {
	subject: RecordWithID<Subject>
} & DialogProps

export const ManageTutorsinSubjectDialog: FC<ManageTutorsinSubjectDialogProps> = ({
	subject,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const tutors = useTutorsInSubject(subject.id)
	const filteredTutors = useMatchSortedRecords(tutors, searchQuery, ['displayName', 'emailAddress', 'schoolName'])
	const [showAddTutorDialog, setShowAddTutorDialog] = useState<boolean>(false)

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'ManageTutorsinSubjectDialog' })
		}
	}, [visible])

	// event handlers
	const handleSearchQueryTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	const handleAddTutorButtonClick = useCallback(() => {
		setShowAddTutorDialog(true)
	}, [setShowAddTutorDialog])

	const handleAddTutorDialogClose = useCallback(() => {
		setShowAddTutorDialog(false)
	}, [setShowAddTutorDialog])

	return (
		<>
			<Dialog visible={visible} onClose={onClose}>
				<DialogHeader title="Manage Tutors in Subject" onClose={onClose} />
				<DialogBodywithSearch
					searchInputProps={{
						value: searchQuery,
						onChange: handleSearchQueryTextChange,
						placeholder: 'Search Tutors'
					}}
					before={(
						<ListItem>
							<StyledSubjectsIcon />
							<h3>{subject.name}</h3>
						</ListItem>
					)}
					actions={(
						<Button color={blue.primary} textColor="white" onClick={handleAddTutorButtonClick}>
							Add Tutor
						</Button>
					)}
					compactView={true}
				>
					{filteredTutors?.map(tutor => (
						<TutorinSubjectListItem tutor={tutor} subject={subject} compactView={true} />
					))}
					{filteredTutors && filteredTutors?.length < 1 && (
						<p>There are no tutors assigned to this subject</p>
					)}
				</DialogBodywithSearch>
				<DialogFooter>
					<DialogFooterButton textColor="white" color={blue.primary} onClick={onClose}>
						Go Back
					</DialogFooterButton>
				</DialogFooter>
			</Dialog>
			<AddTutortoSubjectDialog visible={showAddTutorDialog} onClose={handleAddTutorDialogClose} subject={subject} />
		</>
	)
}

const useStyles = createUseStyles({
	manageTutorsInSubjectDialog: {
		display: 'grid'
	},
	subjectRow: {
		display: 'grid',
		paddingLeft: 24,
		paddingTop: 24,
		paddingRight: 24,
	}
})