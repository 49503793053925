import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { UserIcon } from '../../icons/user-icon/UserIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledUserIconProps = {}

export const StyledUserIcon: FC<StyledUserIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={UserIcon} colors={{ background: blue.primary, foreground: blue[0] }} />
	)
}

const useStyles = createUseStyles({
	styledUserIcon: {
		display: 'grid'
	}
})