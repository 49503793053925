import { useMemo } from "react";
import { Admin } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { useFirestoreQuery } from "../firebase/firebase";

export const useAdmins = (): RecordWithID<Admin>[] | undefined => {

    // hooks
    const [adminIds, adminsData] = useFirestoreQuery<Admin>('admins', { filters: [], sorts: [['displayName', 'asc']] })
    const admins = useMemo(() => combineIdsAndFirebaseQueryData(adminIds, adminsData), [adminIds, adminsData])

    return admins

}