import { useContext, useMemo } from "react";
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context";
import { UserProfile } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { useUserProfiles } from "./use-user-profiles";

export const useAllOtherUserProfiles = (): RecordWithID<UserProfile>[] | undefined => {

    // hooks
    const { userId } = useContext(FirebaseContext)
    const userProfiles = useUserProfiles()
    const filteredUserProfiles = useMemo(() => {
        if (!userId || !userProfiles) { return undefined }
        return userProfiles.filter(userProfile => userProfile.id !== userId)
    }, [userId, userProfiles])

    return filteredUserProfiles

}