import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileDialogMaxWidth } from '../../../../common/constants/breakpoints'
import { Colors } from '../../../../common/constants/colors'
import { SearchInput, SearchInputProps } from '../../../search-input/SearchInput'

type DialogBodywithSearchProps = PropsWithChildren<{
	searchInputProps?: SearchInputProps
	actions?: ReactNode
	before?: ReactNode
	after?: ReactNode
	compactView?: boolean
}>

export const DialogBodywithSearch: FC<DialogBodywithSearchProps> = ({
	searchInputProps,
	children,
	actions,
	before,
	after,
	compactView,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.dialogBodyContainer}>
			<div className={styles.searchBarContainer}>
				{before && (
					<div className={styles.beforeContainer}>
						{before}
					</div>
				)}
				<div className={styles.searchInputContainer}>
					<SearchInput {...searchInputProps} />
					{actions}
				</div>
				{after && (
					<div className={styles.beforeContainer}>
						{after}
					</div>
				)}
			</div>
			<div className={`${styles.dialogBodyContainer} ${compactView && styles.clearPadding}`}>
				<div className={`${styles.dialogBody} ${compactView && styles.compactView}`}>
					{children}
				</div>
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	dialogBodyContainer: {
		display: 'grid',
		maxHeight: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		gridAutoRows: 'max-content',
	},
	dialogBody: {
		display: 'grid',
		padding: 14,
		gridAutoRows: 'max-content',
		marginBottom: 24,
		gap: 22,
	},
	searchInputContainer: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: '1fr',
		gridAutoColumns: 'max-content',
		gap: 14,
	},
	disableScroll: {
		overflowY: 'hidden',
	},
	searchBarContainer: {
		position: 'sticky',
		top: 0,
		backgroundColor: 'white',
		display: 'grid',
		paddingLeft: 14,
		paddingRight: 14,
		gap: 10,
		borderBottom: `1px solid ${Colors.border}`,
		zIndex: 2,
	},
	compactView: {
		gap: 0,
		padding: 0,
	},
	clearPadding: {
		padding: 0,
	},
	[`@media screen and (min-width: ${mobileDialogMaxWidth - 1}px)`]: {
		searchBarContainer: {
			position: 'sticky',
			top: 0,
			backgroundColor: 'white',
			display: 'grid',
			paddingTop: 18,
			paddingBottom: 18,
			paddingLeft: 24,
			paddingRight: 24,
			gap: 14,
		},
		dialogBody: {
			padding: 24,
		},
		compactView: {
			gap: 0,
			padding: 0,
		},
		clearPadding: {
			padding: 0,
		},
		beforeContainer: {
			paddingLeft: 24,
			paddingRight: 24,
		}
	},
})