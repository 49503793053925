import { RegistrationWeek } from "../../types/types";
import moment from 'moment'
import { generateUpcomingWeekMoments } from "../../logic/weeks-logic";
import { useMemo } from "react";

export const useUpcomingWeeks = (generateLabel: (date: moment.Moment) => string): RegistrationWeek[] => {
    
    const upcomingWeeks: RegistrationWeek[] = useMemo(() => {
        const upcomingWeekMoments = generateUpcomingWeekMoments(4)
        return upcomingWeekMoments.map(upcomingWeekMoment => ({
            label: generateLabel(upcomingWeekMoment),
            startDate: upcomingWeekMoment.unix(),
        }))
    }, [generateLabel])

    return upcomingWeeks

}