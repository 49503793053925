import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Home } from '../home/Home'

type AppRouterProps = {}

export const AppRouter: FC<AppRouterProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<Home />
	)
}

const useStyles = createUseStyles({
	appRouter: {
		display: 'grid'
	}
})