import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { UpdateTutorSubjectsDialog } from '../../../dialogs/update-tutor-subjects-dialog/UpdateTutorSubjectsDialog'
import { FirebaseContext } from '../../../firebase-provider/firebase-context'
import { ListItem } from '../../../list-item/ListItem'
import { StyledSubjectsIcon } from '../../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'

type UpdateTutorSubjectsActionProps = {}

export const UpdateTutorSubjectsAction: FC<UpdateTutorSubjectsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showUpdateSubjectsDialog, setShowUpdateSubjectsDialog] = useState<boolean>(false)
	const { userId } = useContext(FirebaseContext)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowUpdateSubjectsDialog(true)
	}, [setShowUpdateSubjectsDialog])

	const handleDialogClose = useCallback(() => {
		setShowUpdateSubjectsDialog(false)
	}, [setShowUpdateSubjectsDialog])

	if (!userId) {
		return null
	}

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledSubjectsIcon />
				<h3>Update My Subjects</h3>
			</ListItem>
			<UpdateTutorSubjectsDialog visible={showUpdateSubjectsDialog} onClose={handleDialogClose} tutorId={userId} />
		</>
	)
}

const useStyles = createUseStyles({
	updateTutorSubjectsAction: {
		display: 'grid'
	}
})