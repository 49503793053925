import React, { FC } from 'react'
import { IconProps } from '../props'

export const UserIcon: FC<IconProps> = ({
	size,
	color,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 448 512" fill={color}>
			<path id="Path_35" data-name="Path 35" d="M224,256A128,128,0,1,0,96,128,127.99,127.99,0,0,0,224,256Zm89.6,32H296.9a174.075,174.075,0,0,1-145.8,0H134.4A134.435,134.435,0,0,0,0,422.4V464a48.012,48.012,0,0,0,48,48H400a48.012,48.012,0,0,0,48-48V422.4A134.435,134.435,0,0,0,313.6,288Z" />
		</svg>
	)
}