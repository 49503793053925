import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Registration } from '../../../../common/types/types'
import { RecordWithID } from '../../../../common/types/utils'
import { HomeSection } from '../../../home-section/HomeSection'
import moment from 'moment'
import { RegistrationListItem } from '../../../registrations/registration-list-item/RegistrationListItem'
import { getDate, getNow } from '../../../../common/logic/date-logic'

type RegistrationsforWeekSectionProps = {
	week: number
	registrations: RecordWithID<Registration>[]
}

export const RegistrationsforWeekSection: FC<RegistrationsforWeekSectionProps> = ({
	week,
	registrations,
}) => {

	// hooks
	const styles = useStyles()
	const diffWeeks: number = useMemo(() => {
		const weekMoment = getDate(week)
		return Math.abs(getNow().startOf('week').diff(weekMoment, 'weeks'))
	}, [week])
	const isThisWeek = useMemo(() => diffWeeks === 0, [diffWeeks])
	const isNextWeek = useMemo(() => diffWeeks === 1, [diffWeeks])
	const sundayText = useMemo(() => getDate(week).add(1, 'week').format('dddd MMM, D'), [week])
	const title: string = useMemo(() => {
		if (isThisWeek) {
			return `My Sessions this Week (${sundayText})`
		}
		if (isNextWeek) {
			return `My Sessions next Week (${sundayText})`
		}
		return `My Sessions in ${diffWeeks} Weeks (${sundayText})`
	}, [isThisWeek, isNextWeek, diffWeeks])
	const helpText: string = useMemo(() => {
		if (isThisWeek) {
			return "Showing sessions that you have scheduled for this week."
		}
		if (isNextWeek) {
			return "Showing sessions that you have scheduled for next week."
		}
		return `Showing sessions that you have scheduled in ${diffWeeks} weeks.`
	}, [isThisWeek, isNextWeek, diffWeeks, sundayText])

	return (
		<HomeSection title={title} helpText={helpText}>
			{registrations.map(registration => (
				<RegistrationListItem
					key={registration.id}
					registration={registration}
				/>
			))}
		</HomeSection>
	)
}

const useStyles = createUseStyles({
	registrationsForWeekSection: {
		display: 'grid'
	}
})