import React, { FC, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useFirestoreQuery } from '../../../common/hooks/firebase/firebase'
import { TutorAvailability } from '../../../common/types/types'
import moment from 'moment'
import { FirebaseQueryFiltersAndSorts } from '../../../common/hooks/firebase/firebase-types'
import { AvailabilityIcon } from '../../icons/availability-icon/AvailabilityIcon'
import { geekblue } from '@ant-design/colors'
import { StatisticsItem } from '../../statistics/statistics-item/StatisticsItem'
import { getNow } from '../../../common/logic/date-logic'

type TutorsAvailableThisWeekStatisticProps = {}

export const TutorsAvailableThisWeekStatistic: FC<TutorsAvailableThisWeekStatisticProps> = () => {

	// hooks
	const styles = useStyles()
	const tutorsAvailableThisWeek = useTutorsAvailableThisWeek()

	return (
		<StatisticsItem
			title="Availability"
			icon={AvailabilityIcon}
			color={geekblue.primary}
			value={tutorsAvailableThisWeek}
			description="Tutors Available this Week"
		/>
	)
}

const useStyles = createUseStyles({
	registrationsThisWeekStatistic: {
		display: 'grid'
	}
})

const useTutorsAvailableThisWeek = (): number | undefined => {

	// hooks
	const thisWeek = useMemo(() => getNow().startOf('week').unix(), [])
	const queryParams: FirebaseQueryFiltersAndSorts<TutorAvailability> = useMemo(() => ({
		filters: [['registrationWeekStartDate', '==', thisWeek], ['availableRegistrations', '>', 0]]
	}), [thisWeek])
	const [tutorAvailabilityIds, _a, setQueryParams] = useFirestoreQuery<TutorAvailability>('tutorAvailabilities', queryParams)

	// side effects
	useEffect(() => {
		setQueryParams(queryParams)
	}, [queryParams])

	return tutorAvailabilityIds?.length

}