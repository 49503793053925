import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { UpdateTutorSubjectsDialog } from '../../dialogs/update-tutor-subjects-dialog/UpdateTutorSubjectsDialog'
import { SubjectsIcon } from '../../icons/subjects-icon/SubjectsIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type UpdateTutorSubjectsOverflowMenuItemProps = {
	tutorId: string
	onClose?: () => void
}

export const UpdateTutorSubjectsOverflowMenuItem: FC<UpdateTutorSubjectsOverflowMenuItemProps> = ({
	tutorId,
	onClose,
}) => {

	// hooks
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setDialogOpen, onClose])

	return (
		<>
			<OverflowMenuItem icon={SubjectsIcon} onClick={handleMenuItemClick}>
				Update Subjects
			</OverflowMenuItem>
			<UpdateTutorSubjectsDialog visible={dialogOpen} onClose={handleDialogClose} tutorId={tutorId} />
		</>
	)
}

const useStyles = createUseStyles({
	updateTutorSubjectsOverflowMenuItem: {
		display: 'grid'
	}
})