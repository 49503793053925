import { blue } from '@ant-design/colors'
import PhoneNumber from 'awesome-phonenumber'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../../../form-row/FormRow'
import { CompleteProfileDialogContext } from '../../complete-profile-dialog-context'
import { countries as sourceCountries } from "countries-list";
import { Country } from '../provide-student-details/ProvideStudentDetails'
import { Student, Tutor } from '../../../../../common/types/types'
import { createTutor } from '../../../../../common/logic/tutor-logic'
import { Select } from '../../../../select/Select'
import { useCurrentUserProfile } from '../../../../../common/hooks/database/use-current-user-profile'
import { Colors } from '../../../../../common/constants/colors'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'

type ProvideTutorDetailsProps = {}

export const ProvideTutorDetails: FC<ProvideTutorDetailsProps> = () => {

	// hooks
	const styles = useStyles()
	const { userProfile } = useCurrentUserProfile()
	const { register, getValues, control, formState: { isValid }, watch } = useForm({ mode: 'onChange' })
	const { setDialogState } = useContext(CompleteProfileDialogContext)
	const selectedCountry: Country = watch('country')
	const countries: Country[] = useMemo(() => Object.keys(sourceCountries).map((id) => {
		const countryId = id as keyof typeof sourceCountries
		return {
			...sourceCountries[countryId],
			id: countryId,
		}
	}), [])
	const [loading, setLoading] = useState<boolean>(false)

	const phoneNumberPlaceholder = useMemo(() => {
		return formatPhoneNumber(PhoneNumber.getExample(selectedCountry ? selectedCountry.id : 'US').getNumber())
	}, [selectedCountry])


	// event handlers
	const handleBackButtonClick = useCallback(() => {
		setDialogState('chooseUserType')
	}, [setDialogState])

	const handleFormSubmit = useCallback(() => {
		if (userProfile) {
			const formData = getValues()
			const tutor: Tutor = {
				displayName: userProfile.displayName,
				emailAddress: userProfile.emailAddress,
				phoneNumber: formData.phoneNumber,
				schoolName: formData.schoolName,
				country: formData.country.id,
				SubjectIDs: [],
				PreferredStudentID: null,
			}
			setLoading(true)
			createTutor(tutor).then(() => {
				setLoading(false)
				setDialogState('chooseTutorSubjects')
			})
		}
	}, [getValues, userProfile, setDialogState, setLoading])
	
	return (
		<>
			<DialogHeader title="Create Profile" />
			<DialogBody>
				<FormRow label="What country do you live in?">
					<Controller
						name="country"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={countries}
								options={{
									nameKey: 'name',
									idKey: 'id',
									searchableFields: ['name'],
								}}
								placeholder="Choose a Country..."
							/>
						)}
					/>
				</FormRow>
				<FormRow label="What's your phone number?">
					<Controller
						name="phoneNumber"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<PhoneInput
								{...field}
								defaultCountry={selectedCountry?.id}
								placeholder={phoneNumberPlaceholder}
							/>
						)}
					/>
				</FormRow>
				<FormRow label="What school do you attend?">
					<input placeholder="Shermer High School" {...register('schoolName', { required: true })} />
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleBackButtonClick}>
					Back
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!isValid} onClick={handleFormSubmit}>
					Next
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={loading} />
		</>
	)
}

const useStyles = createUseStyles({
	provideTutorDetails: {
		display: 'grid'
	}
})