import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ManageTutorsinSubjectDialog } from '../../dialogs/manage-tutors-in-subject-dialog/ManageTutorsinSubjectDialog'
import { TutorsIcon } from '../../icons/tutors-icon/TutorsIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type ManageTutorsinSubjectOverflowMenuItemProps = {
	subject: RecordWithID<Subject>
	onClose?: () => void
}

export const ManageTutorsinSubjectOverflowMenuItem: FC<ManageTutorsinSubjectOverflowMenuItemProps> = ({
	subject,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleMenuItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
		setTimeout(() => {
			onClose && onClose()
		}, 400);
	}, [setDialogOpen, onClose])

	return (
		<>
			<OverflowMenuItem icon={TutorsIcon} onClick={handleMenuItemClick}>
				Manage Tutors in Subject
			</OverflowMenuItem>
			<ManageTutorsinSubjectDialog visible={dialogOpen} onClose={handleDialogClose} subject={subject} />
		</>
	)
}

const useStyles = createUseStyles({
	manageTutorsInSubjectOverflowMenuItem: {
		display: 'grid'
	}
})