import { useMemo } from "react"
import { useUserProfile } from "./use-user-profile"

export const useUserIsDisabled = (userId: string): boolean | undefined => {
    
    // hooks
    const { userProfile } = useUserProfile(userId)
    const disabled = useMemo(() => {
        if (!userProfile) { return undefined }
        return userProfile.disabled
    }, [userProfile])

    return disabled

}