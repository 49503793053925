import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageSubjectsDialog } from '../../../dialogs/admin-manage-subjects-dialog/AdminManageSubjectsDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledSubjectsIcon } from '../../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'

type ManageSubjectsActionProps = {}

export const ManageSubjectsAction: FC<ManageSubjectsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [setShowDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledSubjectsIcon />
				<h3>Manage Subjects</h3>
			</ListItem>
			<AdminManageSubjectsDialog visible={showDialog} onClose={handleDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	manageSubjectsAction: {
		display: 'grid'
	}
})