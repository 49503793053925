import { geekblue } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { AvailabilityIcon } from '../../icons/availability-icon/AvailabilityIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledAvailabilityIconProps = {}

export const StyledAvailabilityIcon: FC<StyledAvailabilityIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={AvailabilityIcon} colors={{ background: geekblue.primary, foreground: geekblue[0] }} />
	)
}

const useStyles = createUseStyles({
	styledAvailabilityIcon: {
		display: 'grid'
	}
})