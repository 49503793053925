import { AppConfig } from "../../types/types";
import { useFirestoreDocument } from "../firebase/firebase";

export const useAppConfig = (): AppConfig | undefined => {

    // hooks
    const [appConfig] = useFirestoreDocument<AppConfig>('appconfig/aah')

    return appConfig

}