import { matchSorter } from 'match-sorter'
import React, { FC, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useAdminRegistrations } from '../../../common/hooks/database/use-admin-registrations'
import { useStudents } from '../../../common/hooks/database/use-students'
import { useSubjects } from '../../../common/hooks/database/use-subjects'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { chunkRegistrationsByWeek } from '../../../common/utils/database/chunk-registrations-by-week'
import { HomeSection } from '../../home-section/HomeSection'
import { AdminRegistrationsforWeekSection } from '../admin-registrations-for-week-section/AdminRegistrationsforWeekSection'

type AdminRegistrationsbyWeekProps = {
	searchQuery: string
	compactView?: boolean
}

export const AdminRegistrationsbyWeek: FC<AdminRegistrationsbyWeekProps> = ({
	searchQuery,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const registrations = useAdminRegistrations()
	const students = useStudents()
	const tutors = useTutors()
	const subjects = useSubjects()
	const filteredRegistrations = useMemo(() => {
		if (!searchQuery || !registrations || !students || !tutors || !subjects) {
			return registrations
		}
		const registrationsIndex: { studentName?: string, tutorName?: string, subjectName?: string, registrationId: string }[] = registrations.map(registration => {
			const student = students.find(student => student.id === registration.StudentID)
			const tutor = tutors.find(tutor => tutor.id === registration.TutorID)
			const subject = subjects.find(subject => subject.id === registration.SubjectID)
			return {
				registrationId: registration.id,
				studentName: student?.displayName,
				tutorName: tutor?.displayName,
				subjectName: subject?.name
			}
		})
		const filteredRegistrationIds = matchSorter(registrationsIndex, searchQuery, { keys: ['studentName', 'tutorName', 'subjectName'] }).map(index => index.registrationId)
		const filteredRegistrations = registrations.filter(registration => filteredRegistrationIds.includes(registration.id))
		filteredRegistrations.sort((a, b) => filteredRegistrationIds.indexOf(a.id) - filteredRegistrationIds.indexOf(b.id))
		return filteredRegistrations
	}, [searchQuery, registrations, students, tutors, subjects])
	const registrationsByWeek = useMemo(() => chunkRegistrationsByWeek(filteredRegistrations), [filteredRegistrations])
	const registrationWeeks = useMemo(() => registrationsByWeek ? Object.keys(registrationsByWeek).map(date => parseInt(date)) : undefined, [registrationsByWeek])

	return (
		<div className={`${styles.studentMySessionsSection} ${compactView && styles.compactView}`}>
			{registrationsByWeek && registrationWeeks?.map(registrationWeek => (
				<AdminRegistrationsforWeekSection key={registrationWeek} week={registrationWeek} registrations={registrationsByWeek[registrationWeek]} compactView={compactView} />
			))}
			{registrationWeeks && registrationWeeks?.length < 1 && (
				<HomeSection title="Registrations this Week" helpText="Showing sessions that you have scheduled for this week." compactView={compactView}>
					<span className={styles.infoText}>There aren't any registrations to show for this week</span>
				</HomeSection>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	studentMySessionsSection: {
		display: 'grid',
		gap: 24,
		gridTemplateRows: 'max-content',
	},
	infoText: {
		fontStyle: 'italic',
		fontSize: 12,
	},
	compactView: {
		gap: 0,
	}
})