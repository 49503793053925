import { blue } from '@ant-design/colors'
import { Spinner } from '@blueprintjs/core'
import { Switch } from 'antd'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Shadows } from '../../../common/constants/shadows'
import { useTutorAvailability } from '../../../common/hooks/database/use-tutor-availability'
import { updateTutorAvailability } from '../../../common/logic/tutor-availability-logic'
import { Button } from '../../button/Button'
import { LoadingOverlay } from '../../loading-overlay/LoadingOverlay'
import { NumberInput } from '../../number-input/NumberInput'
import { OverflowMenu, OverflowMenuProps } from '../../overflow-menu/OverflowMenu'

type AvailableRegistrationsMenuProps = {
	tutorId: string
	registrationWeekStartDate: number
	initialRegistrationsCount: number
} & OverflowMenuProps

export const AvailableRegistrationsMenu: FC<AvailableRegistrationsMenuProps> = ({
	tutorId,
	registrationWeekStartDate,
	initialRegistrationsCount,
	onClose,
	visible,
}) => {

	// hooks
	const styles = useStyles()
	const registrationWeek = useTutorAvailability(tutorId, registrationWeekStartDate)
	const [saving, setSaving] = useState<boolean>(false)
	const [statefulRegistrations, setStatefulRegistrations] = useState<number>(initialRegistrationsCount)
	const buttonDisabled = useMemo(() => (saving  || (registrationWeek?.availableRegistrations === statefulRegistrations)), [saving, registrationWeek, statefulRegistrations])

	// side effects
	const handleSaveButtonClick = useCallback(() => {
		setSaving(true)
		updateTutorAvailability(tutorId, registrationWeekStartDate, statefulRegistrations).then(() => {
			setSaving(false)
			onClose && onClose()
		})
	}, [setSaving, tutorId, registrationWeekStartDate, statefulRegistrations, setSaving, onClose])

	const handleToggleSwitch = useCallback((value: boolean) => {
		setStatefulRegistrations(value ? 1 : 0)
	}, [setStatefulRegistrations])

	if (!registrationWeek) {
		return null
	}

	return (
		<OverflowMenu visible={visible} onClose={onClose}>
			<div className={styles.availabilityRegistrationsMenu}>
				<span className={styles.label}>Available?</span>
				<Switch checked={statefulRegistrations > 0} onChange={handleToggleSwitch} style={{ marginBottom: 12, marginTop: 6 }} />
				<p className={styles.note}>Changing availability here will not cancel any registrations that have already been scheduled.</p>
				<Button style={{ width: '100%' }} disabled={buttonDisabled} onClick={handleSaveButtonClick} color={blue.primary} textColor="white">Save</Button>
			</div>
			<LoadingOverlay loading={saving} />
		</OverflowMenu>
	)
}

const useStyles = createUseStyles({
	availabilityRegistrationsMenu: {
		display: 'grid',
		gridAutoRows: 'max-content',
		justifyItems: 'center',
		gap: 8,
	},
	label: {
		fontSize: 14,
		fontWeight: 500,
		textAlign: 'center',
	},
	availabilityMenuContainer: {
		position: 'absolute',
		right: 8,
		top: 8,
		zIndex: 1,
	},
	note: {
		fontSize: 11,
		textAlign: 'center',
		opacity: 0.75,
	},
	'@keyframes menu-appear': {
		from: {
			transform: 'scale(0.8)',
			opacity: 0,
		},
		to: {
			transform: 'scale(1)',
			opacity: 1,
		},
	},
	'@keyframes backdrop-appear': {
		from: {
			opacity: 0,
		},
		to: {
			opacity: 0.15,
		}
	},
	availabilityMenu: {
		transformOrigin: 'top right',
		position: 'relative',
		zIndex: 1,
		backgroundColor: 'white',
		boxShadow: Shadows['4dp'],
		padding: 8,
		borderRadius: 8,
		cursor: 'unset',
		animationFillMode: 'both',
		animation: `$menu-appear 100ms ease`,
	},
	availabilityMenuBackdrop: {
		position: 'fixed',
		left: 0,
		top: 0,
		width: '100vw',
		height: '100vh',
		cursor: 'pointer',
		backgroundColor: 'black',
		opacity: 0.15,
		zIndex: 0,
		animationFillMode: 'both',
		animation: `$backdrop-appear 100ms ease`,
	},
})