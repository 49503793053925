import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageAdminsDialog } from '../../../dialogs/admin-manage-admins-dialog/AdminManageAdminsDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledAdminIcon } from '../../../styled-icons/styled-admin-icon/StyledAdminIcon'

type ManageAdminsActionProps = {}

export const ManageAdminsAction: FC<ManageAdminsActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setDialogOpen(true)
	}, [setDialogOpen])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [setDialogOpen])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledAdminIcon />
				<h3>Manage Admins</h3>
			</ListItem>
			<AdminManageAdminsDialog visible={dialogOpen} onClose={handleDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	manageAdminsAction: {
		display: 'grid'
	}
})