import React, { FC } from 'react'
import { IconProps } from '../props'

export const AvailabilityIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 22" fill={color}>
			<path id="Path_14" data-name="Path 14" d="M5,13.063H15a.7.7,0,0,0,.714-.687V9.625A.7.7,0,0,0,15,8.938H5a.7.7,0,0,0-.714.688v2.75A.7.7,0,0,0,5,13.063ZM17.857,2.75H15.714V.688A.7.7,0,0,0,15,0H13.571a.7.7,0,0,0-.714.688V2.75H7.143V.688A.7.7,0,0,0,6.429,0H5a.7.7,0,0,0-.714.688V2.75H2.143A2.1,2.1,0,0,0,0,4.813V19.938A2.1,2.1,0,0,0,2.143,22H17.857A2.1,2.1,0,0,0,20,19.938V4.813A2.1,2.1,0,0,0,17.857,2.75Zm0,16.93a.264.264,0,0,1-.268.258H2.411a.264.264,0,0,1-.268-.258V6.875H17.857Z" />
		</svg>
	)
}