import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSubjects } from '../../../common/hooks/database/use-subjects'
import { useTutor } from '../../../common/hooks/database/use-tutor'
import { useMatchSortedRecords } from '../../../common/hooks/other/use-match-sorted-records'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { FirebaseContext } from '../../firebase-provider/firebase-context'
import { InfoIcon } from '../../icons/info-icon/InfoIcon'
import { InfoCallout } from '../../info-callout/InfoCallout'
import { UpdateSubjectListItem } from '../update-subject-list-item/UpdateSubjectListItem'

type UpdateSubjectsDialogBodyProps = {
	tutorId: string
}

export const UpdateSubjectsDialogBody: FC<UpdateSubjectsDialogBodyProps> = ({
	tutorId,
}) => {

	// hooks
	const styles = useStyles()
	const { tutor } = useTutor(tutorId)
	const subjects = useSubjects()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredSubjects = useMatchSortedRecords(subjects, searchQuery, ['name'])

	// event handlers
	const handleSearchTextChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	return (
		<>
			<DialogBodywithSearch searchInputProps={{ value: searchQuery, onChange: handleSearchTextChange, placeholder: 'Search Subjects' }} compactView={true} before={(
				<InfoCallout title="About Choosing Subjects" icon={InfoIcon}>
					Make sure to choose all subjects that you're available to teach.
					<br />
					If you don't choose a subject, then students won't be able to register for that subject with you.
				</InfoCallout>
			)}>
				{filteredSubjects?.map(subject => (
					<UpdateSubjectListItem key={subject.id} subject={subject} tutor={tutor} compactView={true} />
				))}
			</DialogBodywithSearch>
		</>
	)
}

const useStyles = createUseStyles({
	updateSubjectsDialogBody: {
		display: 'grid'
	}
})