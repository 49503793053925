import { green } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { SubjectsIcon } from '../../icons/subjects-icon/SubjectsIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledSubjectsIconProps = {}

export const StyledSubjectsIcon: FC<StyledSubjectsIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={SubjectsIcon} colors={{ background: green.primary, foreground: green[0] }} />
	)
}

const useStyles = createUseStyles({
	styledSubjectsIcon: {
		display: 'grid'
	}
})