import React, { FC } from 'react'
import { IconProps } from '../props'

export const TutorsIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 17.6" fill={color}>
			<path id="Path_6" data-name="Path 6" d="M7.8,11.757c-.955,0-1.2.343-2.3.343s-1.339-.343-2.3-.343A3.122,3.122,0,0,0,0,14.942v1.283A1.375,1.375,0,0,0,1.375,17.6h8.25A1.375,1.375,0,0,0,11,16.225V14.942a3.126,3.126,0,0,0-3.2-3.185ZM9.35,15.95H1.65V14.942A1.483,1.483,0,0,1,3.2,13.407a16.857,16.857,0,0,0,2.3.343,16.783,16.783,0,0,0,2.3-.343A1.483,1.483,0,0,1,9.35,14.942ZM5.5,11A3.3,3.3,0,1,0,2.2,7.7,3.3,3.3,0,0,0,5.5,11Zm0-4.95A1.65,1.65,0,1,1,3.85,7.7,1.652,1.652,0,0,1,5.5,6.05ZM20.35,0H7.15A1.68,1.68,0,0,0,5.5,1.7V3.3a5.356,5.356,0,0,1,1.65.327V1.65h13.2v11H18.7V11a1.1,1.1,0,0,0-1.1-1.1H13.2A1.1,1.1,0,0,0,12.1,11v3.3h8.25A1.68,1.68,0,0,0,22,12.6V1.7A1.68,1.68,0,0,0,20.35,0Zm-3.3,12.65h-3.3v-1.1h3.3Z" />
		</svg>
	)
}