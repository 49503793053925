import { useContext, useEffect } from "react"
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context"
import { Admin } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"
import firebase from 'firebase'

export const useAdmin = (adminId?: string): { admin?: Admin, adminSnapshot?: firebase.firestore.DocumentSnapshot<Admin> } => {

    // hooks
    const [admin, setAdminDocumentPath, adminSnapshot] = useFirestoreDocument<Admin>(adminId && `admins/${adminId}`)

    // side effects
    useEffect(() => {
        setAdminDocumentPath(adminId && `admins/${adminId}`)
    }, [setAdminDocumentPath, adminId])

    return {
        admin,
        adminSnapshot,
    }

}