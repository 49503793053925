import { red } from '@ant-design/colors'
import { message } from 'antd'
import React, { FC, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { deleteSubject } from '../../../common/logic/subject-logic'
import { Subject } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'

type DeleteSubjectDialogProps = {
	subject: RecordWithID<Subject>
} & DialogProps

export const DeleteSubjectDialog: FC<DeleteSubjectDialogProps> = ({
	subject,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'DeleteSubjectDialog' })
		}
	}, [visible])

	// event handlers
	const handleClick = useCallback(() => {
		deleteSubject(subject.id).then(() => {
			message.success("Successfully deleted subject")
		})
	}, [subject.id])

	return (
		<Dialog visible={visible} onClose={onClose}>
			<DialogHeader title="Are you sure?" />
			<DialogBody>
				Are you sure you want to delete this subject?
				<br />
				<br />
				All of the registrations that have ever been scheduled for this subject will be deleted and any students / tutors associated with it will be notified.
				<br />
				<br />
				This cannot be undone.
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={onClose}>
					Go Back
				</DialogFooterButton>
				<DialogFooterButton color={red.primary} textColor="white" onClick={handleClick}>
					Delete Subject
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	deleteSubjectDialog: {
		display: 'grid'
	}
})