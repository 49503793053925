import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useRegistration } from '../../../../../common/hooks/database/use-registration'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'
import { RegistrationListItem } from '../../../../registrations/registration-list-item/RegistrationListItem'
import { RegisterForASessionDialogContext } from '../../register-for-a-session-dialog-context'

type SuccessProps = {}

export const Success: FC<SuccessProps> = () => {

	// hooks
	const styles = useStyles()
	const { onClose } = useContext(RegisterForASessionDialogContext)
	const { registrationId } = useContext(RegisterForASessionDialogContext)
	const registration = useRegistration(registrationId)

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	return (
		<>
			<DialogHeader title="Registration Successful" />
			<DialogBody>
				<div>
					Your registration was successful. Here are the details of your registration:
				</div>
				{registration && (
					<RegistrationListItem registration={registration} />
				)}
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
					Return to Dashboard
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={!registration} />
		</>
	)
}

const useStyles = createUseStyles({
	success: {
		display: 'grid'
	}
})