import { useEffect, useMemo } from "react"
import { Registration, Tutor, TutorAvailability } from "../../types/types"
import { RecordWithID } from "../../types/utils"
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data"
import { useFirestoreQuery } from "../firebase/firebase"
import { FirebaseQueryData, FirebaseQueryFiltersAndSorts } from "../firebase/firebase-types"

export const useNotAvailableTutors = (weekStartDate?: number, subjectId?: string): RecordWithID<Tutor>[] | undefined => {

    // hooks
    const tutorQueryParams: FirebaseQueryFiltersAndSorts<Tutor> | undefined = useMemo(() => {
        if (!subjectId) { return undefined }
        return {
            filters: [['SubjectIDs', 'array-contains', subjectId]],
            sorts: [['displayName', 'asc']],
        }
    }, [subjectId])
    const tutorAvailabilityQueryParams: FirebaseQueryFiltersAndSorts<TutorAvailability> | undefined = useMemo(() => {
        if (!weekStartDate) { return undefined }
        return {
            filters: [['registrationWeekStartDate', '==', weekStartDate], ['availableRegistrations', '>', 0]],
        }
    }, [weekStartDate])
    const registrationsQueryParams: FirebaseQueryFiltersAndSorts<Registration> | undefined = useMemo(() => {
        if (!weekStartDate) { return undefined }
        return {
            filters: [['registrationWeekStartDate', '==', weekStartDate]]
        }
    }, [weekStartDate])
    const [tutorIds, tutorsData, setTutorsQueryParams] = useFirestoreQuery<Tutor>('tutors', tutorQueryParams)
    const [tutorAvailabilityIds, tutorAvailabilitiesData, setTutorAvailabilitiesQueryParams] = useFirestoreQuery<TutorAvailability>('tutorAvailabilities', tutorAvailabilityQueryParams)
    const [registrationIds, registrationsData, setRegistrationQueryParams] = useFirestoreQuery<Registration>('registrations', registrationsQueryParams)

    const tutors = useMemo(() => combineIdsAndFirebaseQueryData(tutorIds, tutorsData), [tutorIds, tutorsData])
    const tutorAvailabilities = useMemo(() => combineIdsAndFirebaseQueryData(tutorAvailabilityIds, tutorAvailabilitiesData), [tutorAvailabilityIds, tutorAvailabilitiesData])
    const registrations = useMemo(() => combineIdsAndFirebaseQueryData(registrationIds, registrationsData), [registrationIds, registrationsData])

    const notAvailableTutors = useMemo(() => {
        if (!tutors || !tutorAvailabilities || !registrations) { return undefined }
        const filteredAvailabilities = tutorAvailabilities.filter(availability => {
            const registrationsCount = registrations.filter(registration => (registration.TutorID === availability.TutorID)).length
            const netRegistrationCount = availability.availableRegistrations - registrationsCount
            return netRegistrationCount > 0
        })
        const availableTutors = filteredAvailabilities
            .map(availabilitiy => {
                return tutors.find(tutor => tutor.id === availabilitiy.TutorID) as RecordWithID<Tutor>
            })
            .sort((a, b) => tutors.indexOf(a) - tutors.indexOf(b))
        const notAvailableTutors = tutors.filter(tutor => !availableTutors.includes(tutor))
        return notAvailableTutors
    }, [tutors, tutorAvailabilities, registrations])

    // side effects
    useEffect(() => {
        setTutorsQueryParams(tutorQueryParams)
    }, [tutorQueryParams])

    useEffect(() => {
        setTutorAvailabilitiesQueryParams(tutorAvailabilityQueryParams)
    }, [tutorAvailabilityQueryParams])

    useEffect(() => {
        setRegistrationQueryParams(registrationsQueryParams)
    }, [registrationsQueryParams])

    return notAvailableTutors

}