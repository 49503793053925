import React, { FC, useState, ElementType, useEffect, CSSProperties, MouseEvent, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { blue } from "@ant-design/colors";
import { IconProps } from '../../../icons/props';
import { Colors } from '../../../../common/constants/colors';

export type SelectTileOption = {
    id: string
    title: string
    subtitle: string
    icon: ElementType<IconProps>
}

type SelectTileProps = {
    selectedOptionId?: string
    option: SelectTileOption
    onSelect?: (id: string) => void
}

export const SelectTile: FC<SelectTileProps> = ({
    selectedOptionId,
    option: { id, title, subtitle, icon: Icon },
    onSelect,
}) => {

    // hooks
    const styles = useStyles()
    const [selected, setSelected] = useState<boolean>(selectedOptionId === id)

    // side effects
    useEffect(() => {
        setSelected(selectedOptionId === id)
    }, [selectedOptionId, id])

    // event handlers
    const handleClick = useCallback(() => {
        onSelect && onSelect(id)
    }, [onSelect, id])

    return (
        <div className={`${styles.selectTile} ${selected && styles.selectTileSelected}`} onClick={handleClick}>
            <Icon size={36} color={selected ? 'white' : Colors.graphite} />
            <h1>{title}</h1>
            <span>{subtitle}</span>
            <div className={`${styles.selectedIndicator} ${selected && styles.selectedIndicatorSelected}`} />
        </div>
    )
}

const useStyles = createUseStyles({
    selectTile: {
        display: 'grid',
        border: '2px solid rgb(240, 240, 240)',
        alignContent: 'center',
        justifyItems: 'center',
        textAlign: 'center',
        position: 'relative',
        borderRadius: 6,
        padding: 24,
        gap: 12,
        cursor: 'pointer',
        '&:hover': {
            borderColor: blue.primary,
        }
    },
    selectTileSelected: {
        backgroundColor: blue.primary,
        color: 'white',
        '& h1': {
            color: 'white',
        }
    },
    selectedIndicator: {
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        border: '2px solid transparent',
    },
    selectedIndicatorSelected: {
        border: `2px solid ${blue.primary}`
    },
})