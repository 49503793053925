import React, { FC } from 'react'
import { IconProps } from '../props'

export const StudentsIcon: FC<IconProps> = ({
	size,
	color,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 17.6" fill={color}>
			<path id="Path_9" data-name="Path 9" d="M8.8,9.9A2.2,2.2,0,1,0,11,7.7,2.2,2.2,0,0,0,8.8,9.9Zm7.7,0a2.2,2.2,0,1,0,2.2-2.2A2.2,2.2,0,0,0,16.5,9.9ZM3.3,12.1A2.2,2.2,0,1,0,1.1,9.9,2.2,2.2,0,0,0,3.3,12.1Zm16.5,1.1H17.6a2.2,2.2,0,0,0-2.2,2.2v1.1a1.1,1.1,0,0,0,1.1,1.1h4.4A1.1,1.1,0,0,0,22,16.5V15.4A2.2,2.2,0,0,0,19.8,13.2Zm-7.7,0H9.9a2.2,2.2,0,0,0-2.2,2.2v1.1a1.1,1.1,0,0,0,1.1,1.1h4.4a1.1,1.1,0,0,0,1.1-1.1V15.4A2.2,2.2,0,0,0,12.1,13.2Zm-7.7,0H2.2A2.2,2.2,0,0,0,0,15.4v1.1a1.1,1.1,0,0,0,1.1,1.1H5.5a1.1,1.1,0,0,0,1.1-1.1V15.4A2.2,2.2,0,0,0,4.4,13.2ZM3.3,2.2H18.7V6.6a3.275,3.275,0,0,1,2.2.858V1.7A1.68,1.68,0,0,0,19.25,0H2.75A1.68,1.68,0,0,0,1.1,1.7V7.458A3.275,3.275,0,0,1,3.3,6.6Z" />
		</svg>
	)
}