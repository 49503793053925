import moment from "moment-timezone";

export const getNow = (): moment.Moment => {
    return moment.tz('America/New_York').subtract(1, 'day')
}

export const getDate = (timestamp: number | moment.Moment): moment.Moment => {
    if (typeof timestamp === 'number') {
        return moment.unix(timestamp).tz('America/New_York')
    } else {
        return moment(timestamp).tz('America/New_York')
    }
}