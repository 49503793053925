import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { FirebaseContext } from "../../firebase-provider/firebase-context";
import { SignInWithGoogleButton } from "../sign-in-with-google-button/SignInWithGoogleButton";

type AuthenticateProps = {}

export const Authenticate: FC<AuthenticateProps> = () => {

    // state
    const styles = useStyles()
    const history = useHistory()
    const { user } = useContext(FirebaseContext)

    // side effects
    useEffect(() => {
        if (user) {
            history.push('/')
        }
    }, [user])

    return (
        <div className={styles.authenticate}>
            <div className={styles.form}>
                <h1>Welcome</h1>
                <h3>Sign in with your Google account to get started</h3>
                <div style={{ marginTop: 24 }}>
                    <div className={styles.formRow}>
                        <SignInWithGoogleButton />
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    authenticate: {
        display: 'grid',
        justifyContent: 'center',
        paddingTop: '10vh',
    },
    form: {
        marginTop: 32,
        marginBottom: 32,
        minWidth: 350,
        justifySelf: 'center',
        textAlign: 'center',
        gap: 18,
        display: 'grid',
    },
    formRow: {
        display: 'grid',
        justifyContent: 'center',
        marginBottom: 10,
    }
})