import React, { FC } from 'react'
import { IconProps } from '../props'

export const AdminsIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 17.6" fill={color}>
			<path id="Path_33" data-name="Path 33" d="M21.392,9.319l-3.96-1.547a1.065,1.065,0,0,0-.763,0l-3.96,1.547a.93.93,0,0,0-.608.856,7.815,7.815,0,0,0,4.568,7.353,1.066,1.066,0,0,0,.763,0A7.789,7.789,0,0,0,22,10.175.93.93,0,0,0,21.392,9.319ZM17.05,15.9v-6.5l3.283,1.282a6.149,6.149,0,0,1-3.283,5.218ZM7.7,8.8A4.4,4.4,0,1,0,3.3,4.4,4.4,4.4,0,0,0,7.7,8.8ZM11,10.175a1.952,1.952,0,0,1,.038-.248c-.086,0-.168-.027-.258-.027h-.574a5.984,5.984,0,0,1-5.012,0H4.62A4.621,4.621,0,0,0,0,14.52v1.43A1.65,1.65,0,0,0,1.65,17.6h12.1a1.692,1.692,0,0,0,.66-.138A9.236,9.236,0,0,1,11,10.175Z" />
		</svg>
	)
}