import { blue } from '@ant-design/colors'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../common/hooks/database/use-initials'

type LabeledUserProps = {
	user: { displayName: string }
}

export const LabeledUser: FC<LabeledUserProps> = ({
	user,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(user)

	return (
		<div className={styles.labeledUser}>
			<Avatar size={24} style={{ backgroundColor: blue.primary }}>{initials}</Avatar>
			{user.displayName}
		</div>
	)
}

const useStyles = createUseStyles({
	labeledUser: {
		display: 'grid',
		gridTemplateColumns: 'max-content max-content',
		gap: 8,
		alignItems: 'center',
	}
})