import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { createHandleInputTextChange } from '../../../common/utils/other/create-handle-input-text-change'
import { Button } from '../../button/Button'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBodywithSearch } from '../../dialog/subcomponents/dialog-body-with-search/DialogBodywithSearch'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { AdminRegistrationsbyWeek } from '../../registrations/admin-registrations-by-week/AdminRegistrationsbyWeek'
import { AdminCreateRegistrationDialog } from '../admin-create-registration-dialog/AdminCreateRegistrationDialog'

type AdminManageRegistrationsDialogProps = {} & DialogProps

export const AdminManageRegistrationsDialog: FC<AdminManageRegistrationsDialogProps> = ({
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [showCreateRegistrationDialog, setShowCreateRegistrationDialog] = useState<boolean>(false)

	// side effects
	useEffect(() => {
		if (visible) {
			logAnalyticsEvent('screen_view', { screen: 'AdminManageRegistrationsDialog' })
		}
	}, [visible])

	// event handlers
	const handleReturnToDashboardButtonClick = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleSearchQueryChange = useCallback(createHandleInputTextChange(setSearchQuery), [setSearchQuery])

	const handleCreateNewClick = useCallback(() => {
		setShowCreateRegistrationDialog(true)
	}, [setShowCreateRegistrationDialog])

	const handleCreateRegistrationDialogClose = useCallback(() => {
		setShowCreateRegistrationDialog(false)
	}, [setShowCreateRegistrationDialog])

	return (
		<>
			<Dialog visible={visible} onClose={onClose}>
				<DialogHeader onClose={onClose} title="Manage Registrations" />
				<DialogBodywithSearch
					searchInputProps={{
						onChange: handleSearchQueryChange,
						value: searchQuery,
						placeholder: 'Search Registrations'
					}}
					actions={(
						<Button color={blue.primary} textColor="white" onClick={handleCreateNewClick}>
							Create New
						</Button>
					)}
					compactView={true}
				>
					<AdminRegistrationsbyWeek searchQuery={searchQuery} compactView={true} />
				</DialogBodywithSearch>
				<DialogFooter>
					<DialogFooterButton textColor="white" color={blue.primary} onClick={handleReturnToDashboardButtonClick}>
						Return to Dashboard
					</DialogFooterButton>
				</DialogFooter>
			</Dialog>
			<AdminCreateRegistrationDialog visible={showCreateRegistrationDialog} onClose={handleCreateRegistrationDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	adminManageRegistrationsDialog: {
		display: 'grid'
	}
})