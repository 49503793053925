import { Tutor } from "../types/types";
import firebase from 'firebase';
import { sanitizeObjectForFirebase } from "../utils/other/sanitize-object-for-firebase";
import { logAnalyticsEvent } from "./analytics";

export const createTutor = async (tutor: Tutor) => {

    logAnalyticsEvent('action', { type: 'createTutor', payload: { tutor } })

    const auth = firebase.auth()
    const db = firebase.firestore()

    const userId = auth.currentUser?.uid
    if (!userId) { throw new Error('Couldn\'t find a user id for the logged in user') }

    await db.doc(`tutors/${userId}`).set(sanitizeObjectForFirebase(tutor))
}

export const updateTutor = async (tutorId: string, update: ((tutor: Tutor) => Tutor)) => {

    const db = firebase.firestore()

    await db.runTransaction(async transaction => {
        const ref = db.doc(`tutors/${tutorId}`) as firebase.firestore.DocumentReference<Tutor>
        const tutor = await transaction.get(ref)
        const data = tutor.data()
        if (!data) {
            throw new Error('Tutor data was undefined')
        }
        const updatePayload = update(data)
        logAnalyticsEvent('action', { type: 'updateTutor', payload: { tutorId, updatePayload } })
        transaction.update(ref, sanitizeObjectForFirebase(updatePayload))
    })

}