import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { AdminsIcon } from '../../icons/admins-icon/AdminsIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledAdminIconProps = {}

export const StyledAdminIcon: FC<StyledAdminIconProps> = () => {
	return (
		<StyledIcon
			icon={AdminsIcon}
			colors={{
				background: Colors.gray,
				foreground: 'white',
			}}
		/>
	)
}