import React, { FC, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Avatar } from "antd";
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile';
import { blue } from '@ant-design/colors';
import { Shadows } from '../../../../common/constants/shadows';
import { signOut } from '../../../../common/logic/authentication-logic';
import { useIntials } from '../../../../common/hooks/database/use-initials';

type UserIdentifierProps = {}

export const UserIdentifier: FC<UserIdentifierProps> = () => {

	// hooks
	const styles = useStyles()
	const { userProfile, isAdmin, isTutor, isStudent } = useCurrentUserProfile()
	const initials = useIntials(userProfile)
	const userType = useMemo(() => {
		const names = []
		let joiner = ' '
		isAdmin && names.push('Admin')
		isTutor && names.push('Tutor')
		isStudent && names.push('Student')
		if (names.length > 1) {
			names[names.length - 1] = `and ${names[names.length - 1]}`
		}
		if (names.length > 2) {
			joiner = ', '
		}
		return names.join(joiner)
	}, [isAdmin, isTutor, isStudent])

	// event handlers
	const handleLogoutClick = useCallback(() => {
		signOut()
	}, [])

	return (
		<div className={styles.userIdentifier}>
			<Avatar size={84} className={styles.avatar}>
				{initials}
			</Avatar>
			<h3>{userProfile?.displayName}</h3>
			<span>{userType} (<a onClick={handleLogoutClick}>Logout</a>)</span>
		</div>
	)
}

const useStyles = createUseStyles({
	userIdentifier: {
		position: 'absolute',
		width: '100%',
		top: 'calc(100% - 84px)',
		display: 'grid',
		justifyItems: 'center',
		'& h3': {
			marginBottom: 3,
		}
	},
	avatar: {
		boxShadow: Shadows['4dp'],
		backgroundColor: blue.primary,
		marginBottom: 8,
	}
})