import React, { FC, useCallback, useContext, useState } from 'react'
import { ManageTutorInformationDialog } from '../../../dialogs/manage-tutor-information-dialog/ManageTutorInformationDialog'
import { FirebaseContext } from '../../../firebase-provider/firebase-context'
import { ListItem } from '../../../list-item/ListItem'
import { StyledTutorsIcon } from '../../../styled-icons/styled-tutors-icon/StyledTutorsIcon'
import { StyledUserIcon } from '../../../styled-icons/styled-user-icon/StyledUserIcon'

type ManageTutorInformationActionProps = {}

export const ManageTutorInformationAction: FC<ManageTutorInformationActionProps> = () => {

	// hooks
	const [showDialog, setShowDialog] = useState<boolean>(false)
	const { userId } = useContext(FirebaseContext)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [])

	if (!userId) {
		return null
	}

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledUserIcon />
				<h3>Update My Information</h3>
			</ListItem>
			<ManageTutorInformationDialog visible={showDialog} onClose={handleDialogClose} tutorId={userId} />
		</>
	)
}