import { useMemo } from "react";
import { Tutor } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { useFirestoreQuery } from "../firebase/firebase";

export const useTutors = (): RecordWithID<Tutor>[] | undefined => {

    // hooks
    const [tutorIds, tutorsData] = useFirestoreQuery<Tutor>('tutors', { filters: [], sorts: [['displayName', 'asc']] })
    const tutors = useMemo(() => combineIdsAndFirebaseQueryData(tutorIds, tutorsData), [tutorIds, tutorsData])

    return tutors

}