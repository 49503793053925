import React, { FC, useCallback } from 'react'
import { Student } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { EmailIcon } from '../../icons/email-icon/EmailIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type EmailStudentOverflowItemProps = {
	student: RecordWithID<Student>
	onClose?: () => void
}

export const EmailStudentOverflowItem: FC<EmailStudentOverflowItemProps> = ({
	student,
	onClose,
}) => {

	// event handlers
	const handleClick = useCallback(() => {
		window.open(`mailto:${student.emailAddress}`)
		onClose && onClose()
	}, [student])

	return (
		<OverflowMenuItem icon={EmailIcon} onClick={handleClick}>
			Email Student
		</OverflowMenuItem>
	)
}