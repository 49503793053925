import { useContext, useEffect } from "react"
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context"
import { Tutor } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"
import firebase from 'firebase'
import { RecordWithID } from "../../types/utils"

export const useTutor = (tutorId?: string): { tutor?: RecordWithID<Tutor>, tutorSnapshot?: firebase.firestore.DocumentSnapshot<Tutor> } => {

    // hooks
    const [tutor, setTutorDocumentPath, tutorSnapshot] = useFirestoreDocument<Tutor>(tutorId && `tutors/${tutorId}`)

    // side effects
    useEffect(() => {
        setTutorDocumentPath(tutorId && `tutors/${tutorId}`)
    }, [setTutorDocumentPath, tutorId])

    return {
        tutor: tutor && tutorId ? { ...tutor, id: tutorId } : undefined,
        tutorSnapshot: tutorSnapshot,
    }

}