import { blue } from '@ant-design/colors'
import { MenuItem } from '@blueprintjs/core'
import { Avatar } from 'antd'
import { isEqual } from 'lodash'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { HighlightText } from '../../highlight-text/HighlightText'
import { MultiSelect } from '../../multi-select/MultiSelect'

type TutorMultiSelectProps = {
	items: RecordWithID<Tutor>[]
	value?: RecordWithID<Tutor>[]
	onChange?: (item?: RecordWithID<Tutor>[]) => any
	onBlur?: () => any
	placeholder?: string
	disabled?: boolean
}

export const TutorMultiSelect: FC<TutorMultiSelectProps> = ({
	value,
	onChange,
	...multiSelectProps
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')

	// event handlers
	const handleActiveItemChange = useCallback((item: RecordWithID<Tutor> | null) => {
		if (item !== null) {
			let newValues: RecordWithID<Tutor>[] = []
			if (value) {
				newValues.push(...value)
			}
			if (newValues.indexOf(item as any) > -1) {
				newValues = newValues.filter(val => !isEqual(val, item))
			} else {
				newValues.push(item)
			}
			onChange && onChange(newValues)
		}
	}, [onChange, value])

	const itemRenderer = useCallback((tutor?: RecordWithID<Tutor>) => {
		if (tutor) {
			const initals = tutor.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
			const isSelected = value && value.find(val => val.id === tutor.id) ? true : false
			return (
				<MenuItem
					text={(
						<div className={styles.menuItemContentContainer} style={{ marginLeft: 2, }}>
							<Avatar size={22} style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
							<HighlightText text={tutor.displayName} query={searchQuery} />
						</div>
					)}
					onClick={() => handleActiveItemChange(tutor)}
					icon={isSelected ? 'tick' : 'blank'}
				/>
			)
		} else {
			return null
		}
	}, [handleActiveItemChange, value, styles.menuItemContentContainer, searchQuery])

	const tagRenderer = useCallback((tutor?: RecordWithID<Tutor>) => {
		if (tutor) {
			const initals = tutor.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
			return (
				<div className={styles.menuItemContentContainer}>
					<Avatar size={24} style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
					{tutor.displayName}
				</div>
			)
		} else {
			return null
		}
	}, [styles.menuItemContentContainer])

	return (
		<MultiSelect
			{...multiSelectProps}
			itemRenderer={itemRenderer}
			tagRenderer={tagRenderer}
			value={value}
			onChange={onChange}
			onQueryChange={setSearchQuery}
			noResultsText="No tutors found for this subject"
			options={{
				nameKey: 'id',
				idKey: 'id',
				searchableFields: ['displayName'],
			}}
		/>
	)
}

const useStyles = createUseStyles({
	tutorMultiSelect: {
		display: 'grid'
	},
	menuItemContentContainer: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		gap: 8,
		alignItems: 'center',
	},
})