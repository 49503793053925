import { Registration } from "../../types/types";
import { RecordWithID } from "../../types/utils";

type RegistrationsByWeek = { [key: number]: RecordWithID<Registration>[] }

export const chunkRegistrationsByWeek = (registrations?: RecordWithID<Registration>[]): RegistrationsByWeek | undefined => {

    if (!registrations) { return undefined }

    const dates: number[] = []

    registrations.forEach(registration => {
        const { registrationWeekStartDate } = registration
        if (!dates.includes(registrationWeekStartDate)) {
            dates.push(registrationWeekStartDate)
        }
    })

    dates.sort()

    const registrationsByWeek: RegistrationsByWeek = {}

    dates.forEach(date => {
        registrationsByWeek[date] = registrations.filter(registration => registration.registrationWeekStartDate === date)
    })

    return registrationsByWeek
    
}