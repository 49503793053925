import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type WithLabelProps = {
	label: string
}

export const WithLabel: FC<WithLabelProps> = ({
	label,
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.withLabel}>
			<span className={styles.label}>{label}</span>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	withLabel: {
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: 4,
		justifyContent: 'start',
	},
	label: {
		fontSize: 14,
		fontWeight: 400,
	}
})