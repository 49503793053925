import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { AdminManageAvailabilityDialog } from '../../../dialogs/admin-manage-availability-dialog/AdminManageAvailabilityDialog'
import { ListItem } from '../../../list-item/ListItem'
import { StyledAvailabilityIcon } from '../../../styled-icons/styled-availability-icon/StyledAvailabilityIcon'

type ManageAvailabilityActionProps = {}

export const ManageAvailabilityAction: FC<ManageAvailabilityActionProps> = () => {

	// hooks
	const styles = useStyles()
	const [showDialog, setShowDialog] = useState<boolean>(false)

	// event handlers
	const handleListItemClick = useCallback(() => {
		setShowDialog(true)
	}, [setShowDialog])

	const handleDialogClose = useCallback(() => {
		setShowDialog(false)
	}, [setShowDialog])

	return (
		<>
			<ListItem onClick={handleListItemClick}>
				<StyledAvailabilityIcon />
				<h3>Manage Availability</h3>
			</ListItem>
			<AdminManageAvailabilityDialog visible={showDialog} onClose={handleDialogClose} />
		</>
	)
}

const useStyles = createUseStyles({
	manageAvailabilityAction: {
		display: 'grid'
	}
})