import React, { FC, ReactNode, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/constants/colors'

type ListItemCardProps = {
	styledIcon: ReactNode
	title: string
	actions?: ReactNode
	compactView?: boolean
}

export const ListItemCard: FC<ListItemCardProps> = ({
	styledIcon,
	title,
	actions,
	children,
	compactView,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={`${styles.listItemCard} ${compactView && styles.compactView}`}>
			<div className={`${styles.titleRow} ${compactView && styles.compactViewContent}`}>
				{styledIcon}
				<h3>{title}</h3>
				{actions}
			</div>
			<div className={`${styles.cardBody} ${compactView && styles.compactViewContent}`}>
				{children}
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	listItemCard: {
		display: 'grid',
		border: `1px solid ${Colors.border}`,
		borderRadius: 8,
		position: 'relative',
	},
	titleRow: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		gridAutoColumns: 'max-content',
		gridAutoFlow: 'column',
		alignItems: 'center',
		gap: 12,
		borderBottom: `1px solid ${Colors.border}`,
		padding: 10,
	},
	cardBody: {
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: 12,
		fontWeight: 500,
		fontSize: 16,
		padding: 18,
	},
	compactView: {
		borderRadius: 0,
		border: 'none',
		borderBottom: `1px solid ${Colors.border}`,
		paddingLeft: 12,
		paddingRight: 12,
	},
	compactViewContent: {
		paddingLeft: 12,
		paddingRight: 12,
	},
})