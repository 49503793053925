import { blue, red } from '@ant-design/colors'
import { Tag } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { useUserIsDisabled } from '../../../common/hooks/database/use-user-is-disabled'
import { Student } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { DisableAccountOverflowMenuItem } from '../../admin/disable-account-overflow-menu-item/DisableAccountOverflowMenuItem'
import { ListItem } from '../../list-item/ListItem'
import { OverflowMenuButton } from '../../overflow-menu/subcomponents/overflow-menu-button/OverflowMenuButton'
import { EmailStudentOverflowItem } from '../email-student-overflow-item/EmailStudentOverflowItem'
import { UpdateStudentInformationOverflowMenuItem } from '../update-student-information-overflow-menu-item/UpdateStudentInformationOverflowMenuItem'

type StudentListItemProps = {
	student: RecordWithID<Student>
	compactView?: boolean
}

export const StudentListItem: FC<StudentListItemProps> = ({
	student,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(student)
	const userIsDisabled = useUserIsDisabled(student.id)
	const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false)

	// event handlers
	const handleOverflowMenuClose = useCallback(() => {
		setOverflowMenuOpen(false)
	}, [setOverflowMenuOpen])

	return (
		<ListItem compactView={compactView}>
			<Avatar style={{ backgroundColor: blue.primary }} size={32}>
				{initials}
			</Avatar>
			<h3>{student.displayName}</h3>
			{userIsDisabled && (
				<Tag color={red.primary}>Disabled</Tag>
			)}
			<OverflowMenuButton visible={overflowMenuOpen} onMenuOpenChange={setOverflowMenuOpen}>
			<UpdateStudentInformationOverflowMenuItem studentId={student.id} onClose={handleOverflowMenuClose} />
				<EmailStudentOverflowItem student={student} onClose={handleOverflowMenuClose} />
				<DisableAccountOverflowMenuItem userId={student.id} onClose={handleOverflowMenuClose} />
			</OverflowMenuButton>
		</ListItem>
	)
}

const useStyles = createUseStyles({
	studentListItem: {
		display: 'grid'
	}
})