export type Grade = {
	id: string
	label: string
}
export const grades: Grade[] = [
	{
		id: 'k',
		label: 'Kindegarten'
	},
	{
		id: '1',
		label: '1st'
	},
	{
		id: '2',
		label: '2nd'
	},
	{
		id: '3',
		label: '3rd'
	},
	{
		id: '4',
		label: '4th'
	},
	{
		id: '5',
		label: '5th'
	},
	{
		id: '6',
		label: '6th'
	},
	{
		id: '7',
		label: '7th'
	},
	{
		id: '8',
		label: '8th'
	},
	{
		id: '9',
		label: '9th'
	},
	{
		id: '10',
		label: '10th'
	},
	{
		id: '11',
		label: '11th'
	},
	{
		id: '12',
		label: '12th'
	},
]