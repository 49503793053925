import React, { FC } from 'react'
import { IconProps } from '../props'

export const EnableUserIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 640 512" fill={color}>
			<path id="Path_35" data-name="Path 35" d="M224,256A128,128,0,1,0,96,128,127.99,127.99,0,0,0,224,256Zm89.6,32H296.9a174.075,174.075,0,0,1-145.8,0H134.4A134.435,134.435,0,0,0,0,422.4V464a48.012,48.012,0,0,0,48,48H400a48.012,48.012,0,0,0,48-48V422.4A134.435,134.435,0,0,0,313.6,288Zm323-128.4-27.8-28.1a11.86,11.86,0,0,0-16.8-.1l-104.8,104-45.5-45.8a11.86,11.86,0,0,0-16.8-.1l-28.1,27.9a11.86,11.86,0,0,0-.1,16.8l81.7,82.3a11.86,11.86,0,0,0,16.8.1L636.5,176.4A11.952,11.952,0,0,0,636.6,159.6Z" />
		</svg>
	)
}