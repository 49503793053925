import { useEffect, useMemo } from "react"
import { Registration } from "../../types/types"
import { useFirestoreQuery } from "../firebase/firebase"
import { FirebaseQueryFiltersAndSorts } from "../firebase/firebase-types"

export const useStudentHasRegistration = (studentId?: string, weekStartDate?: number): boolean | undefined => {

    // hooks
    const registrationQueryParams: FirebaseQueryFiltersAndSorts<Registration> | undefined = useMemo(() => {
        if (!weekStartDate || !studentId) {
            return undefined
        }
        return {
            filters: [
                ['StudentID', '==', studentId],
                ['registrationWeekStartDate', '==', weekStartDate],
            ]
        }
    }, [weekStartDate, studentId])
    const [registrationIds, _a, setRegistrationQueryParams] = useFirestoreQuery<Registration>(`registrations`, registrationQueryParams)
    const studentHasRegistration: boolean | undefined = useMemo(() => {
        if (!registrationIds) { return undefined }
        return registrationIds.length > 0
    }, [registrationIds])

    // side effects
    useEffect(() => {
        setRegistrationQueryParams(registrationQueryParams)
    }, [registrationQueryParams])

    return studentHasRegistration

}