import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Subject } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { useFirestoreDocuments, useFirestoreQuery } from "../firebase/firebase";
import { FirebaseQueryData } from "../firebase/firebase-types";

export const useSubjects = (): RecordWithID<Subject>[] | undefined => {

    // hooks
    const [subjectIds, subjectsData] = useFirestoreQuery<Subject>('subjects', { filters: [], sorts: [['name', 'asc']] })
    const subjects = useMemo(() => combineIdsAndFirebaseQueryData(subjectIds, subjectsData), [subjectIds, subjectsData])

    return subjects

}

export const useSubjectsForIds = (subjectIds: string[]): RecordWithID<Subject>[] | undefined => {

    // hooks
    const [data, setDocumentIds] = useFirestoreDocuments<Subject>('subjects', subjectIds)
    const [statefulSubjectIds, setStatefulSubjectIds] = useState<string[]>(subjectIds)
    const subjects = subjectIds && data ? combineIdsAndFirebaseQueryData(subjectIds, data) : undefined

    // side effects
    useEffect(() => {
        setStatefulSubjectIds(oldSubjectIds => {
            if (!isEqual(oldSubjectIds, subjectIds)) {
                return subjectIds
            }
            return oldSubjectIds
        })
    }, [subjectIds])

    useEffect(() => {
        setDocumentIds(statefulSubjectIds)
    }, [statefulSubjectIds])

    return subjects

}