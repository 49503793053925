import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/constants/colors'
import { useAvailableTutors } from '../../../common/hooks/database/use-available-tutors'
import { useSubjectsForIds } from '../../../common/hooks/database/use-subjects'
import { useTutors } from '../../../common/hooks/database/use-tutors'
import { updateRegistration } from '../../../common/logic/registration-logic'
import { Registration, Subject, Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { Dialog, DialogProps } from '../../dialog/Dialog'
import { DialogBody } from '../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../form-row/FormRow'
import { LoadingOverlay } from '../../loading-overlay/LoadingOverlay'
import { Select } from '../../select/Select'
import { TutorOption, TutorSelect } from '../../tutors/tutor-select/TutorSelect'

type ManuallyPairRegistrationDialogProps = {
	registration: RecordWithID<Registration>
} & DialogProps

export const ManuallyPairRegistrationDialog: FC<ManuallyPairRegistrationDialogProps> = ({
	registration,
	visible,
	onClose,
}) => {

	// hooks
	const styles = useStyles()
	const { control, formState: { isValid }, reset, getValues, watch } = useForm({ mode: 'all' })
	const handleClose = useCallback(() => {
		onClose && onClose()
		reset()
	}, [onClose, reset])
	const subjectIds = registration.registrationPreferences ? registration.registrationPreferences.filter(preference => preference !== null).map(preference => preference.SubjectID) : registration.SubjectID ? [registration.SubjectID] : []
	const subjects = useSubjectsForIds(subjectIds)
	const selectedSubject = watch('subject')
	const availableTutors = useAvailableTutors(registration.registrationWeekStartDate, selectedSubject?.id)
	const tutorMenuItems: TutorOption[] | undefined = useMemo(() => {
		if (!availableTutors)  { return undefined }
		const menuItems: TutorOption[] = []
		availableTutors?.forEach(tutor => tutor && (menuItems.push({ id: tutor.id, type: 'tutor', data: tutor })))
		return menuItems
	}, [availableTutors])

	// event handlers
	const handleSubmit = useCallback(() => {
		const {
			tutor,
			subject,
		} = getValues() as {
			tutor?: TutorOption,
			subject?: RecordWithID<Subject>
		}
		updateRegistration(registration.id, {
			TutorID: tutor?.id,
			SubjectID: subject?.id,
		}).then(() => {
			handleClose()
		})
	}, [])

	if (!subjects) {
		return (
			<Dialog visible={visible} onClose={handleClose}>
				<DialogHeader title="Choose a Tutor to Pair" onClose={handleClose} />
				<DialogBody>
					<div style={{ height: 300 }} />
				</DialogBody>
				<LoadingOverlay loading={true} />
			</Dialog>
		)
	}

	return (
		<Dialog visible={visible} onClose={handleClose}>
			<DialogHeader title="Manually Pair Registration" onClose={handleClose} />
			<DialogBody>
				<FormRow label="Choose a subject to pair the student for">
					<Controller
						name="subject"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								options={{
									nameKey: 'name',
									idKey: 'id',
									searchableFields: ['name']
								}} {...field}
								items={subjects}
								placeholder="Choose a Subject..."
							/>
						)}
					/>
				</FormRow>
				<FormRow label="Choose a tutor to pair the student with" disabled={!selectedSubject}>
					<Controller
						name="tutor"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<TutorSelect
								{...field}
								disabled={!selectedSubject}
								items={tutorMenuItems ? tutorMenuItems : []}
								placeholder="Choose a Tutor..."
							/>
						)}
					/>
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.lightGray} onClick={handleClose}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!isValid} onClick={handleSubmit}>
					Save
				</DialogFooterButton>
			</DialogFooter>
		</Dialog>
	)
}

const useStyles = createUseStyles({
	manuallyPairRegistrationDialog: {
		display: 'grid'
	}
})