import React, { FC } from 'react'
import { IconProps } from '../props'

export const OverflowMenuIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 5.226 18" fill={color}>
			<path id="Path_19" data-name="Path 19" d="M26.613,14.387A2.613,2.613,0,1,1,24,17,2.611,2.611,0,0,1,26.613,14.387ZM24,10.613A2.613,2.613,0,1,0,26.613,8,2.611,2.611,0,0,0,24,10.613Zm0,12.774a2.613,2.613,0,1,0,2.613-2.613A2.611,2.611,0,0,0,24,23.387Z" transform="translate(-24 -8)" />
		</svg>
	)
}