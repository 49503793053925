import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ListItem } from '../../list-item/ListItem'
import { AvailabilityToggle } from '../availability-toggle/AvailabilityToggle'

type TutorAvailabilityListItemProps = {
	tutor: RecordWithID<Tutor>
	registrationWeek: number
}

export const TutorAvailabilityListItem: FC<TutorAvailabilityListItemProps> = ({
	tutor,
	registrationWeek,
}) => {

	// hooks
	const styles = useStyles()
	const initials = useIntials(tutor)

	return (
		<ListItem>
			<Avatar size={36}>
				{initials}
			</Avatar>
			<h3>{tutor.displayName}</h3>
			<AvailabilityToggle tutorId={tutor.id} weekStartDate={registrationWeek} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	tutorAvailabilityListItem: {
		display: 'grid'
	}
})