import { cyan } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { TutorsIcon } from '../../icons/tutors-icon/TutorsIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledTutorsIconProps = {}

export const StyledTutorsIcon: FC<StyledTutorsIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={TutorsIcon} colors={{ background: cyan.primary, foreground: cyan[0] }} />
	)
}

const useStyles = createUseStyles({
	styledTutorsIcon: {
		display: 'grid'
	}
})