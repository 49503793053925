import React, { FC, useCallback } from 'react'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { EmailIcon } from '../../icons/email-icon/EmailIcon'
import { OverflowMenuItem } from '../../overflow-menu/subcomponents/overflow-menu-item/OverflowMenuItem'

type EmailTutorOverflowMenuItemProps = {
	tutor: RecordWithID<Tutor>
	onClose?: () => void
}

export const EmailTutorOverflowMenuItem: FC<EmailTutorOverflowMenuItemProps> = ({
	tutor,
	onClose,
}) => {

	// event handlers
	const handleClick = useCallback(() => {
		window.open(`mailto:${tutor.emailAddress}`)
		onClose && onClose()
	}, [tutor, onClose])

	return (
		<OverflowMenuItem icon={EmailIcon} onClick={handleClick}>
			Email Tutor
		</OverflowMenuItem>
	)
}