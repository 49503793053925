import React, { FC, useCallback, useContext, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { updateTutor } from '../../../common/logic/tutor-logic'
import { Subject, Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { AddorRemoveButton } from '../../add-or-remove-button/AddorRemoveButton'
import { FirebaseContext } from '../../firebase-provider/firebase-context'
import { ListItem } from '../../list-item/ListItem'
import { StyledSubjectsIcon } from '../../styled-icons/styled-subjects-icon/StyledSubjectsIcon'

type UpdateSubjectListItemProps = {
	subject: RecordWithID<Subject>
	tutor?: RecordWithID<Tutor>
	compactView?: boolean
}

export const UpdateSubjectListItem: FC<UpdateSubjectListItemProps> = ({
	subject,
	tutor,
	compactView,
}) => {

	// hooks
	const styles = useStyles()
	const added: boolean = useMemo(() => (tutor ? tutor.SubjectIDs.includes(subject.id) : false), [tutor, subject.id])

	// event handlers
	const handleAddOrRemove = useCallback((add: boolean) => {
		if (tutor?.id) {
			if (add) {
				updateTutor(tutor.id, t => ({ ...t, SubjectIDs: [...t.SubjectIDs, subject.id] }))
			} else {
				updateTutor(tutor.id, t => {
					return { ...t, SubjectIDs: t.SubjectIDs.filter(id => id !== subject.id) }
				})
			}
		}
	}, [tutor, subject.id])

	if (!tutor) {
		return null
	}

	return (
		<ListItem compactView={compactView}>
			<StyledSubjectsIcon />
			<h3>{subject.name}</h3>
			<AddorRemoveButton value={added} onChange={handleAddOrRemove} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	updateSubjectListItem: {
		display: 'grid'
	}
})