import React, { ComponentType, FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../common/constants/colors'
import { IconProps } from '../../../icons/props'

type OverflowMenuItemProps = {
	icon: ComponentType<IconProps>
	textColor?: string
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const OverflowMenuItem: FC<OverflowMenuItemProps> = ({
	icon: Icon,
	textColor,
	onClick,
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.overflowMenuItem} style={{ color: textColor }} onClick={onClick}>
			<Icon size={24} color={textColor} />
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	overflowMenuItem: {
		display: 'grid',
		gridTemplateColumns: '24px max-content',
		alignItems: 'center',
		gap: 8,
		fontSize: 14,
		paddingLeft: 12,
		paddingRight: 12,
		paddingTop: 6,
		paddingBottom: 6,
		borderRadius: 4,
		userSelect: 'none',
		cursor: 'pointer',
		'&:hover':{
			backgroundColor: Colors.whiteButton.hover,
		},
		'&:active':{
			backgroundColor: Colors.whiteButton.active,
		},
	},
})