import moment from 'moment-timezone'
import { logAnalyticsEvent } from './analytics'
import { getNow } from './date-logic'

export const generateUpcomingWeekMoments = (numberOfWeeks: number): moment.Moment[] => {

    logAnalyticsEvent('action', { type: 'generateUpcomingWeekMoments', payload: { numberOfWeeks } })

    const upcomingWeekMoments: moment.Moment[] = []

    const currentMoment = getNow().startOf('week')
    for (var i = 0; i < numberOfWeeks; i++) {
        upcomingWeekMoments.push(moment(currentMoment))
        currentMoment.add(1, 'week')
    }

    return upcomingWeekMoments

}