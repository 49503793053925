import { useContext, useEffect } from "react"
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context"
import { Admin, UserProfile } from "../../types/types"
import { useFirestoreDocument } from "../firebase/firebase"
import firebase from 'firebase'

export const useUserProfile = (userId?: string): { userProfile?: UserProfile, userProfileSnapshot?: firebase.firestore.DocumentSnapshot<UserProfile> } => {

    // hooks
    const [userProfile, setUserProfileDocumentPath, userProfileSnapshot] = useFirestoreDocument<UserProfile>(userId && `userProfiles/${userId}`)

    // side effects
    useEffect(() => {
        setUserProfileDocumentPath(userId && `userProfiles/${userId}`)
    }, [setUserProfileDocumentPath, userId])

    return {
        userProfile,
        userProfileSnapshot,
    }

}