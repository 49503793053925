import { useEffect, useMemo } from "react";
import { Registration } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { useFirestoreDocument } from "../firebase/firebase";

export const useRegistration = (registrationId?: string): RecordWithID<Registration> | undefined => {

    // hooks
    const registrationDocumentPath = useMemo(() => registrationId ? `registrations/${registrationId}` : undefined, [registrationId])
    const [registration, setRegistrationDocumentPath] = useFirestoreDocument<Registration>(registrationDocumentPath)

    // side effects
    useEffect(() => {
        setRegistrationDocumentPath(registrationDocumentPath)
    }, [registrationDocumentPath])

    if (!registration || !registrationId) {
        return undefined
    }

    return {
        ...registration,
        id: registrationId
    }

}