import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/constants/colors'
import { CaretDownIcon } from '../icons/caret-down-icon/CaretDownIcon'
import { IItemRendererProps, ItemRenderer, Select as BlueprintSelect } from "@blueprintjs/select";
import { MenuItem } from '@blueprintjs/core';
import { matchSorter } from "match-sorter";
import { HighlightText } from '../highlight-text/HighlightText';

export type SelectProps<T extends {}> = {
	items: T[]
	value?: T
	allowClear?: boolean
	onChange?: (item?: T) => any
	onBlur?: () => any
	itemRenderer?: ItemRenderer<T | AdminItem>
	valueRenderer?: (item: T) => string | ReactNode
	onQueryChange?: (query: string) => void
	options: {
		nameKey: keyof T
		idKey: keyof T
		searchableFields?: string[]
		clearLabel?: string
	}
	placeholder?: string
	disabled?: boolean
}

export type AdminItem = {
	type: 'clear'
	_isAdminItem: boolean
}

export const Select = <T extends {}>({
	items,
	value,
	allowClear,
	onChange,
	onBlur,
	itemRenderer,
	valueRenderer,
	onQueryChange,
	options,
	placeholder,
	disabled,
}: SelectProps<T>) => {

	const { nameKey, idKey, searchableFields } = options
	const LocalizedSelect = useMemo(() => BlueprintSelect.ofType<T | AdminItem>(), [])

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const filteredItems: T[] = useMemo(() => {
		if (!searchQuery) {
			return items
		}
		return matchSorter(items, searchQuery, { keys: searchableFields as string[] })
	}, [items, searchQuery, searchableFields])
	const selectItems = useMemo(() => {
		const newSelectItems: (T | AdminItem)[] = []
		if (allowClear) {
			newSelectItems.push({ type: 'clear', _isAdminItem: true })
		}
		newSelectItems.push(...filteredItems)
		return newSelectItems
	}, [filteredItems, allowClear])
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

	// event handlers
	const handleActiveItemChange = useCallback((item: T | AdminItem | null) => {
		const val = isAdminItem(item) ? undefined : item ? item : undefined
		onChange && onChange(val)
	}, [onChange])

	const handleDropdownClick = useCallback(() => {
		setDropdownOpen(dropdownOpen => !dropdownOpen)
	}, [setDropdownOpen])

	const handleDropdownKeydown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' || event.key === ' ') {
			setDropdownOpen(dropdownOpen => !dropdownOpen)
		}
	}, [setDropdownOpen])
	
	const handleQueryChange = useCallback((query: string) => {
		setSearchQuery(query)
		onQueryChange && onQueryChange(query)
	}, [setSearchQuery, onQueryChange])

	const renderItem = useCallback((item: T | AdminItem, itemProps: IItemRendererProps) => {
		if (item && isAdminItem(item)) {
			const text = options.clearLabel ? options.clearLabel : 'Clear Selection'
			return (
				<MenuItem
					text={<HighlightText text={text} query={searchQuery} />}
					onClick={() => handleActiveItemChange(null)}
				/>
			)
		} else if (item && itemRenderer) {
			return itemRenderer(item, itemProps)
		} else if (item) {
			const name = item[nameKey] as any as string
			return (
				<MenuItem
					text={<HighlightText text={name} query={searchQuery} />}
					onClick={() => handleActiveItemChange(item)}
					active={value && item[idKey] === value[idKey]}
				/>
			)
		} else {
			return null
		}
	}, [value, nameKey, idKey, handleActiveItemChange, searchQuery])

	const renderedValue = useMemo(() => {
		if (!value) {
			return (
				<span className={styles.placeholder}>
					{placeholder}
				</span>
			)
		} else {
			if (valueRenderer) {
				return (valueRenderer(value))
			} else {
				return value[nameKey]
			}
		}
	}, [value, nameKey, valueRenderer, placeholder, styles.placeholder])

	return (
		<LocalizedSelect
			className={styles.localizedSelect}
			items={selectItems}
			onItemSelect={handleActiveItemChange}
			itemRenderer={renderItem}
			query={searchQuery}
			onQueryChange={handleQueryChange}
			popoverProps={{
				boundary: 'window',
				openOnTargetFocus: true,
				isOpen: dropdownOpen,
				onClose: () => setDropdownOpen(false),
			}}
			resetOnClose={true}
			disabled={disabled}
		>
			<div className={`${styles.select} ${disabled && styles.disabled}`} tabIndex={0} onClick={handleDropdownClick} onKeyDown={handleDropdownKeydown} onBlur={onBlur}>
				{renderedValue}
				<div className={styles.selectCaret}>
					<CaretDownIcon size={18} color={Colors.graphite} />
				</div>
			</div>
		</LocalizedSelect>
	)
}

const useStyles = createUseStyles({
	localizedSelect: {
		display: 'grid',
	},
	select: {
		display: 'grid',
		padding: '14px 18px',
		border: '1px solid #969696',
		borderRadius: 8,
		cursor: 'pointer',
		position: 'relative',
		minHeight: 46,
		'&:hover': {
			backgroundColor: '#F5F5F5',
		},
		'&:focus': {
			backgroundColor: '#F5F5F5',
		},
	},
	selectCaret: {
		position: 'absolute',
		top: 0,
		height: '100%',
		display: 'grid',
		alignItems: 'center',
		right: 18,
	},
	placeholder: {
		color: 'rgb(119, 119, 119)',
	},
	dropdownContainer: {
		position: 'absolute',
		left: 12,
		top: 44,
		width: '100%',
		backgroundColor:'white',
		padding: 12,
		boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
		cursor: 'unset',
		//border: `1px solid ${Colors.border}`,
		borderRadius: 8,
	},
	disabled: {
		cursor: 'default',
	},
})

export const isAdminItem = (item: any): item is AdminItem => {
	return typeof item === 'object' && item !== null && item._isAdminItem
}