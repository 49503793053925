import { Spinner } from "@blueprintjs/core";
import { FC, useContext, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { FirebaseContext } from "../../firebase-provider/firebase-context";

export const AuthenticatedRoute: FC<RouteProps> = (props) => {

    // state
    const styles = useStyles()
    const history = useHistory()
    const { authenticationState } = useContext(FirebaseContext)

    useEffect(() => {
        if (authenticationState === 'notAuthenticated') {
            history.push('/sign-in')
        }
    }, [authenticationState])

    if (authenticationState !== 'authenticated') {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
            </div>
        )
    }

    return (
        <Route {...props} />
    )
}

const useStyles = createUseStyles({
    loadingContainer: {
        width: '100vw',
        height: '100vh',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
    }
})