import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { UpdateSubjectsDialogBody } from '../../../../tutors/update-subjects-dialog-body/UpdateSubjectsDialogBody'
import { CompleteProfileDialogContext } from '../../complete-profile-dialog-context'

type ChooseTutorSubjectsProps = {
	tutorId: string
}

export const ChooseTutorSubjects: FC<ChooseTutorSubjectsProps> = ({
	tutorId,
}) => {

	// hooks
	const styles = useStyles()
	const { setDialogState } = useContext(CompleteProfileDialogContext)

	// event handlers
	const handleNextButtonClick = useCallback(() => {
		setDialogState('success')
	}, [setDialogState])

	return (
		<>
			<DialogHeader title="Choose Tutor Subjects" />
			<UpdateSubjectsDialogBody tutorId={tutorId} />
			<DialogFooter>
				<DialogFooterButton color={blue.primary} textColor="white" onClick={handleNextButtonClick}>
					Next
				</DialogFooterButton>
			</DialogFooter>
		</>
	)
}

const useStyles = createUseStyles({
	chooseTutorSubjects: {
		display: 'grid'
	}
})