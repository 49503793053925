import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type FormRowProps = {
	label?: string
	subtitle?: string
	note?: string
	id?: string
	disabled?: boolean
}

export const FormRow: FC<FormRowProps> = ({
	label,
	subtitle,
	note,
	id,
	children,
	disabled,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={`${styles.formRow} ${disabled && styles.disabled}`}>
			{(label || subtitle) && (
				<div className={styles.labelContainer}>
					<label htmlFor={id} className={styles.title}>{label}</label>
					{subtitle && <span className={styles.subtitle}>{subtitle}</span>}
				</div>
			)}
			{children}
			{note && <span className={styles.note}>{note}</span>}
		</div>
	)
}

const useStyles = createUseStyles({
	formRow: {
		display: 'grid',
		gridAutoRows: 'repeat(2, max-content)',
		transition: 'opacity 100ms ease',
	},
	title: {
		cursor: 'text',
		fontSize: 16,
		fontWeight: 500,
	},
	subtitle: {
		fontStyle: 'italic',
		opacity: 0.85,
	},
	labelContainer: {
		marginBottom: 12,
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: 6,
	},
	note: {
		fontSize: 12,
		padding: 12,
	},
	disabled: {
		opacity: 0.25,
	}
})