import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type ButtonProps = {
	color?: string
	textColor?: string
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button: FC<ButtonProps> = ({
	color,
	textColor,
	children,
	...buttonProps
}) => {

	// hooks
	const styles = useStyles()

	return (
		<button {...buttonProps} className={`${styles.button} ${buttonProps.className}`} style={{ backgroundColor: color, color: textColor, ...buttonProps.style }}>
			{children}
		</button>
	)
}

const useStyles = createUseStyles({
	button: {
		border: 'none',
		display: 'grid',
		gridAutoFlow: 'column',
		alignItems: 'center',
		gap: 8,
		justifyContent: 'center',
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 14,
		paddingRight: 14,
		borderRadius: 4,
		cursor: 'pointer',
		'&:disabled': {
			filter: 'grayscale(1)',
			cursor: 'default',
		}
	}
})