import { transform } from "lodash"

export const sanitizeObjectForFirebase = (obj: { [key: string]: any }) => {
    return transform(obj, (result: any, value, key) => {
        if (value === undefined) {
            result[key] = null
        } else if (typeof value === 'object') {
            result[key] = sanitizeObjectForFirebase(value)
        } else {
            result[key] = value
        }
    })
}