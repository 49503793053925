import { gold } from '@ant-design/colors'
import moment from 'moment'
import React, { FC, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useFirestoreQuery } from '../../../common/hooks/firebase/firebase'
import { FirebaseQueryFiltersAndSorts } from '../../../common/hooks/firebase/firebase-types'
import { getNow } from '../../../common/logic/date-logic'
import { Registration } from '../../../common/types/types'
import { combineIdsAndFirebaseQueryData } from '../../../common/utils/other/combine-ids-and-firebase-query-data'
import { RegistrationIcon } from '../../icons/registration-icon/RegistrationIcon'
import { StatisticsItem } from '../../statistics/statistics-item/StatisticsItem'

type RegistrationsonWaitlistStatisticProps = {}

export const RegistrationsonWaitlistStatistic: FC<RegistrationsonWaitlistStatisticProps> = () => {

	// hooks
	const styles = useStyles()
	const registrationsOnWaitlist = useRegistrationsOnWaitlist()

	return (
		<StatisticsItem
			title="Registrations"
			icon={RegistrationIcon}
			color={gold.primary}
			value={registrationsOnWaitlist}
			description="Registrations on Waitlist this Week"
		/>
	)
}

const useStyles = createUseStyles({
	registrationsThisWeekStatistic: {
		display: 'grid'
	}
})

const useRegistrationsOnWaitlist = (): number | undefined => {

	// hooks
	const thisWeek = useMemo(() => getNow().startOf('week').unix(), [])
	const queryParams: FirebaseQueryFiltersAndSorts<Registration> = useMemo(() => ({
		filters: [['registrationWeekStartDate', '==', thisWeek]]
	}), [thisWeek])
	const [registrationIds, registrationsData, setQueryParams] = useFirestoreQuery<Registration>('registrations', queryParams)
	const registrations = useMemo(() => combineIdsAndFirebaseQueryData(registrationIds, registrationsData), [registrationIds, registrationsData])
	const filteredRegistrations = useMemo(() => {
		if (!registrations) { return undefined }
		return registrations.filter(registration => !registration.TutorID)
	}, [registrations])

	// side effects
	useEffect(() => {
		setQueryParams(queryParams)
	}, [setQueryParams, queryParams])

	return filteredRegistrations?.length

}