import { blue } from '@ant-design/colors'
import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../../common/constants/colors'
import { signOut } from '../../../../../common/logic/authentication-logic'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../../../form-row/FormRow'
import { StudentsIcon } from '../../../../icons/students-icon/StudentsIcon'
import { TutorsIcon } from '../../../../icons/tutors-icon/TutorsIcon'
import { SelectTiles } from '../../../../select-tiles/SelectTiles'
import { CompleteProfileDialogContext } from '../../complete-profile-dialog-context'

type ChooseUserTypeProps = {}

export const ChooseUserType: FC<ChooseUserTypeProps> = () => {

	// hooks
	const { setDialogState } = useContext(CompleteProfileDialogContext)
	const styles = useStyles()
	const [userProfileType, setUserProfileType] = useState<'student' | 'tutor'>()

	// event handlers
	const handleUserProfileTypeChange = useCallback((event: string) => {
		setUserProfileType(event as 'student' | 'tutor')
	}, [setUserProfileType])

	const handleNextButtonClick = useCallback(() => {
		setDialogState(userProfileType === 'student' ? 'provideStudentDetails' : 'provideTutorDetails')
	}, [userProfileType, setDialogState])

	const handleSignOutButtonClick = useCallback(() => {
		signOut()
	}, [])

	return (
		<>
			<DialogHeader title="Create Profile" />
			<DialogBody>
				<FormRow label="Which of the following best describes you?">
					<SelectTiles
						onChange={handleUserProfileTypeChange}
						selectedOptionId={userProfileType}
						options={[
							{
								id: 'student',
								title: 'Student',
								subtitle: 'You want to sign up to learn from tutors',
								icon: StudentsIcon
							},
							{
								id: 'tutor',
								title: 'Tutor',
								subtitle: 'You want to sign up to tutor students',
								icon: TutorsIcon
							},
						]}
					/>
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleSignOutButtonClick}>
					Sign Out
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!userProfileType} onClick={handleNextButtonClick}>
					Next
				</DialogFooterButton>
			</DialogFooter>
		</>
	)
}

const useStyles = createUseStyles({
	chooseUserType: {
		display: 'grid'
	}
})