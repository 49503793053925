import React, { ComponentType, FC } from 'react'
import { createUseStyles } from 'react-jss'
import { IconProps } from '../icons/props'

type StyledIconProps = {
	icon: ComponentType<IconProps>
	colors: {
		background?: string
		foreground?: string
	}
}

export const StyledIcon: FC<StyledIconProps> = ({
	icon: Icon,
	colors,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.styledIcon} style={{ backgroundColor: colors.background }}>
			<Icon size={22} color={colors.foreground} />
		</div>
	)
}

const useStyles = createUseStyles({
	styledIcon: {
		display: 'grid',
		width: 40,
		height: 40,
		borderRadius: 6,
		justifyContent: 'center',
		alignItems: 'center',
	}
})