import { matchSorter } from "match-sorter"
import { useMemo } from "react"
import { RecordWithID } from "../../types/utils"

export const useMatchSortedRecords = <RecordType extends {}>(items?: RecordWithID<RecordType>[], searchQuery?: string, searchableKeys?: (keyof RecordType)[]) => {

    const matchSortedRecords = useMemo(() => {
        if (!items || !searchQuery) {
            return items
        }
        return matchSorter(items, searchQuery, { keys: searchableKeys as string[] })
    }, [items, searchQuery, searchableKeys])

    return matchSortedRecords

}