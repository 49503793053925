import React, { FC } from 'react'
import { IconProps } from '../props'

export const HelpIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill={color}>
			<path id="Path_5" data-name="Path 5" d="M22,15a7,7,0,1,1-7-7A7,7,0,0,1,22,15Zm-6.812-4.685a3.656,3.656,0,0,0-3.29,1.8.339.339,0,0,0,.077.459l.979.743a.339.339,0,0,0,.47-.06c.5-.64.85-1.01,1.617-1.01.577,0,1.29.371,1.29.93,0,.423-.349.64-.918.959-.664.372-1.543.835-1.543,1.994v.113a.339.339,0,0,0,.339.339H15.79a.339.339,0,0,0,.339-.339V16.2c0-.8,2.348-.837,2.348-3.011A3.22,3.22,0,0,0,15.188,10.315Zm-.188,7a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,15,17.315Z" transform="translate(-8 -8)" />
		</svg>
	)
}