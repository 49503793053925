import firebase from 'firebase'
import { Subject } from '../types/types'
import { sanitizeObjectForFirebase } from '../utils/other/sanitize-object-for-firebase'
import { logAnalyticsEvent } from './analytics'
import { updateTutor } from './tutor-logic'

export const deleteSubject = async (subjectId: string) => {

    logAnalyticsEvent('action', { type: 'deleteSubject', payload: { subjectId } })

    const db = firebase.firestore()

    await db.doc(`subjects/${subjectId}`).delete()

}

export const createSubject = async (subject: Subject): Promise<string> => {

    logAnalyticsEvent('action', { type: 'createSubject', payload: { subject } })

    const db = firebase.firestore()

    const payload = sanitizeObjectForFirebase(subject)

    const snapshot = await db.collection('subjects').add(payload)

    return snapshot.id

}

export const updateSubject = async (subjectId: string, payload: Partial<Subject>) => {
    
    const db = firebase.firestore()

    await db.doc(`subjects/${subjectId}`).update(sanitizeObjectForFirebase(payload))

}

export const removeTutorFromSubject = async (tutorId: string, subjectId: string) => {

    logAnalyticsEvent('action', { type: 'removeTutorFromSubject', payload: { tutorId, subjectId } })

    await updateTutor(tutorId, tutor => ({
        ...tutor,
        SubjectIDs: tutor.SubjectIDs.filter(id => id !== subjectId)
    }))

}

export const addTutorToSubject = async (tutorId: string, subjectId: string) => {

    logAnalyticsEvent('action', { type: 'addTutorToSubject', payload: { tutorId, subjectId } })

    await updateTutor(tutorId, tutor => ({
        ...tutor,
        SubjectIDs: [...tutor.SubjectIDs, subjectId]
    }))

}

export const updateSubjectPriority = async (subjectId: string, priority: number) => {

    logAnalyticsEvent('action', { type: 'updateSubjectPriority', payload: { subjectId, priority } })

    await updateSubject(subjectId, {
        pairingPriority: priority,
    })

}