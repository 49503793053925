import { blue } from '@ant-design/colors'
import { MenuDivider, MenuItem } from '@blueprintjs/core'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { HighlightText } from '../../highlight-text/HighlightText'
import { AdminItem, isAdminItem, Select } from '../../select/Select'

type LabeledItem = { label?: string }
export type TutorOption = RecordWithID<{ type: 'divider' | 'option' | 'tutor', data: RecordWithID<Tutor> | LabeledItem }>

type TutorSelectProps = {
	items: TutorOption[]
	value?: TutorOption
	onChange?: (item?: TutorOption) => any
	onBlur?: () => any
	placeholder?: string
	disabled?: boolean
}

export const TutorSelect: FC<TutorSelectProps> = ({
	value,
	onChange,
	...selectProps
}) => {

	// hooks
	const styles = useStyles()
	const [searchQuery, setSearchQuery] = useState<string>('')

	// event handlers
	const handleActiveItemChange = useCallback((item: TutorOption | null) => {
		onChange && onChange(item ? item : undefined)
	}, [onChange])

	const itemRenderer = useCallback((item?: TutorOption | AdminItem) => {
		if (item && isAdminItem(item)) {
			return (
				<MenuItem text="Clear Selection" onClick={() => handleActiveItemChange(null)} />
			)
		} else if (item) {
			if (item.type === 'divider') {
				return <MenuDivider />
			} else if (item.type === 'option') {
				const data = item.data as LabeledItem
				const label = data.label as string
				return (
					<MenuItem
						text={<HighlightText text={label} query={searchQuery} />}
						onClick={() => handleActiveItemChange(item)}
						active={value && item.id === value.id}
					/>
				)
			} else {
				const tutor = item.data as RecordWithID<Tutor>
				const initals = tutor.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
				return (
					<MenuItem
						text={(
							<div className={styles.menuItemContentContainer} style={{ marginLeft: 2, }}>
								<Avatar size={22} style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
								<HighlightText text={tutor.displayName} query={searchQuery} />
							</div>
						)}
						onClick={() => handleActiveItemChange(item)}
						active={value && item.id === value.id}
					/>
				)
			}	
		} else {
			return null
		}
	}, [handleActiveItemChange, value, searchQuery])

	const valueRenderer = useCallback((item?: TutorOption) => {
		if (item) {
			if (item.type === 'option') {
				return (item.data as LabeledItem).label
			} else {
				const tutor = item.data as RecordWithID<Tutor>
				const initals = tutor.displayName.trim().split(' ').map(word => word.charAt(0)).join('').toUpperCase()
				return (
					<div className={styles.valueContainer}>
						<Avatar style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
						{tutor.displayName}
					</div>
				)
			}
		} else {
			return null
		}
	}, [styles.valueContainer])

	return (
		<Select
			{...selectProps}
			itemRenderer={itemRenderer}
			valueRenderer={valueRenderer}
			value={value}
			onChange={onChange}
			onQueryChange={setSearchQuery}
			options={{
				nameKey: 'id',
				idKey: 'id',
				searchableFields: ['data.displayName', 'data.label'],
			}}
		/>
	)
}

const useStyles = createUseStyles({
	valueContainer: {
		display: 'grid',
		alignItems: 'center',
		gap: 8,
		gridAutoFlow: 'column',
		gridAutoColumns: 'max-content',
	},
	menuItemContentContainer: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		gap: 8,
		alignItems: 'center',
	},
})