import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/constants/colors'

type ListItemProps = {
	onClick?: () => void
	compactView?: boolean
}

export const ListItem: FC<ListItemProps> = ({
	onClick,
	children,
	compactView,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={`${styles.listItem} ${onClick && styles.clickable} ${compactView && styles.compactView}`} onClick={onClick}>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	listItem: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		gridAutoColumns: 'max-content',
		gridAutoFlow: 'column',
		padding: 10,
		border: `1px solid ${Colors.border}`,
		borderRadius: 8,
		alignItems: 'center',
		gap: 8,
		position: 'relative',
		'& h3': {
			fontSize: 15,
		},
	},
	clickable: {
		userSelect: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: Colors.whiteButton.hover,
		},
		'&:active': {
			backgroundColor: Colors.whiteButton.active,
		},
	},
	compactView: {
		border: 'none',
		borderRadius: 0,
		borderBottom: `1px solid ${Colors.border}`,
		paddingLeft: 24,
		paddingRight: 24,
	},
})