import { blue } from '@ant-design/colors'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useIntials } from '../../../common/hooks/database/use-initials'
import { Subject, Tutor } from '../../../common/types/types'
import { RecordWithID } from '../../../common/types/utils'
import { ListItem } from '../../list-item/ListItem'
import { StyledTutorsIcon } from '../../styled-icons/styled-tutors-icon/StyledTutorsIcon'
import { TutorAddedtoSubjectToggle } from '../tutor-added-to-subject-toggle/TutorAddedtoSubjectToggle'

type ToggleTutorinSubjectListItemProps = {
	tutor: RecordWithID<Tutor>
	subject: RecordWithID<Subject>
	isAdded: boolean
}

export const ToggleTutorinSubjectListItem: FC<ToggleTutorinSubjectListItemProps> = ({
	tutor,
	subject,
	isAdded,
}) => {

	// hooks
	const styles = useStyles()
	const initals = useIntials(tutor)

	return (
		<ListItem>
			<Avatar style={{ backgroundColor: blue.primary }}>{initals}</Avatar>
			<h3>{tutor.displayName}</h3>
			<TutorAddedtoSubjectToggle tutor={tutor} subject={subject} isAdded={isAdded} />
		</ListItem>
	)
}

const useStyles = createUseStyles({
	toggleTutorInSubjectListItem: {
		display: 'grid'
	}
})