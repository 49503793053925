import React, { FC } from 'react'
import { IconProps } from '../props'

export const SearchIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill={color}>
			<path id="Path_26" data-name="Path 26" d="M17.876,16.484l-4.268-4.268a.417.417,0,0,0-.3-.123h-.464a7.311,7.311,0,1,0-.752.752v.464a.432.432,0,0,0,.123.3l4.268,4.268a.422.422,0,0,0,.6,0l.794-.794A.422.422,0,0,0,17.876,16.484ZM7.312,12.937a5.625,5.625,0,1,1,5.625-5.625A5.623,5.623,0,0,1,7.312,12.937Z" />
		</svg>
	)
}