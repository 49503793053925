import { blue } from '@ant-design/colors'
import PhoneNumber from 'awesome-phonenumber'
import { Country as SourceCountry, countries as sourceCountries } from 'countries-list'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
import { Colors } from '../../../../../common/constants/colors'
import { Grade, grades } from '../../../../../common/constants/grades'
import { useCurrentUserProfile } from '../../../../../common/hooks/database/use-current-user-profile'
import { updateStudent } from '../../../../../common/logic/student-logic'
import { SchoolGrade, Student } from '../../../../../common/types/types'
import { DialogBody } from '../../../../dialog/subcomponents/dialog-body/DialogBody'
import { DialogFooterButton } from '../../../../dialog/subcomponents/dialog-footer-button/DialogFooterButton'
import { DialogFooter } from '../../../../dialog/subcomponents/dialog-footer/DialogFooter'
import { DialogHeader } from '../../../../dialog/subcomponents/dialog-header/DialogHeader'
import { FormRow } from '../../../../form-row/FormRow'
import { LoadingOverlay } from '../../../../loading-overlay/LoadingOverlay'
import { Select } from '../../../../select/Select'

export type Country = SourceCountry & { id: keyof typeof sourceCountries }

type StudentDetailsFormProps = {
	student: Student
	onClose?: () => void
}

export const StudentDetailsForm: FC<StudentDetailsFormProps> = ({
	student,
	onClose,
}) => {

	// hooks
	const { isAdmin } = useCurrentUserProfile()
	const countries: Country[] = useMemo(() => Object.keys(sourceCountries).map((id) => {
		const countryId = id as keyof typeof sourceCountries
		return {
			...sourceCountries[countryId],
			id: countryId,
		}
	}), [])
	const { register, getValues, control, formState: { isValid }, watch } = useForm({
		mode: 'onChange',
		defaultValues: {
			displayName: student.displayName,
			phoneNumber: student.phoneNumber,
			schoolName: student.schoolName,
			grade: grades.find(grade => grade.id === student.grade) as Grade,
			country: countries.find(country => country.id === student.country) as Country,
		}
	})
	const selectedCountry: Country = watch('country')
	const [loading, setLoading] = useState<boolean>(false)

	const phoneNumberPlaceholder = useMemo(() => {
		return formatPhoneNumber(PhoneNumber.getExample(selectedCountry ? selectedCountry.id : 'US').getNumber())
	}, [selectedCountry])

	// event handlers
	const handleCancelButtonClick = useCallback(() => {
		onClose && onClose()
	}, [])

	const handleSaveButtonClick = useCallback(() => {
		const formData = getValues()
		const updatePayload: Partial<Student> = {
			displayName: formData.displayName,
			phoneNumber: formData.phoneNumber,
			schoolName: formData.schoolName,
			grade: formData.grade.id as SchoolGrade | undefined,
			country: formData.country.id,
		}
		setLoading(true)
		updateStudent(updatePayload).then(() => {
			setLoading(false)
			onClose && onClose()
		})
	}, [getValues, setLoading, onClose])

	return (
		<>
			<DialogHeader title={isAdmin ? `Update Student Information` : `Update Tutor Information`} />
			<DialogBody>
				<FormRow label={isAdmin ? 'Student Name' : `What's your name?`}>
					<input placeholder="Marcel Zhang" {...register('displayName', { required: true })} />
				</FormRow>
				<FormRow label={isAdmin ? 'Student Country' : 'What country do you live in?'}>
					<Controller
						name="country"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={countries}
								options={{
									nameKey: 'name',
									idKey: 'id',
									searchableFields: ['name'],
								}}
								placeholder="Choose a Country..."
							/>
						)}
					/>
				</FormRow>
				<FormRow label={isAdmin ? 'Phone Number' : `What's your phone number?`}>
					<Controller
						name="phoneNumber"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<PhoneInput
								{...field}
								defaultCountry={selectedCountry.id}
								placeholder={phoneNumberPlaceholder}
							/>
						)}
					/>
				</FormRow>
				<FormRow label={isAdmin ? 'School Name' : 'What school do you go to?'}>
					<input placeholder="Shermer High School" {...register('schoolName', { required: true })} />
				</FormRow>
				<FormRow label={isAdmin ? 'School Grade' : 'What grade are you in?'}>
				<Controller
						name="grade"
						control={control}
						defaultValue={undefined}
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								{...field}
								items={grades}
								options={{
									nameKey: 'label',
									idKey: 'id',
									searchableFields: ['label'],
								}}
								placeholder="Choose a Grade..."
							/>
						)}
					/>
				</FormRow>
			</DialogBody>
			<DialogFooter>
				<DialogFooterButton color={Colors.border} textColor={Colors.graphite} onClick={handleCancelButtonClick}>
					Cancel
				</DialogFooterButton>
				<DialogFooterButton color={blue.primary} textColor="white" disabled={!isValid} onClick={handleSaveButtonClick}>
					Save
				</DialogFooterButton>
			</DialogFooter>
			<LoadingOverlay loading={loading} />
		</>
	)
}