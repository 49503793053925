import { Portal } from '@blueprintjs/core'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Measure, { ContentRect } from 'react-measure'
import { Shadows } from '../../common/constants/shadows'
import { DialogsContext } from '../dialogs-provider/dialogs-context'

export type OverflowMenuProps = {
	visible: boolean
	onClose?: () => void
}

export const OverflowMenu: FC<OverflowMenuProps> = ({
	visible,
	onClose,
	children,
}) => {

	// hooks
	const styles = useStyles()
	const { currentDialogs } = useContext(DialogsContext)
	const [top, setTop] = useState<number>()
	const [left, setLeft] = useState<number>()
	const [width, setWidth] = useState<number>()
	const currentDialog = useMemo(() => currentDialogs[currentDialogs.length - 1], [currentDialogs])
	const [initialDialog, setInitialDialog] = useState<string>()
	const hideMenu = useMemo(() => currentDialog !== initialDialog, [currentDialog, initialDialog])

	// side effects
	useEffect(() => {
		if (visible) { setInitialDialog(currentDialog) }
		else { setInitialDialog(undefined) }
	}, [visible])

	// event handlers
	const handleResize = useCallback((contentRect: ContentRect) => {
		const { bounds } = contentRect
		if (!bounds) { return }
		const { top, left, width } = bounds
		setTop(top)
		setLeft(left)
		setWidth(width)
	}, [setTop, setLeft, setWidth])

	if (!visible) {
		return null
	}
		
	return (
		<>
			<Measure bounds onResize={handleResize}>
				{({ measureRef }) => (
					<div ref={measureRef} className={`${styles.overflowMenuContainer} ${styles.pseudoContainer}`}>
						<div className={styles.overflowMenu}>
							{children}
						</div>
					</div>
				)}
			</Measure>
			<Portal>
				<div className={`${styles.overflowMenuContainer} ${hideMenu && styles.hideMenu}`} style={{ left, top, width }}>
					<div className={styles.menuBackdrop} onClick={onClose} />
					<div className={styles.overflowMenu}>
						{children}
					</div>
				</div>
			</Portal>
		</>
	)
}

const useStyles = createUseStyles({
	overflowMenuContainer: {
		position: 'absolute',
		right: 8,
		top: 8,
		zIndex: 1,
	},
	pseudoContainer: {
		opacity: 0,
		pointerEvents: 'none',
	},
	'@keyframes menu-appear': {
		from: {
			transform: 'scale(0.8)',
			opacity: 0,
		},
		to: {
			transform: 'scale(1)',
			opacity: 1,
		},
	},
	'@keyframes backdrop-appear': {
		from: {
			opacity: 0,
		},
		to: {
			opacity: 0.15,
		}
	},
	overflowMenu: {
		transformOrigin: 'top right',
		position: 'relative',
		zIndex: 1,
		backgroundColor: 'white',
		boxShadow: Shadows['4dp'],
		padding: 8,
		borderRadius: 8,
		cursor: 'unset',
		animationFillMode: 'both',
		animation: `$menu-appear 100ms ease`,
	},
	menuBackdrop: {
		position: 'fixed',
		left: 0,
		top: 0,
		width: '100vw',
		height: '100vh',
		cursor: 'pointer',
		backgroundColor: 'black',
		opacity: 0.15,
		zIndex: 0,
		animationFillMode: 'both',
		animation: `$backdrop-appear 100ms ease`,
	},
	hideMenu: {
		opacity: 0,
		pointerEvents: 'none',
	},
})