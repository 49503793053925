import React, { FC } from 'react'
import { IconProps } from '../props'

export const AddIcon: FC<IconProps> = ({
	color,
	size,
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 384 384" fill={color}>
			<path id="Path_35" data-name="Path 35" d="M368,224H224V80a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16V224H16A16,16,0,0,0,0,240v32a16,16,0,0,0,16,16H160V432a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V288H368a16,16,0,0,0,16-16V240A16,16,0,0,0,368,224Z" transform="translate(0 -64)" />
		</svg>
	)
}