import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileDialogMaxWidth } from '../../../../common/constants/breakpoints'

type DialogFooterProps = {}

export const DialogFooter: FC<DialogFooterProps> = ({
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.dialogFooter}>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	dialogFooter: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: '1fr',
		gridTemplateRows: 74,
	},
	[`@media screen and (min-width: ${mobileDialogMaxWidth + 1}px)`]: {
		dialogFooter: {
			borderBottomLeftRadius: 16,
			borderBottomRightRadius: 16,
			overflow: 'hidden',
		}
	},
})