import { volcano } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { StudentsIcon } from '../../icons/students-icon/StudentsIcon'
import { StyledIcon } from '../../styled-icon/StyledIcon'

type StyledStudentsIconProps = {}

export const StyledStudentsIcon: FC<StyledStudentsIconProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<StyledIcon icon={StudentsIcon} colors={{ background: volcano.primary, foreground: volcano[0] }} />
	)
}

const useStyles = createUseStyles({
	styledStudentsIcon: {
		display: 'grid'
	}
})