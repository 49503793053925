import { volcano } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useStudents } from '../../../common/hooks/database/use-students'
import { StudentsIcon } from '../../icons/students-icon/StudentsIcon'
import { StatisticsItem } from '../../statistics/statistics-item/StatisticsItem'

type TotalStudentsStatisticProps = {}

export const TotalStudentsStatistic: FC<TotalStudentsStatisticProps> = () => {

	// hooks
	const styles = useStyles()
	const totalStudents = useTotalStudents()

	return (
		<StatisticsItem
			title="Students"
			icon={StudentsIcon}
			color={volcano.primary}
			value={totalStudents}
			description="Total Students on Platform"
		/>
	)
}

const useStyles = createUseStyles({
	registrationsThisWeekStatistic: {
		display: 'grid'
	}
})

const useTotalStudents = (): number | undefined => {

	// hooks
	const students = useStudents()

	return students?.length

}