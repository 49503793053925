import { useContext, useEffect, useMemo } from "react";
import { FirebaseContext } from "../../../components/firebase-provider/firebase-context";
import { Registration } from "../../types/types";
import { RecordWithID } from "../../types/utils";
import { combineIdsAndFirebaseQueryData } from "../../utils/other/combine-ids-and-firebase-query-data";
import { getThisWeek } from "../../utils/other/get-this-week";
import { useFirestoreQuery } from "../firebase/firebase";
import { FirebaseQueryFiltersAndSorts } from "../firebase/firebase-types";

export const useTutorRegistrations = (): RecordWithID<Registration>[] | undefined => {

    // hooks
    const { userId } = useContext(FirebaseContext)
    const thisWeek = useMemo(() => getThisWeek(), [])
    const registrationsQueryParams: FirebaseQueryFiltersAndSorts<Registration> | undefined = useMemo(() => {
        if (!userId) { return undefined }
        return {
            filters: [
                ['TutorID', '==', userId],
                ['registrationWeekStartDate', '>=', thisWeek],
            ],
            sorts: [['registrationWeekStartDate', 'asc']],
        }
    }, [userId])
    const [registrationIds, registrationsData, setRegistrationsQueryParams] = useFirestoreQuery<Registration>(`registrations`, registrationsQueryParams)
    const registrations = useMemo(() => combineIdsAndFirebaseQueryData(registrationIds, registrationsData), [registrationIds, registrationsData])

    // side effects
    useEffect(() => {
        setRegistrationsQueryParams(registrationsQueryParams)
    }, [registrationsQueryParams])

    return registrations

}