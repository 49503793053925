import { Tooltip } from 'antd'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../common/constants/colors'
import { HelpIcon } from '../icons/help-icon/HelpIcon'

type HomeSectionProps = {
	title: string
	helpText: string
	className?: string
	contentClassName?: string
	compactView?: boolean
}

export const HomeSection: FC<HomeSectionProps> = ({
	title,
	helpText,
	children,
	className,
	contentClassName,
	compactView,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={`${styles.homeSection} ${className} ${compactView && styles.compactView}`}>
			<div className={`${styles.sectionHeader} ${compactView && styles.compactViewContent}`}>
				<h4>{title}</h4>
				<Tooltip title={helpText} trigger={['hover']}>
					<HelpIcon size={14} color={Colors.graphite} />
				</Tooltip>
			</div>
			<div className={`${styles.sectionContent} ${contentClassName}`}>
				{children}
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	homeSection: {
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: 16,
		gridTemplateColumns: '100%',
	},
	compactView: {
		gap: 6,
	},
	sectionHeader: {
		display: 'grid',
		gridTemplateColumns: '1fr max-content',
		alignItems: 'center',
		paddingBottom: 16,
		paddingTop: 12,
		paddingLeft: 2,
		paddingRight: 2,
		borderBottom: `1px solid ${Colors.border}`,
		position: 'sticky',
		zIndex: 1,
		top: 0,
		backgroundColor: 'white',
		'&:before': {
			content: '""',
			position: 'absolute',
			left: 0,
			top: -2,
			width: '100%',
			height: '2px',
			backgroundColor: 'white',
		}
	},
	compactViewContent: {
		paddingLeft: 24,
		paddingRight: 24,
	},
	sectionContent: {
		display: 'grid',
		gridAutoRows: 'max-content',
		gap: 8,
	},
})