import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { mobileMaxWidth } from '../../../../common/constants/breakpoints'
import { useCurrentUserProfile } from '../../../../common/hooks/database/use-current-user-profile'
import { HomeSection } from '../../../home-section/HomeSection'
import { ManageStudentInformationAction } from '../../home-actions/manage-student-information-action/ManageStudentInformationAction'
import { RegisterForASession } from '../../home-actions/register-for-a-session/RegisterForASession'
import { UpdateTutorSubjectsAction } from '../../home-actions/update-tutor-subjects-action/UpdateTutorSubjectsAction'

type ManageStudentTutoringSectionProps = {}

export const ManageStudentTutoringSection: FC<ManageStudentTutoringSectionProps> = () => {

	// hooks
	const styles = useStyles()
	const { isStudent } = useCurrentUserProfile()

	if (!isStudent) {
		return null
	}

	return (
		<HomeSection title="Manage My Tutoring" helpText="Manage your registrations for tutoring sessions.">
			<RegisterForASession />
			<ManageStudentInformationAction />
		</HomeSection>
	)
}

const useStyles = createUseStyles({

})