import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Dialog } from '../../dialog/Dialog'
import { useCurrentUserProfile } from '../../../common/hooks/database/use-current-user-profile'
import { CompleteProfileDialogContext, DialogState } from './complete-profile-dialog-context'
import { ChooseUserType } from './subcomponents/choose-user-type/ChooseUserType'
import { ProvideStudentDetails } from './subcomponents/provide-student-details/ProvideStudentDetails'
import { Success } from './subcomponents/success/Success'
import { ProvideTutorDetails } from './subcomponents/provide-tutor-details/ProvideTutorDetails'
import { ChooseTutorSubjects } from './subcomponents/choose-tutor-subjects/ChooseTutorSubjects'
import { logAnalyticsEvent } from '../../../common/logic/analytics'
import { FirebaseContext } from '../../firebase-provider/firebase-context'

type CompleteProfileDialogProps = {}

export const CompleteProfileDialog: FC<CompleteProfileDialogProps> = () => {

	// hooks
	const styles = useStyles()
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)
	const [dialogState, setDialogState] = useState<DialogState>('chooseUserType')
	const { userId } = useContext(FirebaseContext)
	const { isAdmin, isStudent, isTutor } = useCurrentUserProfile()

	// side effects
	useEffect(() => {
		if (![isAdmin, isStudent, isTutor].includes(undefined) && ![isAdmin, isStudent, isTutor].includes(true)) {
			setDialogOpen(true)
		}
	}, [isAdmin, isStudent, isTutor])

	// side effects
	useEffect(() => {
		if (dialogOpen) {
			logAnalyticsEvent('screen_view', { screen: 'CompleteProfileDialog' })
		}
	}, [dialogOpen])

	// event handlers
	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [setDialogOpen])

	if (!userId) {
		return null
	}

	return (
		<CompleteProfileDialogContext.Provider value={{
			dialogState,
			setDialogState,
			onClose: handleDialogClose,
		}}>
			<Dialog
				visible={dialogOpen}
			>
				{dialogState === 'chooseUserType' && (
					<ChooseUserType />
				)}
				{dialogState === 'provideStudentDetails' && (
					<ProvideStudentDetails />
				)}
				{dialogState === 'provideTutorDetails' && (
					<ProvideTutorDetails />
				)}
				{dialogState === 'chooseTutorSubjects' && (
					<ChooseTutorSubjects tutorId={userId} />
				)}
				{dialogState === 'success' && (
					<Success />
				)}
			</Dialog>
		</CompleteProfileDialogContext.Provider>
	)
}

const useStyles = createUseStyles({
	completeProfileDialog: {
		display: 'grid',
	}
})